import React, { useEffect, useState, createContext, useContext } from "react";
import { getCredentialsFromStorage, deleteCredentialsInStorage, saveCredentialsToStorage } from "helpers";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { StoreState } from "reducers";
import { resetStore, setInstantExchange } from "actions";
import { BroadcastChannel } from "broadcast-channel";
import { useReturnOrder } from "hooks/useReturnOrder";
import { useOrder } from "hooks/useOrder";
import { Loading } from "components";
import { useQueryClient, UseQueryOptions } from "react-query";
import i18n from "i18n";

interface AuthContextType {
  loggedIn: boolean;
  broadcastLogout: Function;
  refetchOrderAndReturnOrder: Function;
  isLoading: boolean;
  setHandleLoadingManually: Function;
  isFetching: boolean;
  setRefetchInterval: Function;
}

const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  broadcastLogout: () => {},
  refetchOrderAndReturnOrder: () => {},
  isLoading: false,
  setHandleLoadingManually: () => {},
  isFetching: false,
  setRefetchInterval: () => {}
});
AuthContext.displayName = "AuthContext";

const getOrderNameOrId = (orderNameOrIdFromStorage: string, orderNameOrIdFromSearchParams: string) => {
  // check from search params first - this is used to retrieve the return order from the new customer app
  if (orderNameOrIdFromSearchParams !== "") {
    return orderNameOrIdFromSearchParams;
  } else if (orderNameOrIdFromStorage !== "") {
    return orderNameOrIdFromStorage;
  } else {
    return "";
  }
};

const getEmailOrPhone = (emailOrPhoneFromStorage: string, emailOrPhoneFromSearchParams: string) => {
  // check from search params first - this is used to retrieve the return order from the new customer app
  if (emailOrPhoneFromSearchParams !== "") {
    return emailOrPhoneFromSearchParams;
  } else if (emailOrPhoneFromStorage !== "") {
    return emailOrPhoneFromStorage;
  } else {
    return "";
  }
};

export function AuthProvider({ children }: any): JSX.Element {
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [shouldFetchReturnOrder, setShouldFetchReturnOrder] = useState(false);
  const [shouldFetchOrder, setShouldFetchOrder] = useState(false);
  const [handleLoadingManually, setHandleLoadingManually] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState<UseQueryOptions["refetchInterval"]>(60000 * 10); // 10 minutes
  const {
    orderNameOrId: orderNameOrIdFromStorage,
    emailOrPhone: emailOrPhoneFromStorage
  } = getCredentialsFromStorage();
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const returnOrderIdFromState = useSelector((state: StoreState) => state.order.returnOrderId);
  const locale = useSelector((state: StoreState) => state.locale);
  const location = useLocation();
  const queryClient = useQueryClient();

  // find returnOrderId in url
  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const [returnOrderIdFromUrl, setReturnOrderIdFromUrl] = useState<string>(
    matchResult && matchResult.length ? matchResult[1] : ""
  );

  // find credentials in url
  const urlSearchParams = new URLSearchParams(window.location.search);
  const orderNameOrIdFromSearchParams = urlSearchParams.get("orderNameOrId") || urlSearchParams.get("orderId") || "";
  const emailOrPhoneFromSearchParams = urlSearchParams.get("emailOrPhone") || urlSearchParams.get("email") || "";

  const [orderNameOrId, setOrderNameOrId] = useState<string>(
    getOrderNameOrId(orderNameOrIdFromStorage, orderNameOrIdFromSearchParams)
  );
  const [emailOrPhone, setEmailOrPhone] = useState<string>(
    getEmailOrPhone(emailOrPhoneFromStorage, emailOrPhoneFromSearchParams)
  );

  const {
    isLoading: isLoadingReturnOrder,
    isFetching: isFetchingReturnOrder,
    isSuccess: fetchReturnOrderSuccess,
    isError: fetchReturnOrderError
  } = useReturnOrder(returnOrderIdFromUrl, orderNameOrId, emailOrPhone, {
    enabled: shouldFetchReturnOrder,
    refetchInterval
  });
  const {
    isLoading: isLoadingOrder,
    isFetching: isFetchingOrder,
    isSuccess: fetchOrderSuccess,
    isError: fetchOrderError
  } = useOrder(returnOrderIdFromUrl, { enabled: shouldFetchOrder, refetchInterval });
  const isLoading = isAuthenticating || isLoadingReturnOrder || isLoadingOrder;
  const isFetching = isAuthenticating || isFetchingReturnOrder || isFetchingOrder;

  // Remove credentials from sessionStorage when customer logout/submit return order
  const sessionChannel = new BroadcastChannel("session");
  sessionChannel.onmessage = message => {
    if (message === "logout") {
      deleteCredentialsInStorage();
    } else if (message === "unauthenticated") {
      deleteCredentialsInStorage();
      setLoggedIn(false);
    }
  };

  function broadcastLogout() {
    setShouldFetchReturnOrder(false);
    setShouldFetchOrder(false);
    setOrderNameOrId("");
    setEmailOrPhone("");
    const sessionChannel = new BroadcastChannel("session");
    sessionChannel.postMessage("logout");
  }

  const orderNameOrIdIsDifferent = orderNameOrIdFromStorage !== orderNameOrIdFromSearchParams;
  const emailOrPhoneIsDifferent = emailOrPhoneFromStorage !== emailOrPhoneFromSearchParams;

  function updateCredentialsToStorage() {
    if (
      orderNameOrIdFromSearchParams &&
      emailOrPhoneFromSearchParams &&
      (orderNameOrIdIsDifferent || emailOrPhoneIsDifferent)
    ) {
      saveCredentialsToStorage({
        orderNameOrId: orderNameOrIdFromSearchParams,
        emailOrPhone: emailOrPhoneFromSearchParams
      });
    }
  }

  updateCredentialsToStorage();

  useEffect(() => {
    setReturnOrderIdFromUrl(matchResult && matchResult.length ? matchResult[1] : "");
    dispatch(setInstantExchange(false));
  }, [location]);

  useEffect(() => {
    if (returnOrderIdFromUrl && returnOrderIdFromUrl === returnOrderIdFromState) {
      setLoggedIn(true);
      setIsAuthenticating(false);
    } else if (returnOrderIdFromUrl && orderNameOrId && emailOrPhone && !!shopId) {
      dispatch(resetStore());
      setShouldFetchReturnOrder(true);

      // i18n.language defaults to en
      // we don't want to fetch order unless i18n.language matches the locale code in redux
      // because it can lead to incorrect translations in the backend
      if (i18n.language === locale.code) {
        setShouldFetchOrder(true);
      }
    } else {
      setIsAuthenticating(false);
    }
  }, [
    returnOrderIdFromUrl,
    orderNameOrId,
    emailOrPhone,
    dispatch,
    shopId,
    shouldFetchReturnOrder,
    i18n.language,
    locale.code
  ]);

  useEffect(() => {
    if (fetchReturnOrderSuccess && fetchOrderSuccess) {
      setLoggedIn(true);
      setIsAuthenticating(false);
    }
    if (fetchReturnOrderError || fetchOrderError) {
      setLoggedIn(false);
      setIsAuthenticating(false);
    }
  }, [fetchReturnOrderSuccess, fetchOrderSuccess, fetchReturnOrderError, fetchOrderError]);

  const values = {
    loggedIn,
    broadcastLogout,
    refetchOrderAndReturnOrder: () => {
      setShouldFetchReturnOrder(true);
      setShouldFetchOrder(true);
      queryClient.invalidateQueries(["returnOrder"]);
      queryClient.invalidateQueries(["order"]);
    },
    isLoading,
    setHandleLoadingManually,
    isFetching,
    setRefetchInterval
  };

  return (
    <AuthContext.Provider value={values}>
      {isLoading && !handleLoadingManually ? <Loading /> : children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
}
