import { RefundBreakdown } from "constants/invoice";
import { getFormattedPriceString } from "helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import styles from "./RefundSummary.module.css";

interface RefundSummaryLineItemsProps {
  refundBreakdown: RefundBreakdown[];
  allRefundMethodsAreStoreCredit: boolean;
  hasTopUp: boolean;
  pastTense?: boolean;
}

export const RefundSummaryLineItems: React.FC<RefundSummaryLineItemsProps> = ({
  refundBreakdown,
  allRefundMethodsAreStoreCredit,
  hasTopUp,
  pastTense
}): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  function getRefundMethodText(refundMethod: string): string {
    switch (refundMethod) {
      case "original_payment_method":
        return t("summary.refundToOPMText");
      case "gift_card":
        return t("summary.refundViaGiftCardText");
      case "discount_code":
        return t("summary.refundViaDiscountCodeText");
      case "apparel21_voucher":
        return t("summary.refundViaApparel21Text");
      case "rise_ai_store_credit":
        return t("summary.refundViaRiseAiStoreCreditText");
      case "shopify_store_credit":
        return t("summary.refundViaShopifyStoreCreditText");
      case "online_bank_transfer":
        return "Refund via bank transfer";
      case "ewallet_transfer":
        return "Refund via ewallet transfer";
      default:
        return "";
    }
  }

  function getStoreCreditText(refundMethod: string): string {
    switch (refundMethod) {
      case "gift_card":
        return t("summary.giftCard");
      case "discount_code":
        return t("summary.discountCode");
      case "apparel21_voucher":
        return t("summary.apparel21Voucher");
      case "rise_ai_store_credit":
        return t("summary.riseAiStoreCredit");
      case "shopify_store_credit":
        return t("summary.shopifyStoreCredit");
      default:
        return "";
    }
  }

  // join the texts by a comma, and put and 'and' between the last two
  function joinStoreCreditTexts() {
    const storeCreditTexts = refundBreakdown.map(refund => getStoreCreditText(refund.refundMethod));
    if (storeCreditTexts.length === 1) return storeCreditTexts[0];
    if (storeCreditTexts.length === 2) return storeCreditTexts.join(" and ");

    const lastTwo = storeCreditTexts.slice(-2);
    const rest = storeCreditTexts.slice(0, -2);
    return [...rest, lastTwo.join(", ")].join(" and ");
  }

  function getSummaryHelpText() {
    if (hasTopUp) {
      return allRefundMethodsAreStoreCredit
        ? t("summary.additionalPaymentOffsetStoreCredit", { storeCredits: joinStoreCreditTexts() })
        : t("summary.additionalPaymentOffsetRefund");
    } else {
      return allRefundMethodsAreStoreCredit
        ? t("summary.storeCreditSummaryHelpText", { storeCredits: joinStoreCreditTexts() })
        : t("summary.refundSummaryHelpText");
    }
  }

  return (
    <Grid columns={2} style={{ paddingLeft: "1.5rem" }}>
      {refundBreakdown.map(refund => (
        <React.Fragment key={refund.refundMethod}>
          <Grid.Column style={{ paddingBottom: "0" }} computer={12}>
            {getRefundMethodText(refund.refundMethod)}
          </Grid.Column>
          <Grid.Column textAlign="right" style={{ paddingBottom: "0" }} computer={4}>
            {getFormattedPriceString(Math.abs(refund.amount.cents), refund.amount.currencyIso)}
          </Grid.Column>
        </React.Fragment>
      ))}
      {!pastTense && (
        <Grid.Column computer={12} className={styles.helpText}>
          {getSummaryHelpText()}
        </Grid.Column>
      )}
    </Grid>
  );
};
