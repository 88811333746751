import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import { Form, Grid, Message, Segment } from "semantic-ui-react";
import { PaymentMethod } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Loading, BackgroundImage } from "components";
import { HandleCardAction, HandlePaymentMethod, SubmitButton } from "./components";
import styles from "./ShipmentScheduleAndPayment.module.css";
import { StoreState } from "reducers";
import { NotFound } from "pages/NotFound/NotFound";
import { setError } from "actions";
import { shippingInfo, validationSchema } from "./helpers";
import { updateShipment } from "./api";
import { useReturnOrderWithEmail } from "./hooks";
import { FormValues, PaymentIntent } from "./types";
import { getFormattedPriceString } from "helpers/currency";
import { PAYMENT_STATUS } from "./constants";
import { useStripePromise } from "helpers";

export const ShipmentScheduleAndPayment: React.FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const trackingPrefixes = currentShop.customTrackingNumberRegexes;
  const stripeChargesDisabled =
    !!currentShop.stripeAccountId && !currentShop.stripeChargesEnabled && !currentShop.stripePendingOnboarding;

  const { returnId: returnOrderId } = useParams<{ returnId: string }>();
  const email = new URLSearchParams(window.location.search).get("email");
  const {
    returnOrder,
    isLoading,
    isError,
    returnOrder: { requiresPayment }
  } = useReturnOrderWithEmail(returnOrderId, email);
  const { usesDropOff, usesPickup, feeCents, feeCurrency } = shippingInfo(returnOrder);

  const [paymentStatus, setPaymentStatus] = useState<string>(PAYMENT_STATUS.uninitialized);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null);
  const stripePromise = useStripePromise(currentShop);

  //FORMIK HELPERS
  function handleOnSubmit(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    if (requiresCompletePayment()) {
      dispatch(setError(new Error("Please click Add Payment.")));
      setSubmitting(false);
      return;
    }
    handleSubmit(values);
  }

  function handleValidate(values: FormValues) {
    try {
      validateYupSchema<FormValues>(values, validationSchema(), true, {
        customContext: trackingPrefixes
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  }

  const initialFormValues = {
    noPrinter: false,
    trackingNumber: ""
  };

  // PAYMENT STATUS HELPERS
  function paymentSuccess(): boolean {
    return returnOrder.requiresPayment && paymentStatus === PAYMENT_STATUS.succeeded;
  }

  function requiresCompletePayment(): boolean {
    if ((requiresPayment && paymentMethod) || !requiresPayment) {
      return false;
    }
    return true;
  }

  //UPDATE SHIPMENT & PAYMENT
  function resetPaymentMethod(): void {
    setPaymentMethod(null);
  }
  function redirectToSuccess(noPrinter: boolean): void {
    history.push(
      `/${
        currentShop.shopSlug
      }/returns/${returnOrderId}?paymentSuccess=${paymentSuccess()}&redirectFromAfterReview=true&customerEmail=${email}&noPrinter=${noPrinter}&usesPickup=${usesPickup}&usesDropOff=${usesDropOff}`
    );
  }
  async function handleSubmit(values: FormValues) {
    try {
      const response = await updateShipment(returnOrderId, values, paymentMethod?.id, paymentIntent?.id);
      if (requiresPayment) {
        const { paymentStatusInfo, paymentIntentInfo } = response;
        setPaymentStatus(paymentStatusInfo);
        if (paymentStatusInfo === PAYMENT_STATUS.requiresAction) {
          setPaymentIntent(paymentIntentInfo);
        } else if (paymentStatusInfo === PAYMENT_STATUS.succeeded) {
          redirectToSuccess(values.noPrinter);
        } else {
          throw Error("Unknown payment status. Please contact support.");
        }
      } else {
        redirectToSuccess(values.noPrinter);
      }
    } catch (error) {
      resetPaymentMethod();
      dispatch(setError(error));
    }
  }

  //TODO: Update formik & refactor handleCardAction
  const handleCardActionCallback = (values: FormValues, paymentStatus: string, paymentIntentId?: string) => {
    if (paymentStatus === PAYMENT_STATUS.failed) {
      resetPaymentMethod();
    }
    if (paymentIntentId === paymentIntent?.id) {
      setPaymentStatus(paymentStatus);
      handleSubmit(values);
    }
  };

  // HANDLES LOADING AND ERROR
  if (isLoading) {
    return <Loading />;
  } else if (isError) {
    return <NotFound />;
  }

  return (
    <Elements stripe={stripePromise}>
      <BackgroundImage>
        <div className={styles.container}>
          <div className={styles.content}>
            <Grid container centered verticalAlign="middle">
              <Grid.Column mobile={16} tablet={12} computer={8}>
                {requiresPayment && !paymentMethod && stripeChargesDisabled ? (
                  <Message
                    error
                    header="Payment Error"
                    content={`Oops! It looks like there's something wrong with our payment provider. Please reach out to our support team at ${currentShop.customerSupportEmail}.`}
                  />
                ) : (
                  <Segment stacked>
                    <Formik initialValues={initialFormValues} onSubmit={handleOnSubmit} validate={handleValidate}>
                      {({
                        handleSubmit,
                        errors,
                        setFieldValue,
                        values,
                        isSubmitting
                      }: FormikProps<FormValues>): JSX.Element => (
                        <Form className={styles.form} onSubmit={handleSubmit}>
                          <Grid centered>
                            <Grid.Column mobile={16} tablet={14} computer={12}>
                              {requiresPayment && (
                                <>
                                  <HandlePaymentMethod
                                    requiresPayment={returnOrder.requiresPayment}
                                    price={getFormattedPriceString(feeCents, feeCurrency)}
                                    setPaymentMethod={setPaymentMethod}
                                    paymentStatus={paymentStatus}
                                    paymentMethod={paymentMethod}
                                  />
                                  <HandleCardAction
                                    paymentIntentSecret={paymentIntent?.clientSecret || null}
                                    paymentStatus={paymentStatus}
                                    callback={(paymentStatus: string, paymentIntentId?: string) =>
                                      handleCardActionCallback(values, paymentStatus, paymentIntentId)
                                    }
                                  />
                                </>
                              )}
                              <SubmitButton
                                hidden={requiresCompletePayment()}
                                errors={errors}
                                isSubmitting={isSubmitting}
                              />
                            </Grid.Column>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </Segment>
                )}
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </BackgroundImage>
    </Elements>
  );
};
