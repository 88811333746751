import React, { useEffect, useState } from "react";
import { PaymentDetails } from "..";
import { Button } from "components";
import "./style.css";
import { Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useHistory } from "react-router-dom";
import { updateReturnOrderThenContinue, ReturnSteps } from "actions";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "contexts/AuthProvider";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { StickyBottomAppBar } from "StickyBottomAppBar";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { StoreState } from "reducers";

export const PaymentAndSubmission: React.FC = (): JSX.Element => {
  const { p2pRequestInfo } = useInvoiceContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("orderFlow");
  const { requiresPayment } = useInvoiceContext();
  const { broadcastLogout } = useAuthContext();
  const { isEmbedded } = useIsEmbedded();
  const optedInForRenewReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);

  // showSubmit when there are invoices and customer needs to update their payment details
  const [showSubmit, setShowSubmit] = useState(false);

  // handleSubmit when payment in not required
  const handleSubmit = () => {
    dispatch(
      updateReturnOrderThenContinue(history, ReturnSteps.summaryAndPaymentDetails, { continueToSubmittedStep: true })
    );
    broadcastLogout();
  };
  const PaymentBottomSheet = () => {
    const [stickyBottomPosition, setStickyBottomPosition] = useState(0);
    useEffect(() => {
      const onMessageHandler = (event: MessageEvent) => {
        if (typeof event.data !== "object" || !event.data) {
          return;
        }
        const { type, data } = event.data;
        if (type === "stickyBottomAppBarPosition") {
          setStickyBottomPosition(data);
        }
      };

      window.addEventListener("message", onMessageHandler);

      return () => window.removeEventListener("message", onMessageHandler);
    }, []);

    return (
      <BottomSheet open={showSubmit && requiresPayment} onDismiss={() => setShowSubmit(false)}>
        <Container style={{ marginBottom: isEmbedded ? stickyBottomPosition : 0 }}>
          <PaymentDetails header={t("orderSummary.paymentDetails")} showPaymentDetails={showSubmit} />
        </Container>
      </BottomSheet>
    );
  };

  return (
    <>
      <div style={{ paddingBottom: "3rem" }}>
        <br />
      </div>
      <StickyBottomAppBar>
        <Button
          style={{ margin: "1rem 0.5rem", width: "-webkit-fill-available" }}
          color="primaryColor"
          onClick={() => (requiresPayment ? setShowSubmit(true) : handleSubmit())}
        >
          {requiresPayment
            ? t("exchangePaymentPage.proceedToPayment")
            : p2pRequestInfo.requestable
            ? optedInForRenewReturns
              ? t("summaryAndPaymentDetailsPage.submitRenewReturnRequest")
              : t("summaryAndPaymentDetailsPage.submitRegularReturnRequest")
            : t("orderSummary.submit")}
        </Button>
      </StickyBottomAppBar>
      <PaymentBottomSheet />
    </>
  );
};
