import { ReturnSteps, ShippingAddress, updateReturnOrderThenContinue } from "actions";
import { Title, ReturnItemsSummary, ShippingMethodSummary, Button } from "components";
import { FormikProps } from "formik";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Header } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ShippingAddressForm, ShippingAddressFormForStickyButtons } from "./ShippingAddressForm";
import styles from "./MobileView.module.css";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { StickyBottomAppBar } from "StickyBottomAppBar";

export const MobileView: React.FC = (): JSX.Element => {
  const { isEmbedded } = useIsEmbedded();
  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");
  const shippingMethodTitle = t("shippingMethodSummary.header");
  const panels = [
    {
      title: { content: <Title>{returnItemSummaryTitle}</Title> },
      content: { content: <ReturnItemsSummary title={returnItemSummaryTitle} /> }
    },
    {
      title: { content: <Title>{shippingMethodTitle}</Title> },
      content: { content: <ShippingMethodSummary /> }
    }
  ];

  const formRef = useRef<FormikProps<ShippingAddress>>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    featureFlags: { stickyButtons }
  } = useFeatureFlags();
  const currentStep = ReturnSteps.shippingAddress;

  const handleSubmit = async () => {
    if (!formRef.current) {
      return;
    }

    await formRef.current.submitForm();

    if (!formRef.current.isValid) {
      return;
    }

    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  const continueButtonDisabled = formRef.current !== null && formRef.current.isSubmitting;

  return stickyButtons ? (
    <div className={styles.mobileViewContainer}>
      <div
        style={{
          padding: "0.5em"
        }}
      >
        <div style={{ backgroundColor: "#FBFBFB" }}>
          <div style={{ padding: "1rem" }}>
            <Header as="h2" textAlign="left">
              {t("addressForm.pageHeader")}
            </Header>
          </div>
          <Accordion defaultActiveIndex={[1]} panels={panels} exclusive={false} />
        </div>
        <br />
        <ShippingAddressFormForStickyButtons formRef={formRef} />
      </div>
      <StickyBottomAppBar>
        <Button
          style={{ margin: "1rem", width: "calc(100vw - 2rem)" }}
          color="primaryColor"
          onClick={handleSubmit}
          disabled={continueButtonDisabled}
        >
          <p>{t("continue")}</p>
        </Button>
      </StickyBottomAppBar>
    </div>
  ) : (
    <div
      style={{
        padding: "0.5rem"
      }}
    >
      <div style={{ backgroundColor: "#FBFBFB" }}>
        <div style={{ padding: "1rem", paddingLeft: isEmbedded ? "64px" : "1rem" }}>
          <Header as="h2" textAlign="left">
            {t("addressForm.pageHeader")}
          </Header>
        </div>
        <Accordion defaultActiveIndex={[1]} panels={panels} exclusive={false} />
      </div>
      <br />
      <ShippingAddressForm />
    </div>
  );
};
