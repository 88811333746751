import { addressValidationShape } from "components/AddressFields/constants";
import * as yup from "yup";
import { contactDetailsValidationShape } from "components/ContactDetailsFields/constants";

export function getValidationSchema(isShopify: boolean, courierName: string) {
  const yupShape = {
    ...(!isShopify ? addressValidationShape : {}),
    ...contactDetailsValidationShape
  };

  if (courierName === "Royal Mail") {
    const latin1Regex = /^[\u0020-\u007F]+$/;
    const errorMessage = "This field should contain only Latin-1 characters";

    const castedYupShape = yupShape as Record<string, yup.StringSchema>;
    Object.keys(yupShape).forEach((key: string) => {
      if (castedYupShape[key].constructor.name === "StringSchema") {
        castedYupShape[key] = castedYupShape[key].matches(latin1Regex, {
          message: errorMessage,
          excludeEmptyString: true
        });
      }
    });
  }

  return yup.object().shape(yupShape);
}
