import { MainInvoice } from "constants/invoice";
import { getFormattedPriceString } from "helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "semantic-ui-react";
import styles from "./ExtraLineItems.module.css";

interface ExtraLineItemsProps {
  extraLineItems: MainInvoice["extraLineItems"];
  subtotal: MainInvoice["subtotal"];
}

export const ExtraLineItems: React.FC<ExtraLineItemsProps> = ({ extraLineItems, subtotal }): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  // Hardcoding the translation for now because the backend requires the adjustment name
  // to be "Return shipping fee" so we can't really translate the adjustment name
  function translateExtraLineItemName(name: string) {
    if (name === "Return shipping fee") {
      return t("summary.extraLineItems.returnShippingFee");
    } else {
      return name;
    }
  }

  const content = extraLineItems.map(({ name, presentmentPrice }) => {
    return (
      <div
        key={name}
        style={{
          textAlign: "right",
          color: "gray",
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          padding: "8px 16px",
          verticalAlign: "middle"
        }}
      >
        <span>{translateExtraLineItemName(name)}</span>
        <span>
          {getFormattedPriceString(presentmentPrice.cents, presentmentPrice.currencyIso, {
            useParenthesis: true
          })}
        </span>
      </div>
    );
  });

  return (
    <>
      <Divider style={{ marginTop: "-18px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "16px",
          marginBottom: "12px",
          fontWeight: "bold",
          fontSize: 14
        }}
      >
        {t("summary.subtotal")}
        <span>{getFormattedPriceString(subtotal.cents, subtotal.currencyIso, { useParenthesis: true })}</span>
      </div>
      <div style={{ marginBottom: "24px" }}>{content}</div>
    </>
  );
};
