import React from "react";
import { Loading } from "components/Loading/Loading";
import { useReturnMethods } from "hooks/useReturnMethods";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import qs from "qs";
import ExchangeV2Iframe from "./ExchangeV2Iframe";

export default function ExchangeV2() {
  const { exchangeMethods, isLoading } = useReturnMethods();
  const filteredExchangeMethods = exchangeMethods.filter(method => ["same_product", "advanced"].includes(method.name));
  const returnMethods = filteredExchangeMethods.map(method => method.name).join(",");
  const firstPageIsSameProduct = filteredExchangeMethods.some(method => method.name === "same_product");

  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const exchangeBalanceRefundMethod = useSelector((state: StoreState) => state.currentShop.exchangeBalanceRefundMethod);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const localeCode = useSelector((state: StoreState) => state.locale.code);

  const selectedLineItem = useSelector((state: StoreState) => state.formModal.selectedLineItem);
  const displayPrice = selectedLineItem?.displayPrice;
  const displayPriceCents = displayPrice?.cents;
  const displayPriceCurrency = displayPrice?.currencyIso;

  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const { productId, lineItemId, bonusCreditAmountCents, bundleItemId } = currentReturnItem;

  const newCustomerAppUrl = process.env.REACT_APP_NEW_CUSTOMER_APP_URL;

  const query = {
    return_methods: returnMethods,
    exchange_balance_refund_method: exchangeBalanceRefundMethod,
    returning_product_id: productId,
    line_item_id: lineItemId,
    bundle_line_item_id: bundleItemId,
    returning_item_price_cents: displayPriceCents,
    currency: displayPriceCurrency,
    bonus_credit_amount_cents: bonusCreditAmountCents,
    locale: localeCode
  };
  const queryString = qs.stringify(query);

  const firstPagePath = firstPageIsSameProduct ? productId : "all";
  const iframeUrl = new URL(
    `/${shopSlug}/returns/${returnOrderId}/exchange/products/${firstPagePath}?${queryString}`,
    newCustomerAppUrl
  );

  if (isLoading) {
    return <Loading />;
  }

  return <ExchangeV2Iframe url={iframeUrl} exchangeMethods={filteredExchangeMethods} returningProductId={productId} />;
}
