import React from "react";
import { useTranslation } from "react-i18next";
import { useTruncateItemCardSentence } from "hooks/useTruncateItemCardSentence";
import styles from "./ReturnItemSummary.module.css";
import { Grid, Image, Label, Icon } from "semantic-ui-react";
import { ReturnItemSummary } from "./types";
import { getRefundMethodBadgeText, getExchangeMethodBadgeText } from "helpers";
import camelCase from "camelcase";

interface Props {
  returnItem: ReturnItemSummary;
}

export const PostSubmitReturnItemSummary: React.FC<Props> = ({ returnItem }) => {
  const { imageUrl, daysLeft, price, rejectReason, title, returnMethod, exchangingItemName, statusBadge } = returnItem;
  const { truncateSentence } = useTruncateItemCardSentence();
  const { t } = useTranslation("orderFlow");

  const returnTypeInfo =
    returnMethod.type === "Exchange"
      ? getExchangeMethodBadgeText(returnItem, t("forExchange"), truncateSentence(exchangingItemName || ""))
      : getRefundMethodBadgeText(returnItem, t);

  return (
    <Grid.Column>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch", paddingBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ height: "60px", aspectRatio: "1 / 1" }}>
            <Image
              rounded
              disabled={Boolean(rejectReason)}
              inline
              src={imageUrl}
              style={{ height: "100%", objectFit: "cover", aspectRatio: "1 / 1" }}
            />
          </div>
          <div className={styles.itemSummary}>
            <div className={styles.header}>{title}</div>
            <div className={styles.subheader} style={{ lineHeight: "16px" }}>
              <div>{returnTypeInfo}</div>
              <div>{price}</div>
              {rejectReason && (
                <div style={{ fontStyle: "italic" }}>
                  {t("exchangePaymentPage.rejectReason")}: {rejectReason}
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div style={{ alignSelf: "flex-end", justifyItems: "flex-start" }}>
            <Label
              style={{
                backgroundColor: statusBadge.backgroundColor,
                color: statusBadge.textColor,
                borderRadius: "8px"
              }}
            >
              {t(`orderSummary.returnItemStatus.${camelCase(statusBadge.value)}`)}
            </Label>
          </div>
          {daysLeft && (
            <div style={{ textAlign: "right", paddingTop: "10px", color: "gray", minWidth: "100px" }}>
              <Icon name="clock outline" />
              {t("shippingMethodSummary.daysLeft", { daysLeft })}
            </div>
          )}
        </div>
      </div>
    </Grid.Column>
  );
};
