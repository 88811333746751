import { getReturnOrders, GetReturnOrdersValues } from "api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

// Gets return orders that share the same Shopify order as the return order
// with inputted returnOrderId
export const useGetReturnOrders = (returnOrderId: string, options = {}) => {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);

  const { data, isFetching: isGettingReturnOrders } = useQuery<GetReturnOrdersValues>({
    queryKey: ["getReturnOrders", shopId],
    queryFn: () => getReturnOrders({ shopId, returnOrderId }),
    ...options
  });

  const returnOrders = data ? data.returnOrders : [];

  return { returnOrders, isGettingReturnOrders };
};
