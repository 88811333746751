import React from "react";
import Lottie from "lottie-react";
import animationData from "lottie-animations/loading.json";
import styles from "./Loading.module.css";

interface LoadingProps {
  fullscreen?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ fullscreen }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  };

  return (
    <div className={styles.loading} style={{ position: fullscreen ? "fixed" : "static" }}>
      <div style={{ height: "200px", width: "200px" }}>
        <Lottie {...defaultOptions} />
      </div>
    </div>
  );
};
