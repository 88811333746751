import React from "react";
import { Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { MainInvoice } from "constants/invoice";
import { ReturningItemsSummary, ExchangingLineItems, ExtraLineItems, Summary } from "components";
import { ReturnItem, Summary as SummaryType } from "types";
import { ReturnOrderStatuses } from "constants/returnOrder";

interface OverviewProps {
  invoice: MainInvoice;
  returnOrderStatus: SummaryType["returnOrderStatus"];
  returnItems: ReturnItem[];
}

export const Overview: React.FC<OverviewProps> = ({ invoice, returnOrderStatus, returnItems }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { extraLineItems, subtotal, exchangingForLineItems } = invoice;
  const returnOrderStatusIndex = ReturnOrderStatuses[returnOrderStatus as keyof typeof ReturnOrderStatuses];
  const allReturnItemsRejected = returnItems.every(returnItem => returnItem.status === "rejected");
  const returningItemsPastTense = returnOrderStatusIndex >= ReturnOrderStatuses["received"] && !allReturnItemsRejected;
  const summaryPastTense = returnOrderStatusIndex >= ReturnOrderStatuses["completed"] && !allReturnItemsRejected;

  const padding = isMobile ? "0" : "1rem";

  return (
    <div style={{ padding: padding }}>
      {!isMobile && <Header>{t("invoiceSummary.header")}</Header>}

      <ReturningItemsSummary afterResolvedInvoice={invoice} pastTense={returningItemsPastTense} />
      <div style={{ paddingLeft: "1.5rem" }}>
        {exchangingForLineItems && <ExchangingLineItems exchangingForLineItems={exchangingForLineItems} />}
        {extraLineItems.length > 0 && <ExtraLineItems extraLineItems={extraLineItems} subtotal={subtotal} />}
      </div>
      <Summary afterResolvedInvoice={invoice} pastTense={summaryPastTense} />
    </div>
  );
};
