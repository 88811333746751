import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useHistory } from "react-router-dom";
import { createReturnOrder } from "api";
import {
  addBonusCreditInfo,
  addReturnItems,
  changeReturnStep,
  clearLoading,
  fetchAndAddOrder,
  ReturnItem,
  ReturnSteps,
  setError,
  setInstantExchange,
  setLoading,
  setQuestions
} from "actions";
import { getRoutes } from "constants/routes";
import { Button, Icon } from "semantic-ui-react";

export const LodgeReturnButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const orderName = useSelector((state: StoreState) => state.order.name);
  const emailOrPhone = useSelector((state: StoreState) =>
    Boolean(state.order.email) ? state.order.customer.email : state.order.customer.phone
  );
  const localeCode = useSelector((state: StoreState) => state.locale.code);

  const onLodgeButtonClick = async () => {
    try {
      dispatch(setLoading());

      const params = { orderIdOrName: orderName, emailOrPhone: emailOrPhone, shopId, localeCode };
      const { returnOrder, nextStep } = await createReturnOrder(params);
      fetchAndAddOrder(returnOrder.id);

      const bonusCreditInfo = {
        exchangeBonusCreditMaxValuePerOrder: returnOrder.exchangeBonusCreditMaxValuePerOrder,
        storeCreditBonusCreditMaxValuePerOrder: returnOrder.storeCreditBonusCreditMaxValuePerOrder
      };
      dispatch(addBonusCreditInfo(bonusCreditInfo));

      dispatch(setInstantExchange(returnOrder.instantExchange));

      const returnItems: ReturnItem[] = [...returnOrder.exchanges, ...returnOrder.refunds];
      dispatch(addReturnItems(returnItems));

      dispatch(setQuestions(returnOrder.questions));

      const nextStepEnum = Object.values(ReturnSteps).indexOf(nextStep);
      dispatch(changeReturnStep(nextStepEnum));

      const nextRoute = getRoutes(shopSlug, returnOrder.id)[nextStepEnum];
      history.push(nextRoute);
    } catch (err) {
      dispatch(setError(err));
    } finally {
      dispatch(clearLoading());
    }
  };

  return (
    <Button basic onClick={onLodgeButtonClick} compact>
      <Icon name="plus" />
      <span>Lodge Another Return</span>
    </Button>
  );
};
