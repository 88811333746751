import { combineReducers } from "redux";

import { returnStepReducer } from "./returnStep";
import { errorsReducer } from "./errors";
import { formModalReducer } from "./formModal";
import { frontendConfigsReducer } from "./frontendConfigs";
import { localeReducer } from "./locale";
import { loadingReducer } from "./loading";
import { orderReducer } from "./order";
import { returnItemsReducer } from "./returnItems";
import { sampleReducer } from "./sample";
import { currentShopReducer } from "./currentShop";
import { holidaysReducer } from "./holidays";
import {
  FormModal,
  FrontendConfigs,
  Holiday,
  Order,
  ReturnItems,
  ReturnSteps,
  Sample,
  CurrentShop,
  ShopLocale
} from "actions";

export interface StoreState {
  errors: Error[];
  formModal: FormModal;
  frontendConfigs: FrontendConfigs;
  holidays: Holiday[];
  locale: ShopLocale;
  loading: boolean;
  order: Order;
  returnItems: ReturnItems;
  returnStep: ReturnSteps;
  sample: Sample;
  currentShop: CurrentShop;
}

export const reducers = combineReducers<StoreState>({
  errors: errorsReducer,
  formModal: formModalReducer,
  frontendConfigs: frontendConfigsReducer,
  holidays: holidaysReducer,
  locale: localeReducer,
  loading: loadingReducer,
  order: orderReducer,
  returnItems: returnItemsReducer,
  returnStep: returnStepReducer,
  sample: sampleReducer,
  currentShop: currentShopReducer
});
