import React from "react";
import {
  LineItem,
  ReturnItems,
  ReturnItem,
  setLineItemInFormModal,
  clearCurrentReturnItem,
  setCurrentReturnItemProperty,
  openFormModal,
  setReturnItemDirty,
  removeReturnItemFromCart
} from "actions";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { createUUID } from "helpers";
import { Card, Grid } from "semantic-ui-react";
import { ReturnMethodTag, Button, DeclineReason, SquareImage } from "components";
import { Trans, useTranslation } from "react-i18next";
import styles from "./LineItemCard.module.css";

interface Props {
  lineItem: LineItem;
}

export const LineItemCard = ({ lineItem }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");

  const order = useSelector((state: StoreState) => state.order);
  const returnItems = useSelector((state: StoreState) => state.returnItems);

  const getReturnItemsInCart = (lineItem: LineItem, returnItems: ReturnItems): ReturnItem[] => {
    return returnItems.cart.filter(returnItem => returnItem.lineItemId === lineItem.id.toString());
  };

  const handleSelectLineItem = (lineItem: LineItem): void => {
    dispatch(setLineItemInFormModal(lineItem));
    dispatch(clearCurrentReturnItem());
    dispatch(setCurrentReturnItemProperty("uuid", createUUID()));
    dispatch(setCurrentReturnItemProperty("bundleItemId", lineItem.bundleItemId ?? null));
    dispatch(setCurrentReturnItemProperty("lineItemId", lineItem.id.toString()));
    dispatch(setCurrentReturnItemProperty("productId", lineItem.productId));
    dispatch(setCurrentReturnItemProperty("variantId", lineItem.variantId));
    dispatch(setCurrentReturnItemProperty("productTitle", lineItem.productTitle));
    dispatch(setCurrentReturnItemProperty("variantTitle", lineItem.variantTitle));
    dispatch(setCurrentReturnItemProperty("fulfillmentStatus", lineItem.fulfillmentStatus));
    dispatch(setCurrentReturnItemProperty("returnItemImageUrl", lineItem.imageUrl));
    dispatch(setCurrentReturnItemProperty("returnItemPrice", lineItem.unitPrice));
    dispatch(setCurrentReturnItemProperty("taxPrice", lineItem.taxPrice));
    dispatch(setCurrentReturnItemProperty("taxRate", lineItem.taxRate));
    dispatch(setCurrentReturnItemProperty("discountAmount", lineItem.discountAmount));
    dispatch(setCurrentReturnItemProperty("sku", lineItem.sku));
    dispatch(setCurrentReturnItemProperty("shopPrice", lineItem.shopPrice));
    dispatch(setCurrentReturnItemProperty("returnItemName", lineItem.name));
    dispatch(setCurrentReturnItemProperty("vendor", lineItem.vendor));
    dispatch(openFormModal());
  };

  const isEditable = ["pending", "draft", "update_draft"].includes(order.status);

  const returnItemsInCart = getReturnItemsInCart(lineItem, returnItems);
  const isReturnable = returnItemsInCart.length < lineItem.quantity && isEditable && lineItem.returnable;
  const handleClick = (): void => {
    if (isReturnable) handleSelectLineItem(lineItem);
  };
  const cursorStyle = isReturnable ? "pointer" : "not-allowed";
  const opacityStyle = lineItem.returnable ? 1 : 0.5;
  const unreturnableReason = !lineItem.returnable && (
    <p className={styles.unreturnableReason}>{lineItem.unreturnableReason}</p>
  );

  const showCallToActionButton =
    isEditable && returnItemsInCart.length > 0 && returnItemsInCart.length < lineItem.quantity;

  const notReturnable = lineItem.quantity === 0;

  return (
    <Card
      fluid
      key={lineItem.id}
      onClick={handleClick}
      style={{ cursor: cursorStyle, opacity: opacityStyle }}
      disable={notReturnable.toString()}
    >
      <Card.Content>
        <Grid>
          <Grid.Column mobile={4} tablet={4} computer={4}>
            <SquareImage src={lineItem.imageUrl} />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={12} computer={12}>
            <Card.Description className={notReturnable ? styles.disabledItemTitle : styles.itemTitle}>
              {lineItem.name}
            </Card.Description>
            <Card.Meta>
              {notReturnable ? (
                <Trans i18nKey="orderSummary.notReturnableItem">
                  {t("orderSummary.notReturnableItem", {
                    count: returnItemsInCart.length
                  })}
                </Trans>
              ) : (
                <Trans i18nKey="orderSummary.itemCount">
                  {t("orderSummary.itemCount", {
                    count: returnItemsInCart.length,
                    total: lineItem.quantity
                  })}
                </Trans>
              )}
            </Card.Meta>
            <Card.Meta>
              <span className={styles.itemMetaText}>{lineItem.displayPriceString}</span>
            </Card.Meta>
            <ReturnMethodTag
              cancellable={isEditable}
              onCancel={(id: string) => {
                dispatch(setReturnItemDirty());
                dispatch(removeReturnItemFromCart(id));
              }}
              returnItems={returnItemsInCart}
            />
            {unreturnableReason}
            {showCallToActionButton && (
              <div style={{ marginTop: "10px" }}>
                <Button color="primaryColor" onClick={handleClick} size="small">
                  {t("orderSummary.returnNextItem")}
                </Button>
              </div>
            )}
            <DeclineReason returnItems={returnItemsInCart} />
          </Grid.Column>
        </Grid>
      </Card.Content>
    </Card>
  );
};
