import * as Sentry from "@sentry/react";
import qs from "qs";
import { fetchClient } from "api/constants";

export const getShippingMethods = async (shopId: number, returnOrderId: string): Promise<any> => {
  try {
    const query = {
      filter: {
        shopId,
        enabled: true
      },
      policy_rule_data: {
        returnOrderId
      }
    };

    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    return fetchClient(`/api/v2/customer/shipping_methods.json?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};
