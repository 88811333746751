import React from "react";
import { Grid } from "semantic-ui-react";
import { Button } from "components";

interface Props {
  helperText?: string;
  action: {
    label: string;
    onClick: () => void;
    disabled: boolean;
  };
}

export const ContinueBottomAppBar = ({ helperText, action }: Props) => (
  <div
    style={{
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 0.11) 0 -4px 4px 0"
    }}
  >
    <Grid centered container padded="vertically" style={{}}>
      <Grid.Column
        mobile={16}
        tablet={13}
        computer={10}
        style={{
          margin: "16px auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline"
        }}
      >
        <b>{helperText}</b>
        <Button color="primaryColor" disabled={action.disabled} onClick={action.onClick}>
          {action.label}
        </Button>
      </Grid.Column>
    </Grid>
  </div>
);
