import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { getTotalBonusCredit } from "./helpers";

export function useItemBonusCredit() {
  const returnItems = useSelector((state: StoreState) => state.returnItems.cart);
  const lineItem = useSelector((state: StoreState) => state.formModal.selectedLineItem);
  const { exchangeBonusCreditMaxValuePerOrder, storeCreditBonusCreditMaxValuePerOrder } = useSelector(
    (state: StoreState) => state.returnItems.bonusCreditInfo
  );

  if (!lineItem) return { exchangeBonusCredit: 0, storeCreditBonusCredit: 0 };

  const potentialStoreCreditBonusCredit = lineItem.storeCreditBonusCredit;
  const potentialExchangeBonusCredit = lineItem.maxUsableExchangeBonusCredit;
  const totalExchangeBonusCredit = getTotalBonusCredit(returnItems, "Exchange");
  const totalStoreCreditBonusCredit = getTotalBonusCredit(returnItems, "Refund");

  const canUseExchangeBonusCredit = (): boolean => {
    if (exchangeBonusCreditMaxValuePerOrder) {
      const newTotalExchangeBonusCredit = totalExchangeBonusCredit + potentialExchangeBonusCredit!;
      return newTotalExchangeBonusCredit <= exchangeBonusCreditMaxValuePerOrder;
    }
    return true;
  };

  function canUseStoreCreditBonusCredit(): boolean {
    if (storeCreditBonusCreditMaxValuePerOrder) {
      const newTotalStoreCreditBonusCredit = totalStoreCreditBonusCredit + potentialStoreCreditBonusCredit!;
      return newTotalStoreCreditBonusCredit <= storeCreditBonusCreditMaxValuePerOrder;
    }
    return true;
  }

  const exchangeBonusCredit = canUseExchangeBonusCredit() ? potentialExchangeBonusCredit : 0;
  const storeCreditBonusCredit = canUseStoreCreditBonusCredit() ? potentialStoreCreditBonusCredit : 0;

  return { exchangeBonusCredit, storeCreditBonusCredit };
}
