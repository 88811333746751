import { Action, ActionTypes, FormModal, FormModalSteps } from "../actions";

const initialState: FormModal = {
  isOpen: false,
  step: FormModalSteps.stageTwoQuestions,
  selectedLineItem: null,
  stepsHistory: []
};

export const formModalReducer = (state: FormModal = initialState, action: Action): FormModal => {
  switch (action.type) {
    case ActionTypes.closeFormModal:
      return initialState;
    case ActionTypes.openFormModal:
      return {
        ...state,
        isOpen: true,
        step: FormModalSteps.stageTwoQuestions
      };
    case ActionTypes.resumeFormModal:
      return {
        ...state,
        isOpen: true
      };
    case ActionTypes.setLineItemInFormModal:
      return {
        ...state,
        selectedLineItem: action.payload
      };
    case ActionTypes.formModalPush:
      const fromStep = state.step;
      const toStep = action.payload.step;
      return {
        ...state,
        stepsHistory: action.payload.appendStepHistory ? [...state.stepsHistory, fromStep] : [...state.stepsHistory],
        step: toStep
      };
    case ActionTypes.formModalGoBack:
      // Get the previous step from stepsHistory
      const previousStep = state.stepsHistory.pop();
      return {
        ...state,
        step: previousStep ?? state.step,
        // Remove the now current step from the history
        stepsHistory: state.stepsHistory
      };
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
