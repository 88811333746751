import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import * as Sentry from "@sentry/react";
import qs from "qs";
import i18n from "i18n";
import { fetchClient } from "api/constants";

export const getVariants = async (
  shopId: number | null,
  productId: number | null,
  returnMethodId: number,
  orderId: string,
  variantId: number | null,
  lineItemId: string,
  returnOrderId: string,
  bundleItemId: number | null
): Promise<any> => {
  try {
    const query = {
      filter: {
        shopId
      },
      productId,
      returnMethodId,
      orderId,
      variantId,
      lineItemId,
      returnOrderId,
      bundleItemId
    };

    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    return fetchClient(`/api/v2/customer/variants.json?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};

export interface Option {
  name: string;
  position: number;
}

const nullVariantsResponse = {
  variants: [],
  productImageUrls: [],
  options: []
};

function parseVariants(response: any) {
  const variants = response.variants.data.map((variant: { id: string; attributes: Object }) => {
    return {
      id: variant.id,
      ...variant.attributes
    };
  });
  const initialSelectedVariant = response.initialSelectedVariant?.attributes;
  return { ...response, variants, initialSelectedVariant };
}

function useVariants(productId: number | null) {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const locale = i18n.language;
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const returnMethodId = currentReturnItem.returnMethod.returnMethodId;
  const orderId = useSelector((state: StoreState) => state.order.id);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const variantId = currentReturnItem.variantId;
  const lineItemId = currentReturnItem.lineItemId;
  const bundleItemId = currentReturnItem.bundleItemId;

  const { data, ...others } = useQuery({
    queryKey: [
      "variants",
      shopId,
      productId,
      returnMethodId,
      orderId,
      productId,
      variantId,
      lineItemId,
      locale,
      bundleItemId
    ],
    queryFn: () =>
      getVariants(shopId, productId, returnMethodId, orderId, variantId, lineItemId, returnOrderId, bundleItemId),
    enabled: !!productId
  });

  const response = data ? parseVariants(data) : nullVariantsResponse;
  return { ...response, ...others };
}

export { useVariants };
