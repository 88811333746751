import { formModalPush, FormModalSteps, setCurrentReturnItemProperty } from "actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { StoreState } from "reducers";
import { Card, Image } from "semantic-ui-react";
import styles from "./ExchangeProductSelector.module.css";
import { CollectionProduct } from "./types";

interface Props {
  product: CollectionProduct;
}

export const ProductCard = ({ product }: Props) => {
  const dispatch = useDispatch();
  const productImageObjectFit = useSelector(
    (state: StoreState) => state.currentShop.exchangeSetting.productImageObjectFit
  );

  function handleProductOnClick(product: CollectionProduct) {
    dispatch(
      setCurrentReturnItemProperty("selectedProduct", {
        id: parseInt(product.id),
        title: product.title,
        image: { src: product.imageUrl }
      })
    );
    dispatch(formModalPush(FormModalSteps.variantsSelector));
  }

  return (
    <div style={{ cursor: "pointer" }} onClick={() => handleProductOnClick(product)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Image
          src={product.imageUrl}
          bordered
          size="huge"
          centered
          style={{
            height: isMobile ? "200px" : "380px",
            objectFit: productImageObjectFit
          }}
        />
        <Card.Content
          style={{ padding: "8px", fontSize: isMobile ? "13px" : "15px", lineHeight: isMobile ? "1.5rem" : "1.8rem" }}
        >
          <span>{product.title}</span>
          <br />
          <div>
            {product.variantsHaveDifferentPrice && (
              <span>
                <i>from </i>
              </span>
            )}
            <b className={styles.price}>{product.cheapestVariantPrice}</b>
            <wbr></wbr>
            {Boolean(product.cheapestVariantCompareAtPrice) && (
              <del className={styles.compareAtPrice}>{product.cheapestVariantCompareAtPrice}</del>
            )}
          </div>
        </Card.Content>
      </div>
    </div>
  );
};
