import { AxiosResponse } from "axios";

import { Order } from "../actions";
import { FETCH_ORDER_URL, PostCo360API } from "./constants";
import { searchParamsToString } from "./utils";
import { sanitizeAddress } from "components/AddressFields/helper";
import camelcaseKeys from "camelcase-keys";

export const fetchOrder = async (shopSlug: string, returnOrderId: string): Promise<Order> => {
  try {
    const searchParams = {
      shopSlug,
      returnOrderId
    };

    const response: AxiosResponse<any> = await PostCo360API().get(
      `${FETCH_ORDER_URL}${searchParamsToString(searchParams)}`
    );
    const data = camelcaseKeys({ ...response.data.data }, { deep: true });

    return {
      customer: data.attributes.customer.data.attributes,
      email: data.attributes.email,
      id: data.id,
      name: data.attributes.name,
      lineItems: data.attributes.lineItems.data.map((lineItem: any) =>
        camelcaseKeys(lineItem.attributes, { deep: true })
      ),
      phone: data.attributes.phone,
      orderCreatedAt: data.attributes.orderCreatedAt,
      returnOrderId: data.attributes.returnOrderId,
      shippingAddress: sanitizeAddress(camelcaseKeys(data.attributes.shippingAddress)),
      status: data.attributes.status,
      currency: data.attributes.currency,
      stripeIntegrationEnabled: data.attributes.stripeIntegrationEnabled
    };
  } catch (error) {
    throw Error(error.message);
  }
};

export const deleteOrder = async (orderId: string | number): Promise<void> => {
  try {
    await PostCo360API().delete(`/api/v2/customer/return_orders/${orderId}`);
  } catch (error) {
    throw Error(`Error deleting order: ${error.message}`);
  }
};
