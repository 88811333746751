import { Action, ActionTypes, ReturnItems, ReturnItem } from "../actions";

const initialState: ReturnItems = {
  shippingMethod: {
    apiProvider: "",
    availableForCustomerBorne: false,
    badge: "",
    courier: {
      id: "",
      name: "",
      country: "",
      onDemand: false,
      weekend: false,
      holiday: false,
      startTime: "",
      cutoffTime: "",
      supportedPostcodes: [],
      sameDayDelivery: false,
      hourRange: 0,
      noticeHour: 0,
      allowCustomTrackingNumber: false,
      pickup: false,
      dropOff: false,
      dropOffLocationsUrl: "",
      logoUrl: ""
    },
    customerBorne: false,
    description: "",
    dropOffLocationsUrl: "",
    title: "",
    uuid: "",
    id: "",
    logoUrl: "",
    name: "",
    enabled: false,
    fixedPrice: false,
    priceCents: 0,
    priceCurrency: "",
    requiresLabel: false,
    retailerMarkup: 0,
    customerMarkup: 0,
    shopId: 0,
    storeListUrl: "",
    serviceType: "OnDemandPickup",
    type: "IntegratedShippingMethod",
    rightLabel: ""
  },
  customerBorneShipping: false,
  fromAddress: null,
  questions: [],
  cart: [],
  bonusCreditInfo: {
    exchangeBonusCreditMaxValuePerOrder: 0,
    storeCreditBonusCreditMaxValuePerOrder: 0
  },
  currentReturnItem: {
    uuid: "",
    bonusCreditAmountCents: 0,
    bundleItemId: null,
    currentProductPageInfo: "",
    discountAmount: 0,
    extraNote: "",
    images: [],
    fulfillmentStatus: null,
    lineItemId: "",
    productId: null,
    productTitle: "",
    price: "0.00",
    questions: [],
    rejectReason: "",
    status: "pending",
    returnMethod: {
      id: "",
      description: "",
      displayName: "",
      enabled: false,
      globalId: "",
      hasCustomName: false,
      name: "",
      returnMethodId: 0,
      shopId: 0,
      type: ""
    },
    returnItemImageUrl: "",
    returnItemName: "",
    returnItemPrice: 0,
    returnMethodType: "",
    returnReason: {
      id: "",
      extraNote: false,
      optionalExtraNote: false,
      globalId: "",
      imageUpload: false,
      optionalImageUpload: false,
      name: ""
    },
    selectedCollectionId: "",
    selectedProduct: {
      id: null,
      title: "",
      image: {
        src: ""
      }
    },
    shopPrice: 0,
    sku: "",
    taxPrice: 0,
    variantId: null,
    variantTitle: "",
    variantPrice: "",
    vendor: "",
    taxRate: 0
  },
  isDirty: false,
  instantExchange: false,
  instantExchangeSet: false,
  p2pReturns: false
};

export const returnItemsReducer = (state: ReturnItems = initialState, action: Action): ReturnItems => {
  switch (action.type) {
    case ActionTypes.clearCurrentReturnItem:
      return {
        ...state,
        currentReturnItem: {
          ...initialState.currentReturnItem
        }
      };
    case ActionTypes.setCurrentReturnItemProperty:
      return {
        ...state,
        currentReturnItem: {
          ...state.currentReturnItem,
          [action.payload.property]: action.payload.value
        }
      };
    case ActionTypes.appendCurrentReturnItem:
      return {
        ...state,
        isDirty: true,
        cart: [...state.cart, state.currentReturnItem],
        currentReturnItem: { ...initialState.currentReturnItem }
      };
    case ActionTypes.addReturnItems:
      return {
        ...state,
        cart: action.payload
      };
    case ActionTypes.addBonusCreditInfo:
      return {
        ...state,
        bonusCreditInfo: action.payload
      };
    case ActionTypes.removeReturnItemFromCart:
      return {
        ...state,
        cart: state.cart.filter((returnItem: ReturnItem) => returnItem.uuid !== action.payload)
      };
    case ActionTypes.setReturnItemDirty:
      return { ...state, isDirty: true };
    case ActionTypes.setShippingMethod:
      return { ...state, shippingMethod: action.payload };
    case ActionTypes.setQuestions:
      return { ...state, questions: action.payload };
    case ActionTypes.setCustomerBorneShipping:
      return { ...state, customerBorneShipping: action.payload };
    case ActionTypes.setFromAddress:
      return { ...state, fromAddress: action.payload };
    case ActionTypes.setInstantExchange:
      return { ...state, instantExchange: action.payload, instantExchangeSet: true };
    case ActionTypes.setP2PReturns:
      return { ...state, p2pReturns: action.payload };
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
