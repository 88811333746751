export enum ReturnOrderStatuses {
  update_draft,
  draft,
  pending,
  reviewed,
  received,
  resolving,
  pending_payment_from_customer,
  stripe_account_disabled,
  pending_action,
  completing,
  handle_manually,
  completed,
  archived
}
