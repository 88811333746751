import React, { Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Button as SuiBtn, ButtonProps, StrictButtonProps } from "semantic-ui-react";
import { FrontendConfigs } from "actions";
import chroma from "chroma-js";

interface ComponentProps extends Omit<ButtonProps, "color"> {
  color?: StrictButtonProps["color"] | "primaryColor" | "cancelGray";
  disabled?: boolean;
}

interface ReduxStateProps {
  primaryColor: FrontendConfigs["primaryColor"];
}

type Props = ReduxStateProps & ComponentProps;

const _Button: React.FC<Props> = ({
  primaryColor,
  children,
  dispatch: _dispatch,
  color,
  disabled = false,
  ...args
}) => {
  const primaryButtonTextColor = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.primaryButtonTextColor
  );
  const getColor = (primaryColor: string | null) => {
    const black = "black";
    const white = "white";
    if (primaryColor) {
      const blackContrast = chroma.contrast(primaryColor, black);
      const whiteContrast = chroma.contrast(primaryColor, white);
      return blackContrast > whiteContrast ? black : white;
    } else {
      return "black";
    }
  };

  const randomString = Math.random()
    .toString(36)
    .substr(2, 5);

  const customId = `custom-btn__${randomString}`;

  switch (color) {
    case "primaryColor":
      return (
        <Fragment>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          #${customId} {
            background-color: ${primaryColor} !important;
            color: ${primaryButtonTextColor || getColor(primaryColor)} !important;
            font-weight: bold !important;
          }
          #${customId}.basic {
            box-shadow: 0px 0px 0px 1px ${primaryColor} inset, 0px 0px 0px 0px ${primaryColor} inset;
            color: ${primaryColor} !important;
            background-color: white !important;
          }
          /* Uses id to override SemanticUI styles :( */
          #lowPriorityNormalReturnMethodButton {
            color: black !important;
          }
        `
            }}
          />
          <div style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
            <SuiBtn id={customId} disabled={disabled} {...args}>
              {children}
            </SuiBtn>
          </div>
        </Fragment>
      );
    case "cancelGray":
      const lighterGray = "#f4f4f4";
      const darkerGray = "#817a7a7";

      return (
        <Fragment>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          #${customId} {
            background-color: ${lighterGray} !important;
            color: ${darkerGray} !important;
            font-weight: bold !important;
          }
          #${customId}.basic {
            box-shadow: 0px 0px 0px 1px ${lighterGray} inset, 0px 0px 0px 0px ${lighterGray} inset;
            color: ${lighterGray} !important;
            background-color: white !important;
          }
          /* Uses id to override SemanticUI styles :( */
          #lowPriorityNormalReturnMethodButton {
            color: ${lighterGray} !important;
          }
        `
            }}
          />
          <div style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
            <SuiBtn id={customId} disabled={disabled} {...args}>
              {children}
            </SuiBtn>
          </div>
        </Fragment>
      );
    default:
      return (
        <div style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
          <SuiBtn color={color} disabled={disabled} {...args}>
            {children}
          </SuiBtn>
        </div>
      );
  }
};

const mapStateToProps = ({ frontendConfigs: { primaryColor } }: StoreState): ReduxStateProps => ({
  primaryColor
});

const Button = connect<ReduxStateProps, null, ComponentProps, StoreState>(mapStateToProps)(_Button);

export default Button;
