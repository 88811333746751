import { appendCurrentReturnItem, closeFormModal, ReturnMethod, setCurrentReturnItemProperty } from "actions";
import { Button as CustomButton, PageHeader } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { useItemBonusCredit } from "../hooks";
import styles from "./ReturnMethodsSelector.module.css";

interface Props {
  storeCreditMethods: ReturnMethod[];
}

const listingPriorities: Record<string, number> = {
  rise_ai_store_credit: 0,
  apparel21_voucher: 1,
  gift_card: 2,
  discount_code: 3
};

export const StoreCreditMethodsSelector = ({ storeCreditMethods }: Props) => {
  const { storeCreditBonusCredit } = useItemBonusCredit();
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");

  const sortedStoreCreditMethods = storeCreditMethods.sort((a: ReturnMethod, b: ReturnMethod) => {
    return listingPriorities[a.name] - listingPriorities[b.name];
  });
  const [selectedStoreCreditMethod, setSelectedStoreCreditMethod] = useState<ReturnMethod | null>(
    sortedStoreCreditMethods[0]
  );

  function handleContinue() {
    dispatch(setCurrentReturnItemProperty("bonusCreditAmountCents", storeCreditBonusCredit));
    dispatch(setCurrentReturnItemProperty("returnMethod", selectedStoreCreditMethod));
    dispatch(appendCurrentReturnItem());
    dispatch(closeFormModal());
  }

  if (sortedStoreCreditMethods.length === 1) {
    handleContinue();
  }

  return (
    <div>
      <PageHeader header={t("selectStoreCreditOnly") as string} />
      {sortedStoreCreditMethods.map(storeCreditMethod => {
        const selected = selectedStoreCreditMethod?.id === storeCreditMethod.id;
        return (
          <div className={styles.buttonContainer} key={storeCreditMethod.id}>
            <Button
              basic
              color={selected ? "green" : undefined}
              fluid
              onClick={() => setSelectedStoreCreditMethod(storeCreditMethod)}
            >
              <div className={styles.buttonContent}>
                <div className={styles.descriptionContainer}>
                  <div className={styles.textBox}>
                    <div className={styles.titleContainer}>
                      <strong>{storeCreditMethod.displayName}</strong>
                    </div>
                    <p>{storeCreditMethod.description}</p>
                  </div>
                </div>
                <Icon
                  color="green"
                  name="check circle"
                  style={{
                    visibility: selected ? "visible" : "hidden"
                  }}
                />
              </div>
            </Button>
          </div>
        );
      })}
      <div className={styles.buttonContainer}>
        <CustomButton color="primaryColor" disabled={!selectedStoreCreditMethod} fluid onClick={() => handleContinue()}>
          {t("continue")}
        </CustomButton>
      </div>
    </div>
  );
};
