import React from "react";
import { VariantOptionButton, VariantOptionDropdown } from "./";
import styles from "./VariantSelector.module.css";

interface VariantOptionProps {
  optionIndex: "option1" | "option2" | "option3";
  handleOnClick: (optionValue: string | null) => void;
  optionSelections: (string | null)[];
  optionName: string;
  selectedOption: string | null;
}

export const VariantOption: React.FC<VariantOptionProps> = ({
  optionIndex,
  handleOnClick,
  optionSelections,
  optionName,
  selectedOption
}): JSX.Element => {
  function handleOnSelect(value: string) {
    handleOnClick(value);
  }

  return (
    <div style={{ marginBottom: "8px" }}>
      <div style={{ marginBottom: "3px" }}>{optionName}</div>
      <div className={styles.selectionRow}>
        {optionSelections.length > 4 ? (
          <VariantOptionDropdown
            optionName={optionName}
            onSelect={handleOnSelect}
            optionSelections={optionSelections}
            optionIndex={optionIndex}
            selectedOption={selectedOption || undefined}
          />
        ) : (
          optionSelections.map(optionValue => (
            <VariantOptionButton
              key={`${optionIndex}-${optionValue}`}
              onClick={handleOnClick}
              isSelected={selectedOption === optionValue}
              option={optionIndex}
              optionValue={optionValue}
            />
          ))
        )}
      </div>
    </div>
  );
};
