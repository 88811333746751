import * as Sentry from "@sentry/react";
import qs from "qs";
import { fetchClient, PostCo360API } from "api/constants";
import { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";

export const getReturnReasons = async (
  shopId: number | null,
  orderId: string,
  returnOrderId: string,
  productId: number | null,
  variantId: number | null,
  bundleItemId: number | null,
  lineItemId: string
): Promise<any> => {
  try {
    const query = {
      filter: {
        shopId
      },
      policy_rule_data: {
        orderId,
        returnOrderId,
        productId,
        variantId,
        lineItemId,
        bundleItemId
      },
      evaluatePolicyRule: true
    };

    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    return fetchClient(`/api/v2/return_reasons.json?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};

export const evaluateReturnabilityByReturnReason = async (
  shopId: number | null,
  orderId: string,
  returnOrderId: string,
  productId: number | null,
  variantId: number | null,
  lineItemId: string,
  bundleItemId: number | null,
  returnReason: string
): Promise<{ returnable: boolean }> => {
  try {
    const query = {
      data: {
        shopId,
        policyRuleOptions: {
          orderId,
          returnOrderId,
          productId,
          variantId,
          lineItemId,
          bundleItemId,
          returnReason
        }
      }
    };
    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    const response: AxiosResponse<{ returnable: boolean }> = await PostCo360API().get(
      `/api/v2/return_reasons/return_item_returnable_evaluation?${queryString}`
    );

    return camelcaseKeys(response.data);
  } catch (error) {
    throw Error(error.message);
  }
};
