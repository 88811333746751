import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface StripeIntent {
  clientSecret: string;
  type: "payment_itent" | "setup_intent";
}

const nullStripeIntent: StripeIntent = {
  clientSecret: "",
  type: "setup_intent"
};

export const useStripeIntent = (options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const instantExchange = useSelector((store: StoreState) => store.returnItems.instantExchange);
  const { data, ...others } = useQuery({
    queryKey: ["clientSecretKey", instantExchange, returnOrderId],
    queryFn: () => getStripeIntent(returnOrderId, instantExchange),
    ...options
  });

  const { clientSecret, type } = data ?? nullStripeIntent;
  return { clientSecret, type, ...others };
};

export const getStripeIntent = async (returnOrderId: string, instantExchange: boolean) => {
  try {
    const response = await PostCo360API().get(
      `api/v2/customer/return_orders/${returnOrderId}/stripe_intent?instant_exchange=${Boolean(instantExchange)}`
    );
    return camelcaseKeys(response.data, { deep: true }) as StripeIntent;
  } catch (error) {
    throw Error(error.message);
  }
};
