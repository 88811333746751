import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { ReturnOrderStatuses } from "constants/returnOrder";
import { useDispatch } from "react-redux";
import { fetchReturnOrder } from "api";
import { useQuery } from "react-query";
import {
  ReturnItem,
  ReturnSteps,
  setError,
  setInstantExchange,
  setP2PReturns,
  addReturnItems,
  addBonusCreditInfo,
  setQuestions,
  changeReturnStep
} from "actions";

export const useReturnOrderStatus = () => {
  const returnOrderStatus = useSelector((store: StoreState) => store.order.status);
  const returnOrderStatusIndex = ReturnOrderStatuses[returnOrderStatus as keyof typeof ReturnOrderStatuses];
  const cart = useSelector((store: StoreState) => store.returnItems.cart);
  const allReturnItemsRejected = cart.every(item => item.status === "rejected");

  const returnItemsReceived = returnOrderStatusIndex >= ReturnOrderStatuses.received && !allReturnItemsRejected;
  const returnOrderReviewed = returnOrderStatusIndex >= ReturnOrderStatuses.reviewed;
  const completedReturnOrder = returnOrderStatusIndex >= ReturnOrderStatuses.completed && !allReturnItemsRejected;
  const editableReturnOrder = ["draft", "update_draft"].includes(returnOrderStatus);

  return { returnItemsReceived, completedReturnOrder, editableReturnOrder, returnOrderReviewed };
};

export const useReturnOrder = (returnOrderId: string, orderIdOrName: string, emailOrPhone: string, options = {}) => {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const dispatch = useDispatch();

  const params = { returnOrderId, orderIdOrName, emailOrPhone, shopId };
  const query = useQuery({
    queryKey: ["returnOrder", returnOrderId, orderIdOrName, emailOrPhone],
    queryFn: () => fetchReturnOrder(params),
    onSuccess: ({ returnOrder, nextStep }) => {
      const returnItems: ReturnItem[] = [...returnOrder.exchanges, ...returnOrder.refunds];
      const nextStepEnum = Object.values(ReturnSteps).indexOf(nextStep);
      const bonusCreditInfo = {
        exchangeBonusCreditMaxValuePerOrder: returnOrder.exchangeBonusCreditMaxValuePerOrder,
        storeCreditBonusCreditMaxValuePerOrder: returnOrder.storeCreditBonusCreditMaxValuePerOrder
      };

      dispatch(setP2PReturns(returnOrder.p2pReturns));
      dispatch(setInstantExchange(returnOrder.instantExchange));
      dispatch(addReturnItems(returnItems));
      dispatch(addBonusCreditInfo(bonusCreditInfo));
      dispatch(setQuestions(returnOrder.questions));
      dispatch(changeReturnStep(nextStepEnum));
    },
    onError: err => {
      if (err instanceof Error) dispatch(setError(err));
    },
    // need to refetch return order info for exchangeability validation purpose
    // cart items' exchangeableByAvailability will change if the exchanging variant id is no longer available on ecom platform
    // set staleTime to 10 minutes since we don't want to refetch too often
    staleTime: 60000 * 10, // 10 minutes
    refetchInterval: 60000 * 10, // 10 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    ...options
  });

  return query;
};
