import { fetchReturnOrder } from "./api";
import { useQuery } from "react-query";
import { nullReturnOrder, ReturnOrder } from "./types";

export function useReturnOrderWithEmail(returnOrderId: string, email: string | null) {
  const { data, ...others } = useQuery({
    queryKey: ["ReturnOrderWithEmail", returnOrderId],
    queryFn: () => fetchReturnOrder(returnOrderId, email)
  });
  const returnOrder: ReturnOrder = data ? data : nullReturnOrder;
  return { returnOrder, ...others };
}
