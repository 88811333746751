import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import * as Sentry from "@sentry/react";
import qs from "qs";
import { fetchClient } from "api/constants";
import camelcaseKeys from "camelcase-keys";
import { ShippingZone } from "types/shippingZone";
import { hasExchange } from "pages/ShippingAddressDetails/helpers";

export const getShippingZones = async (shopId: number | null, cartPrice: number, cartWeight: number): Promise<any> => {
  try {
    const query = {
      filter: {
        shopId
      },
      cartPrice,
      cartWeight
    };
    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    return fetchClient(`/api/v2/customer/shipping_zones.json?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};

function useShippingZones() {
  const ecomProvider = useSelector((state: StoreState) => state.currentShop.ecomProvider);
  const isShopify = ecomProvider === "Shopify";
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const cartPrice = cart.reduce(
    (accumulator, returnItem) => accumulator + parseFloat(returnItem.exchangingVariant?.price || "0"),
    0
  );
  const cartWeight = cart.reduce(
    (accumulator, returnItem) => accumulator + (returnItem.exchangingVariant?.grams || 0),
    0
  );
  const shopId = useSelector((state: StoreState) => state.currentShop.id);

  const { data, ...others } = useQuery({
    queryKey: ["shippingZones", shopId, cartPrice, cartWeight],
    queryFn: () => getShippingZones(shopId, cartPrice, cartWeight),
    enabled: isShopify && hasExchange(cart)
  });
  const shippingZones: ShippingZone[] = data ? camelcaseKeys(data) : [];

  return { shippingZones, ...others };
}

export { useShippingZones };
