import { Action, ActionTypes, Sample } from "../actions";

const initialState: Sample = "";

export const sampleReducer = (
  state: Sample = initialState,
  action: Action
): Sample => {
  switch (action.type) {
    case ActionTypes.normalSample:
      return action.payload;
    case ActionTypes.thunkSample:
      return action.payload;
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
