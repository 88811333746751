import { setCurrentReturnItemProperty } from "actions";
import { Loading } from "components/Loading/Loading";
import { useReturnMethods } from "hooks/useReturnMethods";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { FormModalContent } from "../FormModalContent/FormModalContent";
import { ExchangeMethodsSelector } from "./ExchangeMethodsSelector";
import { RefundMethodsSelector } from "./RefundMethodsSelector";
import { StoreCreditMethodsSelector } from "./StoreCreditMethodsSelector";

export const ReturnMethodsSelector = () => {
  const dispatch = useDispatch();

  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const selectedReturnMethodType = currentReturnItem.returnMethodType;
  const previouslySelectedProduct = currentReturnItem.selectedProduct;

  const { storeCreditMethods, refundMethods, exchangeMethods, isLoading } = useReturnMethods();

  function selectedSelector() {
    switch (selectedReturnMethodType) {
      case "refund":
        return <RefundMethodsSelector refundMethods={refundMethods} />;
      case "exchange":
        return <ExchangeMethodsSelector exchangeMethods={exchangeMethods} />;
      case "storeCredit":
        return <StoreCreditMethodsSelector storeCreditMethods={storeCreditMethods} />;
    }
  }

  // Reset selectedProduct
  if (previouslySelectedProduct.id) {
    dispatch(
      setCurrentReturnItemProperty("selectedProduct", {
        id: null,
        title: "",
        image: { src: "" }
      })
    );
  }

  return <FormModalContent>{isLoading ? <Loading /> : selectedSelector()}</FormModalContent>;
};
