import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ReactQueryDevtools } from "react-query/devtools";
import { FeatureFlagsProvider } from "contexts/FeatureFlagsProvider";
import {
  NotFound,
  Success,
  ShippingAddressDetails,
  ReceivablePaymentDetails,
  SummaryAndPaymentDetails,
  ShipmentScheduleAndPayment,
  StageOneQuestions,
  ReturnItemsSelection,
  P2PRequestPage,
  P2PProductListingPage,
  PublicSummary,
  ShippingMethodSelector,
  CustomerInfo,
  Submitted,
  SummaryDetails,
  ExchangeOrderPayment,
  InstantExchangePayment
} from "pages";
import { ErrorMessages, AuthenticatedRoute, FloatingBackButton, Loading, Navbar } from "components";
import { StoreState } from "reducers";
import {
  CurrentShop,
  getAndSetCurrentShop,
  setShopSlug,
  getAndSetHolidays,
  FrontendConfigs,
  ShopLocale,
  resetStore,
  setLocale
} from "actions";
import * as Sentry from "@sentry/react";
import camelcase from "camelcase";
import { useApplicationVersion } from "hooks/useApplicationVersion";
import { useLowerCaseShopSlug } from "hooks/useLowerCaseShopSlug";
import { AuthProvider } from "contexts/AuthProvider";
import { useIsEmbedded } from "./hooks/useIsEmbedded";

interface AppProps {
  currentShop: CurrentShop;
  configLoading: boolean;
  locale: ShopLocale;
  loading: boolean;
  getAndSetCurrentShop: Function;
  resetStore: Function;
  getAndSetHolidays: () => Promise<void>;
  setShopSlug: typeof setShopSlug;
  setLocale: typeof setLocale;
  title: FrontendConfigs["title"];
  faviconUrl: FrontendConfigs["faviconUrl"];
}

const _App: React.FC<AppProps> = ({
  currentShop,
  configLoading,
  locale,
  loading,
  getAndSetCurrentShop,
  resetStore,
  getAndSetHolidays,
  setLocale,
  title,
  faviconUrl
}): JSX.Element => {
  const { isFetching: fetchingApplicationVersion } = useApplicationVersion();
  const shopSlug = window.location.href.split("?")[0].split("/")[3];
  const { i18n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamLocale = searchParams.get("locale");

  useLowerCaseShopSlug(shopSlug);
  const shopLocales = useSelector((state: StoreState) => state.currentShop.shopLocales);

  // Check the locale from the URL on load and set it in the redux store
  useEffect(() => {
    if (shopLocales.length > 0 && searchParamLocale) {
      const matchedshopLocale = shopLocales.find(shopLocale => shopLocale.code === searchParamLocale);
      if (searchParamLocale && matchedshopLocale) setLocale(matchedshopLocale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopLocales]);

  useEffect(() => {
    if (faviconUrl) setFavicon(faviconUrl);
  }, [faviconUrl]);

  // Listen for changes to the locale changes in the redux store and update the locale accordingly
  // title is also updated here and uses the locale to determine the translation to use
  useEffect(() => {
    i18n.changeLanguage(locale.code).then(_e => {
      document.title = getTitle(title);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, title]);

  useEffect(() => {
    if (window.APPLICATION_VERSION) {
      if (currentShop.shopSlug) {
        if (currentShop.shopSlug === shopSlug) {
          getAndSetHolidays();
        } else {
          resetStore();
          getAndSetCurrentShop(shopSlug);
        }
      } else {
        getAndSetCurrentShop(shopSlug);
      }
    }
    // eslint-disable-next-line
  }, [currentShop.id, window.APPLICATION_VERSION]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const isEmbedded = currentUrl.searchParams.get("embed") === "true";

    // Redirect to the embedded version of the app if the embed param is true, for backwards compatibility
    if (isEmbedded) {
      const { host, protocol } = currentUrl;
      const hostIsLocalhost = host.includes("localhost");
      const updatedHost = hostIsLocalhost ? `embed.${host}` : `embed-${host}`;
      const localeSearchParam = currentUrl.searchParams.get("locale");
      const searchParams = localeSearchParam ? `?locale=${localeSearchParam}` : "";
      const embeddedUrl = `${protocol}//${updatedHost}/${shopSlug}${searchParams}`;

      window.location.href = embeddedUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isEmbedded } = useIsEmbedded();

  const location = useLocation();
  useEffect(() => {
    if (isEmbedded) {
      window.top?.postMessage({ type: "scrollToTop" }, "*");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setFavicon = (url: URL) => {
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.rel = "shortcut icon";
    link.href = url.href;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const DEFAULT_TITLE = "PostCo: Returns Center";

  const getTitle = (title: Record<string, string> | string) => {
    switch (typeof title) {
      case "object":
        return title[camelcase(i18n.language)] || DEFAULT_TITLE; // title object from rails
      default:
        return DEFAULT_TITLE; // 360 defaults
    }
  };

  const app = currentShop.shopSlug && (
    <AuthProvider>
      <FeatureFlagsProvider>
        <Helmet>
          <style type="text/css">{currentShop.userInterfaceSetting.customCss}</style>
        </Helmet>
        <Navbar baseUrl={`/${shopSlug}`} />
        <FloatingBackButton />
        <Switch>
          <Route component={CustomerInfo} exact path="/:shopSlug" />
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/stage_one_questions">
            <StageOneQuestions />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/return_items_selection">
            <ReturnItemsSelection />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/shipping_method_selection">
            <ShippingMethodSelector />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/shipping_address_details">
            <ShippingAddressDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/receivable_payment_details">
            <ReceivablePaymentDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/summary_and_payment_details">
            <SummaryAndPaymentDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/:shopSlug/returns/:returnId/p2p/requests">
            <P2PRequestPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/:shopSlug/returns/:returnId/p2p/product_listings/:productListingId">
            <P2PProductListingPage />
          </AuthenticatedRoute>
          <Route
            component={ShipmentScheduleAndPayment}
            exact
            path="/:shopSlug/returns/:returnId/shipment_and_payment/edit"
          />
          <Route component={ExchangeOrderPayment} path="/:shopSlug/returns/:returnId/exchange_order_payment" />
          <Route component={InstantExchangePayment} path="/:shopSlug/returns/:returnId/instant_exchange_payment" />
          <Route component={PublicSummary} path="/:shopSlug/returns/:returnId/summary" />
          <Route component={SummaryDetails} path="/:shopSlug/returns/:returnId/details" />
          <Route component={Submitted} exact path="/:shopSlug/returns/:returnId/submitted" />
          <Route component={Success} exact path="/:shopSlug/returns/:returnId" />
          <Route component={NotFound} path="/:shopSlug/*" />
        </Switch>
      </FeatureFlagsProvider>
    </AuthProvider>
  );

  const wrongShopSlugPage = !currentShop.shopSlug && (
    <Switch>
      <Route path="*">
        <NotFound
          header="Shop Return Page Not Found"
          paragraph="Please check with your retailer to make
                     sure that the return page link is correct."
          showBtn={false}
        />
      </Route>
    </Switch>
  );

  const isLoading = (): boolean => {
    return loading || configLoading || fetchingApplicationVersion;
  };

  return (
    <>
      <ErrorMessages />
      {isLoading() ? (
        <Loading fullscreen />
      ) : (
        <React.Fragment>
          {app}
          {wrongShopSlugPage}
        </React.Fragment>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const mapStateToProps = (state: StoreState) => {
  const {
    frontendConfigs: { title, faviconUrl, loading: configLoading },
    locale
  } = state;
  return {
    currentShop: state.currentShop,
    locale,
    loading: state.loading,
    title,
    faviconUrl,
    configLoading
  };
};

export default Sentry.withProfiler(
  connect(mapStateToProps, {
    getAndSetCurrentShop,
    getAndSetHolidays,
    resetStore,
    setShopSlug,
    setLocale
  })(_App)
);
