import { Action, ActionTypes, ReturnSteps } from "../actions";

const initialState: ReturnSteps = ReturnSteps.customerInfo;

export const returnStepReducer = (
  state: ReturnSteps = initialState,
  action: Action
): ReturnSteps => {
  switch (action.type) {
    case ActionTypes.changeReturnStep:
      return action.payload;
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
