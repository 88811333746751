import { Title } from "components";
import React from "react";
import { AccordionPanelProps } from "semantic-ui-react";
import { Summary } from "types";
import { Overview } from "../Overview";
import {
  ReceivablePaymentDetailsSummary,
  ReturnItemsSummary,
  ShippingAddressDetailsSummary,
  ShippingMethodSummary
} from "../ReturnOrderDetails/components";

export const PANEL_KEYS = {
  returnItems: "return-items",
  shippingMethod: "shipping-method",
  shippingInfo: "shipping-info",
  paymentInfo: "payment-info",
  overview: "overview",
  refundOverview: "refund-overview"
};

export const createSummaryPanels = (
  summary: Summary,
  hasOnlineBankTransfer: boolean,
  hasEwalletTransfer: boolean,
  t: Function
): AccordionPanelProps[] => {
  const {
    returnOrderStatus,
    returnItems,
    shippingMethod,
    shipment,
    custEmail,
    shippingAddress,
    shop,
    bankAccount,
    ewalletAccount,
    invoice
  } = summary;
  const returnItemSummaryTitle = t("orderSummary.header");
  const panels = [
    {
      key: PANEL_KEYS.returnItems,
      title: { content: <Title>{returnItemSummaryTitle}</Title> },
      content: { content: <ReturnItemsSummary title={returnItemSummaryTitle} returnItems={returnItems} /> }
    },
    {
      key: PANEL_KEYS.shippingMethod,
      title: { content: <Title>{t("shippingMethodSummary.header")}</Title> },
      content: {
        content: (
          <ShippingMethodSummary
            shipment={shipment}
            custEmail={custEmail}
            shippingMethod={shippingMethod}
            returnOrderStatus={returnOrderStatus}
          />
        )
      }
    },
    {
      key: PANEL_KEYS.shippingInfo,
      title: { content: <Title>{t("addressForm.pageHeader")}</Title> },
      content: {
        content: (
          <ShippingAddressDetailsSummary shippingAddress={shippingAddress} shop={shop} returnItems={returnItems} />
        )
      }
    }
  ];
  if (hasEwalletTransfer || hasOnlineBankTransfer) {
    panels.push({
      key: PANEL_KEYS.paymentInfo,
      title: { content: <Title>{t("receivablePaymentDetails.summaryTitle")}</Title> },
      content: {
        content: (
          <ReceivablePaymentDetailsSummary
            returnItems={returnItems}
            bankAccount={bankAccount}
            ewalletAccount={ewalletAccount}
          />
        )
      }
    });
  }

  panels.push({
    key: PANEL_KEYS.overview,
    title: { content: <Title>{t("invoiceSummary.header")}</Title> },
    content: { content: <Overview invoice={invoice} returnOrderStatus={returnOrderStatus} returnItems={returnItems} /> }
  });

  return panels;
};

export const indexOf = (panels: AccordionPanelProps[], key: string): number => {
  return panels.findIndex(p => p.key === key);
};
