import React from "react";
import { Container, Button } from "semantic-ui-react";
import { Dot } from "pure-react-carousel";
import styles from "./ImageCarousel.module.css";

interface CircleDotProps {
  slides: number;
}

export const CircleDot: React.FC<CircleDotProps> = ({ slides }): JSX.Element => {
  return (
    <Container className={styles.circleDotImageContainer}>
      <Button.Group size={"mini"}>
        {[...Array(slides).keys()].map(slide => (
          <Button
            as={Dot}
            key={slide}
            icon="circle"
            slide={slide}
            style={{ border: "none", background: "none", padding: "0.2rem" }}
          />
        ))}
      </Button.Group>
    </Container>
  );
};
