import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Header, Icon } from "semantic-ui-react";
import { Button, Loading } from "components";
import { Variant, appendCurrentReturnItem, closeFormModal, setCurrentReturnItemProperty, nullVariant } from "actions";
import { StoreState } from "reducers";
import { useVariants, Option } from "hooks/useVariants";
import { getSelectionsFromVariants, getPriceCentsAndCurrency } from "./helpers";
import { getFormattedPriceString } from "helpers";
import { VariantSelectorContext } from "./context";
import { ImageCarousel, VariantOption } from "./";
import styles from "./VariantSelector.module.css";
import parse from "html-react-parser";
import { AdditionalMessage } from "../AdditionalMessage/AdditionalMessage";
import i18next from "i18next";
import camelcase from "camelcase";

type Props = {
  showHeaderText?: boolean;
};

export const VariantSelector: React.FC<Props> = ({ showHeaderText = true }) => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();

  const selectedProduct = useSelector((state: StoreState) => state.returnItems.currentReturnItem.selectedProduct);
  const selectedLineItem = useSelector((state: StoreState) => state.formModal.selectedLineItem);
  const exchangeBalanceRefundMethod = useSelector((state: StoreState) => state.currentShop.exchangeBalanceRefundMethod);
  const exchangeRequestDisclaimer = useSelector((state: StoreState) => state.currentShop.exchangeRequestDisclaimer);
  const sizeGuideUrl = useSelector((state: StoreState) => state.currentShop.exchangeSetting.sizeGuideUrl);
  const currentBonusCreditCents = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem.bonusCreditAmountCents
  );

  const locale = camelcase(i18next.language);
  const customTopUpRequiredMessage = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.topUpRequiredMessageTranslations[locale]
  );

  const { initialSelectedVariant, variants, productImageUrls, options, isLoading } = useVariants(selectedProduct.id);
  const [selectedVariant, setSelectedVariant] = useState<Variant>(nullVariant);
  const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<string | null>(null);
  const [selectedOption3, setSelectedOption3] = useState<string | null>(null);

  const option1Selections = getSelectionsFromVariants(variants, "option1");
  const option2Selections = getSelectionsFromVariants(variants, "option2");
  const option3Selections = getSelectionsFromVariants(variants, "option3");

  const haveOption1 = option1Selections.length > 0;
  const haveOption2 = option2Selections.length > 0;
  const haveOption3 = option3Selections.length > 0;

  useEffect(() => {
    initialSelectedVariant && setSelectedVariant(initialSelectedVariant);
    initialSelectedVariant?.option1 && setSelectedOption1(initialSelectedVariant.option1);
    initialSelectedVariant?.option2 && setSelectedOption2(initialSelectedVariant.option2);
    initialSelectedVariant?.option3 && setSelectedOption3(initialSelectedVariant.option3);
  }, [initialSelectedVariant]);

  const {
    priceCents: selectedLineItemDisplayPriceCents,
    currency: selectedLineItemDisplayPriceCurrency
  } = getPriceCentsAndCurrency(selectedLineItem?.displayPrice);

  const {
    priceCents: selectedVariantDisplayPriceCents,
    currency: selectedVariantDisplayPriceCurrency
  } = getPriceCentsAndCurrency(selectedVariant.displayPrice);

  const exchangeDifferenceCents = selectedVariantDisplayPriceCents - selectedLineItemDisplayPriceCents;
  const finalBonusCreditCents =
    exchangeDifferenceCents > 0 ? Math.min(currentBonusCreditCents, exchangeDifferenceCents) : 0;

  const selectedVariantDisplayPriceFormatted = selectedVariantDisplayPriceCurrency
    ? getFormattedPriceString(selectedVariantDisplayPriceCents, selectedVariantDisplayPriceCurrency)
    : "";

  const selectedVariantTitle =
    selectedVariant.title === "Default Title" || selectedVariant.title === "" ? "" : `- ${selectedVariant.title}`;
  const priceDifferenceCents =
    selectedLineItemDisplayPriceCurrency === selectedVariantDisplayPriceCurrency
      ? exchangeDifferenceCents - finalBonusCreditCents
      : 0;
  const priceDifferenceFormatted = priceDifferenceCents
    ? getFormattedPriceString(Math.abs(priceDifferenceCents), selectedVariantDisplayPriceCurrency)
    : "";

  const topUpRequiredMessage = customTopUpRequiredMessage
    ? customTopUpRequiredMessage.replace("{{ top_up_amount }}", priceDifferenceFormatted)
    : t("variantSelector.topUp", { amount: priceDifferenceFormatted });

  const getImgUrls = () => {
    const imgUrls: string[] = selectedVariant?.imageUrl
      ? [selectedVariant.imageUrl, ...productImageUrls]
      : productImageUrls;

    if (imgUrls.length === 0) {
      return ["https://postco360-attachments.sgp1.cdn.digitaloceanspaces.com/static/blank-white.png"];
    }

    // Only returns the uniq image urls
    return [...new Set(imgUrls)];
  };

  const notShowImage = productImageUrls.length === 0 && variants.every((variant: Variant) => !variant.imageUrl);

  const refundText = (priceDifferenceFormatted: string) => {
    if (["discount_code", "gift_card"].includes(exchangeBalanceRefundMethod)) {
      return t("variantSelector.storeCredit", { amount: priceDifferenceFormatted });
    } else {
      return t("variantSelector.refund", { amount: priceDifferenceFormatted });
    }
  };

  const getOptionNameByPosition = (position: number): string => {
    return options.find((option: Option) => option.position === position).name;
  };

  function handleContinue() {
    if (selectedVariant) {
      dispatch(setCurrentReturnItemProperty("bonusCreditAmountCents", finalBonusCreditCents));
      dispatch(setCurrentReturnItemProperty("exchangingVariant", selectedVariant));
    }
    dispatch(appendCurrentReturnItem());
    dispatch(closeFormModal());
  }

  useEffect(() => {
    const variant = variants.find(
      (variant: Variant) =>
        variant.option1 === selectedOption1 &&
        variant.option2 === selectedOption2 &&
        variant.option3 === selectedOption3
    );
    variant ? setSelectedVariant(variant) : setSelectedVariant(nullVariant);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption1, selectedOption2, selectedOption3]);

  if (isLoading)
    return (
      <Grid style={{ paddingBottom: "32px" }}>
        <Grid.Column>
          <Loading />
        </Grid.Column>
      </Grid>
    );

  const SizeGuide = () => {
    if (!sizeGuideUrl) return null;

    return (
      <Button
        size="mini"
        style={{ background: "#f0f0f2", marginRight: "0" }}
        href={sizeGuideUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("sizeGuide")} <Icon name="external alternate" style={{ marginRight: "0", marginLeft: "4px" }} />
      </Button>
    );
  };

  return (
    <div>
      {showHeaderText && (
        <div style={{ marginBottom: "25px" }}>
          <Header>{t("variantSelector.header")}</Header>
        </div>
      )}
      <Grid>
        {!notShowImage && (
          <Grid.Column mobile={16} tablet={8} computer={7}>
            <ImageCarousel imgUrls={getImgUrls()} />
          </Grid.Column>
        )}

        <Grid.Column
          mobile={16}
          tablet={notShowImage ? 16 : 8}
          computer={notShowImage ? 16 : 9}
          style={{ minHeight: "500px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Header
              style={{ marginBottom: "8px", marginTop: "8px" }}
            >{`${selectedProduct.title} ${selectedVariantTitle}`}</Header>
            <SizeGuide />
          </div>
          <div style={{ paddingBottom: "1rem" }}>
            <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>{selectedVariantDisplayPriceFormatted}</div>
            {priceDifferenceCents !== 0 && (
              <div style={{ color: "gray" }}>
                {priceDifferenceCents > 0 ? topUpRequiredMessage : refundText(priceDifferenceFormatted)}
              </div>
            )}
          </div>

          <div style={{ paddingBottom: "6em" }}>
            <VariantSelectorContext.Provider
              value={{
                selectedOption1,
                selectedOption2,
                selectedOption3,
                haveOption1,
                haveOption2,
                haveOption3,
                variants
              }}
            >
              {haveOption1 && (
                <VariantOption
                  optionIndex={"option1"}
                  optionSelections={option1Selections}
                  optionName={getOptionNameByPosition(1)}
                  selectedOption={selectedOption1}
                  handleOnClick={(optionValue: string | null) => {
                    setSelectedOption1(optionValue);

                    const isCurrentVariantExchangeable = variants.some(
                      (variant: Variant) =>
                        variant.option1 === optionValue &&
                        variant.option2 === selectedOption2 &&
                        variant.option3 === selectedOption3 &&
                        variant.exchangeableByInventory &&
                        variant.exchangeableByPrice
                    );

                    if (!isCurrentVariantExchangeable) {
                      const firstAvailableVariant = variants.find(
                        (variant: Variant) =>
                          variant.option1 === optionValue &&
                          variant.exchangeableByInventory &&
                          variant.exchangeableByPrice
                      );

                      setSelectedOption2(firstAvailableVariant?.option2 ?? null);
                      setSelectedOption3(firstAvailableVariant?.option3 ?? null);
                    }
                  }}
                />
              )}
              {haveOption2 && (
                <VariantOption
                  optionIndex={"option2"}
                  optionSelections={option2Selections}
                  optionName={getOptionNameByPosition(2)}
                  selectedOption={selectedOption2}
                  handleOnClick={(optionValue: string | null) => {
                    setSelectedOption2(optionValue);

                    const isCurrentVariantExchangeable = variants.some(
                      (variant: Variant) =>
                        variant.option1 === selectedOption1 &&
                        variant.option2 === optionValue &&
                        variant.option3 === selectedOption3 &&
                        variant.exchangeableByInventory &&
                        variant.exchangeableByPrice
                    );

                    if (!isCurrentVariantExchangeable) {
                      const firstAvailableVariant = variants.find(
                        (variant: Variant) =>
                          variant.option1 === selectedOption1 &&
                          variant.option2 === optionValue &&
                          variant.exchangeableByInventory &&
                          variant.exchangeableByPrice
                      );

                      setSelectedOption3(firstAvailableVariant?.option3 ?? null);
                    }
                  }}
                />
              )}
              {haveOption3 && (
                <VariantOption
                  optionIndex={"option3"}
                  optionSelections={option3Selections}
                  optionName={getOptionNameByPosition(3)}
                  selectedOption={selectedOption3}
                  handleOnClick={(optionValue: string | null) => {
                    setSelectedOption3(optionValue);
                  }}
                />
              )}
            </VariantSelectorContext.Provider>
            {exchangeRequestDisclaimer && (
              <span style={{ fontSize: "9px", fontStyle: "italic" }}>{parse(exchangeRequestDisclaimer)}</span>
            )}
          </div>
          <div className={styles.submitRowContainer}>
            <div className={styles.submitRow}>
              <Button color="primaryColor" disabled={selectedVariant === nullVariant} fluid onClick={handleContinue}>
                {t("continue")}
              </Button>
            </div>
            <AdditionalMessage />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};
