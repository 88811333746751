import React from "react";
import { Accordion, Divider } from "semantic-ui-react";
import { RefundSummaryLineItems } from "./";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { getFormattedPriceString } from "helpers";
import styles from "./RefundSummary.module.css";
import { MainInvoice } from "constants/invoice";
import { storeCreditNames } from "constants/returnMethod";

interface RefundSummaryProps {
  afterResolvedInvoice: MainInvoice;
  pastTense?: boolean;
}

export const RefundSummary: React.FC<RefundSummaryProps> = ({
  afterResolvedInvoice,
  pastTense = false
}): JSX.Element => {
  const { total, refundBreakdown, lineItems } = afterResolvedInvoice;
  const { t } = useTranslation("orderFlow");
  const hasMultipleRefundMethods = refundBreakdown.length > 1;

  const allRefundMethodsAreStoreCredit = refundBreakdown.every(method =>
    storeCreditNames.includes(method.refundMethod)
  );

  const storeCreditHeader = t("summary.totalStoreCredit");
  const refundHeader = pastTense ? t("summary.totalRefunded") : t("summary.totalToRefund");
  const header = allRefundMethodsAreStoreCredit ? storeCreditHeader : refundHeader;
  const refundAmount = getFormattedPriceString(Math.abs(total.cents), total.currencyIso);

  const hasTopUp = lineItems.some(lineItem => {
    if (!lineItem.exchangeItem) return false;

    return (
      lineItem.exchangeItem.presentmentPrice.cents +
        // presentmentPrice and bonusCreditAmount are in negative value
        (lineItem.returnItem.presentmentPrice.cents + lineItem.returnItem.bonusCreditAmount.cents) >
      0
    );
  });

  const accordionTitle = (
    <div className={styles.accordionTitle}>
      <span>{header}</span>
      <span style={{ float: "right" }}>{refundAmount}</span>
    </div>
  );

  const panels = [
    {
      key: "refundMethodsSummary",
      title: { content: accordionTitle },
      content: {
        content: (
          <RefundSummaryLineItems
            refundBreakdown={refundBreakdown}
            pastTense={pastTense}
            allRefundMethodsAreStoreCredit={allRefundMethodsAreStoreCredit}
            hasTopUp={hasTopUp}
          />
        )
      }
    }
  ];

  if (hasMultipleRefundMethods) {
    return (
      <React.Fragment>
        <Divider />
        <Accordion panels={panels} defaultActiveIndex={0} />
      </React.Fragment>
    );
  }

  function getRefundMethodHelpText(returnMethod: string): string {
    switch (returnMethod) {
      case "original_payment_method":
        return t("summary.refundToOPM");
      case "gift_card":
        return t("summary.refundViaGiftCard");
      case "discount_code":
        return t("summary.refundViaDiscountCode");
      case "online_bank_transfer":
        return t("summary.refundViaBank");
      case "ewallet_transfer":
        return t("summary.refundViaEwallet");
      case "apparel21_voucher":
        return t("summary.refundViaApparel21");
      case "rise_ai_store_credit":
        return t("summary.refundViaRiseAiStoreCredit");
      case "shopify_store_credit":
        return t("summary.refundViaShopifyStoreCredit");
      default:
        return "";
    }
  }

  function getStoreCreditText(returnMethod: string): string {
    switch (returnMethod) {
      case "gift_card":
        return t("summary.giftCard");
      case "discount_code":
        return t("summary.discountCode");
      case "rise_ai_store_credit":
        return t("summary.riseAiStoreCredit");
      case "apparel21_voucher":
        return t("summary.apparel21Voucher");
      case "shopify_store_credit":
        return t("summary.shopifyStoreCredit");
      default:
        return "";
    }
  }

  function getRefundMethodWithTopUpHelpText(returnMethod: string): string {
    switch (returnMethod) {
      case "original_payment_method":
      case "online_bank_transfer":
      case "ewallet_transfer":
        return t("summary.additionalPaymentOffsetRefund");
      case "gift_card":
      case "discount_code":
      case "rise_ai_store_credit":
      case "apparel21_voucher":
      case "shopify_store_credit":
        return t("summary.additionalPaymentOffsetStoreCredit", { returnMethodNames: getStoreCreditText(returnMethod) });
      default:
        return "";
    }
  }

  function getHelpText(returnMethod: string): string {
    if (hasTopUp) {
      return getRefundMethodWithTopUpHelpText(returnMethod);
    } else {
      return getRefundMethodHelpText(returnMethod);
    }
  }

  return (
    <>
      <Divider style={{ marginTop: "-18px" }} />
      <div style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: "8px", paddingInline: "16px" }}>
        <div>
          <b style={{ display: "block", marginBottom: "4px" }}>{header}</b>
          {!pastTense && <i style={{ fontSize: "12px" }}>{getHelpText(refundBreakdown[0].refundMethod)}</i>}
        </div>
        <Grid.Column computer={4} textAlign="right" className={styles.header} style={{ paddingLeft: 0 }}>
          {refundAmount}
        </Grid.Column>
      </div>
    </>
  );
};
