import { RefundSummary } from "components/RefundSummary";
import { MainInvoice } from "constants/invoice";
import { getFormattedPriceString } from "helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "semantic-ui-react";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface SummaryProps {
  afterResolvedInvoice: MainInvoice;
  pastTense?: boolean;
}

export const Summary: React.FC<SummaryProps> = ({ afterResolvedInvoice, pastTense = false }): JSX.Element => {
  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const { total, lineItems, returningLineItems } = afterResolvedInvoice;
  const { t } = useTranslation("orderFlow");
  const { completedReturnOrder } = useReturnOrderStatus();

  const hasTopUp = lineItems.some(lineItem => {
    if (!lineItem.exchangeItem) return false;

    return (
      lineItem.exchangeItem.presentmentPrice.cents +
        // presentmentPrice and bonusCreditAmount are in negative value
        (lineItem.returnItem.presentmentPrice.cents + lineItem.returnItem.bonusCreditAmount.cents) >
      0
    );
  });

  const description = pastTense || completedReturnOrder ? t("summary.totalPaid") : t("summary.totalToPay");
  const storeCreditRefundMethods = ["gift_card", "discount_code", "apparel21_voucher", "rise_ai_store_credit"];
  const refundMethodNames = [
    ...storeCreditRefundMethods,
    "original_payment_method",
    "online_bank_transfer",
    "ewallet_transfer"
  ];
  const lineItemsRefundMethods = [
    ...new Set(
      returningLineItems
        .filter(lineItem => refundMethodNames.includes(lineItem.returnMethodName))
        .map(lineItem => lineItem.returnMethodName)
    )
  ];
  const hasRefunds = lineItemsRefundMethods.length > 0;

  function getStoreCreditText(refundMethod: string): string {
    switch (refundMethod) {
      case "gift_card":
        return t("summary.giftCard");
      case "discount_code":
        return t("summary.discountCode");
      case "apparel21_voucher":
        return t("summary.apparel21Voucher");
      case "rise_ai_store_credit":
        return t("summary.riseAiStoreCredit");
      case "shopify_store_credit":
        return t("summary.shopifyStoreCredit");
      default:
        return "";
    }
  }

  function joinStoreCreditTexts() {
    const storeCreditTexts = lineItemsRefundMethods.map(refundMethod => getStoreCreditText(refundMethod));
    if (storeCreditTexts.length === 1) return storeCreditTexts[0];
    if (storeCreditTexts.length === 2) return storeCreditTexts.join(" and ");

    const lastTwo = storeCreditTexts.slice(-2);
    const rest = storeCreditTexts.slice(0, -2);
    return [...rest, lastTwo.join(", ")].join(" and ");
  }

  function getTopUpOffsetText() {
    const hasStoreCreditRefundsOnly = lineItemsRefundMethods.every(method => storeCreditRefundMethods.includes(method));

    return hasStoreCreditRefundsOnly
      ? t("summary.topUpOffsetStoreCredit", { storeCredits: joinStoreCreditTexts() })
      : t("summary.topUpOffsetRefund");
  }

  if (total.cents >= 0) {
    return (
      <>
        <Divider style={{ marginTop: "-18px" }} />
        <div style={{ paddingInline: "16px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              fontWeight: "bold",
              fontSize: "14px",
              paddingBottom: "16px"
            }}
          >
            <div>
              <p style={{ marginBottom: 0 }}>{description}</p>
              {isP2PReturns && (
                <p style={{ fontWeight: "normal", fontSize: "12px", color: "#515151", marginTop: "4px" }}>
                  You’ll only be required to pay this amount when a buyer purchases your item, or when the item is past
                  its holding period.
                </p>
              )}
            </div>
            <div>{getFormattedPriceString(total.cents, total.currencyIso, { useParenthesis: true })}</div>
            {hasTopUp && hasRefunds && !pastTense && (
              <i style={{ fontSize: "12px", fontWeight: "normal", marginTop: "8px", color: "#515151" }}>
                {getTopUpOffsetText()}
              </i>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <RefundSummary afterResolvedInvoice={afterResolvedInvoice} pastTense={pastTense} />;
  }
};
