import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { CardWithShadow } from "components";
import { getFormattedPriceString, shippingMethodInfo } from "helpers";
import { Shipment, Summary, SummaryShippingMethod } from "types";
import { ReturnOrderStatuses } from "constants/returnOrder";
import { MakePaymentButtonRow } from "./MakePaymentButtonRow";
import styles from "./ShippingMethodSummary.module.css";
import { ShippingDetailsButtonsRow } from ".";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface ShippingMethodSummaryProps {
  shippingMethod: SummaryShippingMethod;
  shipment: Shipment;
  custEmail: string;
  returnOrderStatus: Summary["returnOrderStatus"];
}

export const ShippingMethodSummary: React.FC<ShippingMethodSummaryProps> = ({
  shippingMethod,
  shipment,
  custEmail,
  returnOrderStatus
}) => {
  const { t } = useTranslation("orderFlow");

  const { name, type, shippingServiceType, customerNote } = shippingMethod;
  const { qrCodeUrl, shipmentLabelLink, courierName } = shipment;

  const displayShippingMethodInfo = (): boolean => {
    return (
      ReturnOrderStatuses[returnOrderStatus as keyof typeof ReturnOrderStatuses] <= ReturnOrderStatuses["reviewed"]
    );
  };

  const shippingMethodDescription = (): string => {
    if (qrCodeUrl) {
      return `<p>${t("shippingMethodSummary.printerlessReturn", { shipmentLabelLink })}</p>`;
    }

    return customerNote ? customerNote : shippingMethodInfo(type, shippingServiceType, t);
  };

  const header = isMobile ? name : `${t("shippingMethodSummary.header")} - ${name}`;
  const shipmentFee = shipment.fee;
  const feeCents = shipmentFee?.cents;
  const labelFee =
    shipmentFee && feeCents && shipment.customerBorneShipping
      ? getFormattedPriceString(feeCents, shipmentFee?.currencyIso)
      : "Free";
  const paymentStage = shipment.paymentStage;
  const labelFeePaymentDescription =
    feeCents && paymentStage && ["on_resolve", "on_deprecated_resolve"].includes(paymentStage)
      ? " " + t("shippingMethodSummary.deductLabelFeeOnResolve")
      : "";
  const shipmentRequiresPayment = shipmentFee && feeCents && shipment.customerBorneShipping;
  const deltaFeeDisclaimer =
    shipmentRequiresPayment && courierName === "USPS" ? " " + t("shippingMethodSummary.deltaFeeDisclaimer") : "";

  const courierAndLabelInfo = () => (
    <Grid.Row style={{ paddingTop: "1rem" }}>
      <Grid columns={2}>
        <Grid.Column className={styles.gridColumn}>
          <Card.Header>
            <span className={styles.subHeader}>{t("shippingMethodSummary.courier")}</span>
          </Card.Header>
          <Card.Description>{courierName}</Card.Description>
        </Grid.Column>
        <Grid.Column className={styles.gridColumn}>
          <Card.Header>
            <span className={styles.subHeader}>{t("shippingMethodSummary.fee")}</span>
          </Card.Header>
          <Card.Description>{labelFee}</Card.Description>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  );

  return (
    <CardWithShadow>
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <MakePaymentButtonRow shipment={shipment} custEmail={custEmail} returnOrderStatus={returnOrderStatus} />
        <ShippingDetailsButtonsRow shipment={shipment} />
        {displayShippingMethodInfo() && (
          <Grid.Column style={{ paddingTop: "1rem" }}>
            <Card.Description>
              {parse(shippingMethodDescription())}
              {labelFeePaymentDescription}
              {deltaFeeDisclaimer}
            </Card.Description>
          </Grid.Column>
        )}
        {courierName && courierAndLabelInfo()}
      </Card.Content>
    </CardWithShadow>
  );
};
