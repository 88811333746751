import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import customerInfoStyles from "pages/CustomerInfo/CustomerInfo.module.css";
import styles from "./Success.module.css";
import { StoreState } from "reducers";
import { ReturnSteps, changeReturnStep } from "actions";
import { BackgroundImage } from "components";

export const Success: React.FC<any> = () => {
  const { t } = useTranslation(["translation", "orderFlow"]);
  const dispatch = useDispatch();
  const hasPreprintedReturnLabel = useSelector((state: StoreState) => state.currentShop.hasPreprintedReturnLabel);
  const shopDomain = useSelector((state: StoreState) => state.currentShop.shopDomain);
  const customerEmail =
    useSelector((state: StoreState) => state.order.shippingAddress.email) ||
    new URLSearchParams(window.location.search).get("customerEmail");

  const successPageContent = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.successPageContent
  );
  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const redirectFromAfterReview = new URLSearchParams(window.location.search).get("redirectFromAfterReview");
  const redirectFromExchangePaymentPage = new URLSearchParams(window.location.search).get(
    "redirectFromExchangePaymentPage"
  );
  const redirectFromShipmentAdjustmentPaymentPage = new URLSearchParams(window.location.search).get(
    "redirectFromShipmentAdjustmentPaymentPage"
  );
  const paymentSuccess = new URLSearchParams(window.location.search).get("paymentSuccess");
  const noPrinter = new URLSearchParams(window.location.search).get("noPrinter");
  const usesPickup = new URLSearchParams(window.location.search).get("usesPickup");
  const usesDropOff = new URLSearchParams(window.location.search).get("usesDropOff");

  const getSuccessMessage = () => {
    if (redirectFromAfterReview === "true") {
      if (usesPickup === "true") {
        return noPrinter === "true"
          ? t(`orderFlow:success.noPrinterPickupMessage.${hasPreprintedReturnLabel ? "preprinted" : "original"}`, {
              customerEmail
            })
          : t("orderFlow:success.pickupMessage", { customerEmail });
      } else if (usesDropOff === "true") {
        return t("orderFlow:success.dropoffMessage", { customerEmail });
      } else {
        return "";
      }
    } else if (redirectFromExchangePaymentPage === "true") {
      return instantExchange
        ? t("orderFlow:success.instantExchangePayment")
        : t("orderFlow:success.exchangeOrderPayment");
    } else if (redirectFromShipmentAdjustmentPaymentPage === "true") {
      return "";
    } else {
      // TODO: Remove this once we deploy the submitted page.
      return customerEmail ? t("orderFlow:success.emailMessage", { customerEmail }) : "";
    }
  };

  const getSuccessHeader = () => {
    if (redirectFromAfterReview === "true") {
      if (paymentSuccess === "true") {
        return t("orderFlow:success.afterReviewHeader.paymentSuccess");
      } else if (usesPickup === "true") {
        return t("orderFlow:success.afterReviewHeader.pickup");
      } else if (usesDropOff === "true") {
        return t("orderFlow:success.afterReviewHeader.dropoff");
      }
    } else if (redirectFromExchangePaymentPage === "true") {
      return t("orderFlow:success.exchangePaymentPageHeader");
    } else if (redirectFromShipmentAdjustmentPaymentPage === "true") {
      return t("orderFlow:success.shipmentAdjustmentPaymentPageHeader");
    } else {
      // TODO: Remove this once we deploy the submitted page.
      return t("orderFlow:success.header");
    }
  };

  useEffect(() => {
    window.top?.postMessage({ type: "resize", resetHeight: true }, "*");
  }, []);

  useEffect(() => {
    dispatch(changeReturnStep(ReturnSteps.success));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const standardSuccessTemplate = (
    <>
      <div>
        <div className={styles.headerContainer}>
          <Header>{getSuccessHeader()}</Header>
        </div>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          <p>{parse(getSuccessMessage())}</p>
        </div>
      </div>
      <div>
        <a href={`https://${shopDomain}`} rel="noopener noreferrer" target="_blank">
          {t("orderFlow:success.continue")}
        </a>
      </div>
    </>
  );

  const parsedCustomSuccessTemplate = () => {
    const content = successPageContent.replace(/{{[\s]*customer_email[\s]*}}/g, customerEmail || "REDACTED");
    return <div>{parse(content)}</div>;
  };

  return (
    <BackgroundImage>
      <div className={customerInfoStyles.container}>
        <div className={customerInfoStyles.content}>
          <Container className={styles.contentContainer} text>
            <div className={styles.textContainer}>
              {successPageContent ? parsedCustomSuccessTemplate() : standardSuccessTemplate}
            </div>
          </Container>
        </div>
      </div>
    </BackgroundImage>
  );
};
