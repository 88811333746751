import { Action, ActionTypes, FrontendConfigs } from "../actions";

const initialState: FrontendConfigs = {
  backgroundImageUrls: {
    webp: {
      small: null,
      medium: null,
      large: null
    },
    jpg: {
      small: null,
      medium: null,
      large: null
    }
  },
  mobileBackgroundImageUrls: {
    webp: {
      small: null,
      medium: null,
      large: null
    },
    jpg: {
      small: null,
      medium: null,
      large: null
    }
  },
  primaryColor: null,
  logoImageUrls: {
    webp: { navbar: null },
    png: { navbar: null }
  },
  title: "Loading...",
  faviconUrl: new URL("https://postco360-attachments.sgp1.cdn.digitaloceanspaces.com/static/favicons/favicon.ico"),
  loading: true
};

export const frontendConfigsReducer = (state: FrontendConfigs = initialState, action: Action): FrontendConfigs => {
  switch (action.type) {
    case ActionTypes.setBackgroundImageUrl:
      return {
        ...state,
        backgroundImageUrls: action.payload
      };
    case ActionTypes.setMobileBackgroundImageUrl:
      return { ...state, mobileBackgroundImageUrls: action.payload };
    case ActionTypes.clearConfigLoading:
      return {
        ...state,
        loading: false
      };
    case ActionTypes.setPrimaryColor:
      return {
        ...state,
        primaryColor: action.payload
      };
    case ActionTypes.setLogoImageUrls:
      return {
        ...state,
        logoImageUrls: action.payload
      };
    case ActionTypes.setTitle:
      return {
        ...state,
        title: action.payload
      };
    case ActionTypes.setFaviconUrl:
      return {
        ...state,
        faviconUrl: action.payload
      };
    default:
      return state;
  }
};
