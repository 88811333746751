import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import customerInfoStyles from "pages/CustomerInfo/CustomerInfo.module.css";
import styles from "./Submitted.module.css";
import { StoreState } from "reducers";
import { ReturnSteps, changeReturnStep } from "actions";
import { BackgroundImage } from "components";

export const Submitted: React.FC<any> = () => {
  const { t } = useTranslation(["translation", "orderFlow"]);
  const dispatch = useDispatch();
  const shopDomain = useSelector((state: StoreState) => state.currentShop.shopDomain);
  const isP2PReturn = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const customerEmail =
    useSelector((state: StoreState) => state.order.shippingAddress.email) ||
    new URLSearchParams(window.location.search).get("customerEmail");

  const successPageContent = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.successPageContent
  );

  const getSuccessMessage = () => {
    if (isP2PReturn) {
      return t("orderFlow:success.p2pReturnMessage");
    } else {
      return customerEmail ? t("orderFlow:success.emailMessage", { customerEmail }) : "";
    }
  };

  useEffect(() => {
    window.top?.postMessage({ type: "resize", resetHeight: true }, "*");
  }, []);

  useEffect(() => {
    dispatch(changeReturnStep(ReturnSteps.submitted));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const standardSuccessTemplate = (
    <>
      <div>
        <div className={styles.headerContainer}>
          <Header>{t("orderFlow:success.header")}</Header>
        </div>
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          <p>{parse(getSuccessMessage())}</p>
        </div>
      </div>
      {isP2PReturn && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px 0px" }}>
          <ul className={styles.renewTimelineContainer}>
            <li className={styles.activeTimeline}>Pending approval</li>
            <li>Ship out items</li>
            <li>Return processed</li>
          </ul>
        </div>
      )}
      <div>
        <a href={`https://${shopDomain}`} rel="noopener noreferrer" target="_blank">
          {t("orderFlow:success.continue")}
        </a>
      </div>
    </>
  );

  const parsedCustomSuccessTemplate = () => {
    const content = successPageContent.replace(/{{[\s]*customer_email[\s]*}}/g, customerEmail || "REDACTED");
    return <div>{parse(content)}</div>;
  };

  return (
    <BackgroundImage>
      <div className={customerInfoStyles.container}>
        <div className={customerInfoStyles.content}>
          <Container className={styles.contentContainer} text>
            <div className={styles.textContainer}>
              {successPageContent ? parsedCustomSuccessTemplate() : standardSuccessTemplate}
            </div>
          </Container>
        </div>
      </div>
    </BackgroundImage>
  );
};
