import { isMobile } from "react-device-detect";
import DEFAULT_IMAGE from "./background.jpg";
import { BackgroundImageUrls } from "actions";

function getBackgroundImageDerivative(backgroundImageUrls: BackgroundImageUrls, mimeType: string, size: string) {
  const typeDerivativeObject = backgroundImageUrls?.[mimeType as keyof typeof backgroundImageUrls];
  const sizeDerivative = typeDerivativeObject?.[size as keyof typeof typeDerivativeObject];
  return sizeDerivative?.toString();
}

export function getBackgroundImageUrl(
  backgroundImageUrls: BackgroundImageUrls,
  mobileBackgroundImageUrls: BackgroundImageUrls,
  mimeType: string,
  size: string
) {
  const mobileBackgroundImageDerivative = getBackgroundImageDerivative(mobileBackgroundImageUrls, mimeType, size);
  const backgroundImageDerivative = getBackgroundImageDerivative(backgroundImageUrls, mimeType, size);

  if (isMobile && mobileBackgroundImageDerivative) {
    return mobileBackgroundImageDerivative;
  } else {
    return backgroundImageDerivative || DEFAULT_IMAGE;
  }
}
