import { RefundBreakdown } from "constants/invoice";
import { storeCreditNames } from "constants/returnMethod";

export function getRefundSectionSubHeader(refundBreakdown: RefundBreakdown[], t: Function) {
  const refundMethods = refundBreakdown.map((refund: RefundBreakdown) => refund.refundMethod);
  const hasOpmRefund = refundMethods.includes("original_payment_method");
  const storeCreditMethods = refundMethods.filter(
    method => storeCreditNames.includes(method) && method !== "original_payment_method"
  );
  const otherRefundMethods = refundMethods.filter(
    method => !storeCreditNames.includes(method) && method !== "original_payment_method"
  );

  const storeCreditText =
    storeCreditMethods.length > 0 ? [`store credit (${getFormattedRefundMethods(storeCreditMethods)})`] : []; // e.g: store credit (discount code)
  const nonOpmRefundMethods = [...storeCreditText, ...otherRefundMethods];
  const formattedNonOpmRefundMethods = getFormattedRefundMethods(nonOpmRefundMethods);

  if (hasOpmRefund) {
    if (refundMethods.length === 1) {
      return t("instantExchangeRefundSection.opmSubHeader");
    } else {
      return t("instantExchangeRefundSection.multipleReturnMethodsSubHeader", { formattedNonOpmRefundMethods });
    }
  } else {
    return t("instantExchangeRefundSection.nonOpmSubHeader", { formattedNonOpmRefundMethods });
  }
}

function getFormattedRefundMethods(refundMethods: string[]) {
  if (refundMethods.length === 1) {
    return getNormalCaseRefundMethods(refundMethods)[0];
  } else {
    const normalCaseRefundMethods = getNormalCaseRefundMethods(refundMethods);

    return (
      normalCaseRefundMethods.slice(0, -1).join(", ") +
      " and " +
      normalCaseRefundMethods[refundMethods.length - 1]
    ).toLowerCase();
  }
}

function getNormalCaseRefundMethods(refundMethods: string[]) {
  return refundMethods.map(method => method.split("_").join(" "));
}

export const getInstantExchangeChargeReason = (chargeAmountCents: number, paymentOption: string, t: Function) => {
  if (chargeAmountCents > 0) {
    if (paymentOption === "charge_and_refund") {
      return t("summary.instantExchange.returnItemsRejected");
    } else {
      return t("summary.instantExchange.chargeFailed");
    }
  }
};
