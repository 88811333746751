import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Loading, PageHeader } from "components";
import { useReturnMethods } from "hooks/useReturnMethods";
import { FormModalContent } from "..";
import { useItemBonusCredit } from "../hooks";
import { ExchangeButton } from "./components/ExchangeButton";
import { StoreCreditButton } from "./components/StoreCreditButton";
import { RefundButton } from "./components/RefundButton";
import { getModalHeaderText, getPrioritizedButton, handleContinue } from "./helpers";

export const ReturnMethodTypeSelector: React.FC = () => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();

  const { returnMethods, refundMethods, exchangeMethods, storeCreditMethods, isLoading } = useReturnMethods();

  const exchangeAvailable = exchangeMethods.length > 0;
  const refundAvailable = refundMethods.length > 0;
  const storeCreditAvailable = storeCreditMethods.length > 0;

  useEffect(() => {
    // skip screen if only one type of return method available
    const handleOnlyOneReturnMethod = (type: string) => {
      handleContinue(type, dispatch, false);
    };

    if (refundAvailable && !exchangeAvailable && !storeCreditAvailable) {
      handleOnlyOneReturnMethod("refund");
    } else if (exchangeAvailable && !refundAvailable && !storeCreditAvailable) {
      handleOnlyOneReturnMethod("exchange");
    } else if (storeCreditAvailable && !exchangeAvailable && !refundAvailable) {
      handleOnlyOneReturnMethod("storeCredit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refundMethods, exchangeMethods, storeCreditMethods]);

  const modalHeaderText = getModalHeaderText(exchangeAvailable, storeCreditAvailable, refundAvailable, t);

  const { exchangeBonusCredit, storeCreditBonusCredit } = useItemBonusCredit();

  const prioritizedButton = getPrioritizedButton(
    exchangeAvailable,
    storeCreditAvailable,
    exchangeBonusCredit,
    storeCreditBonusCredit
  );

  const returnMethodButtons = [];

  const exchangeButtonElement = exchangeAvailable ? (
    <ExchangeButton bonusCredit={exchangeBonusCredit} prioritized={prioritizedButton === "exchange"} />
  ) : null;

  const storeCreditButtonElement = storeCreditAvailable ? (
    <StoreCreditButton bonusCredit={storeCreditBonusCredit} prioritized={prioritizedButton === "storeCredit"} />
  ) : null;

  const refundButtonElement = refundAvailable ? <RefundButton /> : null;

  if (prioritizedButton === "storeCredit") {
    returnMethodButtons.push(storeCreditButtonElement, exchangeButtonElement, refundButtonElement);
  } else {
    returnMethodButtons.push(exchangeButtonElement, storeCreditButtonElement, refundButtonElement);
  }

  return (
    <FormModalContent>
      {isLoading || returnMethods.length === 0 ? (
        <div>
          <Grid style={{ paddingBottom: "32px" }}>
            <Grid.Column>
              <Loading />
            </Grid.Column>
          </Grid>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "fit-content" }}>
            <PageHeader header={modalHeaderText} isReturnMethodTypeSelectorHeader />
            {returnMethodButtons}
          </div>
        </div>
      )}
    </FormModalContent>
  );
};
