import React from "react";
import { LineItem } from "constants/invoice";
import { useTranslation } from "react-i18next";
import { ExchangingLineItem } from "./ExchangingLineItem";
import styles from "./ExchangingLineItems.module.css";
import { Accordion } from "components";
import { getFormattedPriceString } from "helpers";

interface LineItemsProps {
  lineItems: LineItem[];
}

export const ExchangingLineItems: React.FC<LineItemsProps> = ({ lineItems }): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  const title = (
    <div className={styles.accordionTitle}>
      <span>{t("summary.exchangingItems")} </span>
      <span>({lineItems.length})</span>
        <span style={{ float: "right" }}>
          {getFormattedPriceString(
            lineItems.reduce((totalCents, lineItem) => totalCents + lineItem.exchangeItem.presentmentPrice.cents, 0),
            lineItems[0].exchangeItem.presentmentPrice.currencyIso
          )}
        </span>
    </div>
  );

  const content = (
    <div style={{ paddingBottom: "1.5rem" }}>
      {lineItems.map((lineItem: LineItem, index) => (
        <ExchangingLineItem lineItem={lineItem} key={index} />
      ))}
    </div>
  );

  return (
    <div style={{ marginBottom: "32px" }}>
      <Accordion
        panels={[
          {
            title,
            content,
          },
        ]}
      />
    </div>
  );
};
