import { PostCo360API } from "api/constants";
import camelcaseKeys from "camelcase-keys";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export interface ProductListing {
  id: number;
  productListable: ProductListable;
  imageUploadRequirement: ImageUploadRequirement;
}

export interface ProductListable {
  id: number;
  returnItemImageUrl: string;
  productTitle: string;
  variantTitle: string;
  presentmentPrice: string;
}

export interface ImageUploadRequirement {
  id: number;
  instructions: ImageUploadRequirementInstruction[];
}

export interface ImageUploadRequirementInstruction {
  id: number;
  title: string;
  description: string;
  sampleImages: any[];
  image?: UploadedImage;
}

export interface UploadedImage {
  id: number;
  url: string;
}

type ProductListingResponse = {
  id: number;
  data: {
    attributes: Omit<ProductListing, "id">;
  };
};

export const useP2PProductListings = (options = {}) => {
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { data, ...other } = useQuery<ProductListing[]>({
    ...options,
    queryKey: ["p2p_product_listings", returnOrderId],
    queryFn: async () => {
      const res = await PostCo360API().get<ProductListingResponse[]>(
        `/api/v2/customer/return_orders/${returnOrderId}/p2p/product_listings/`
      );

      const result = res.data.map(item => {
        return camelcaseKeys({ id: item.id, ...item.data.attributes }, { deep: true });
      });

      return result;
    }
  });

  return {
    productListings: data ?? [],
    ...other
  };
};
