import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ReturnItem } from "actions";
import { Loading } from "components";
import { useShipment } from "../hooks";
import { getFormattedPriceString, shippingMethodInfo } from "helpers";
import styles from "../ShippingMethodSummary.module.css";
import { useShipmentInvoice } from "hooks/useInvoices";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const NormalShippingMethodSummary: React.FC<any> = () => {
  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const returnItems = useSelector((state: StoreState) => state.returnItems);

  const { t } = useTranslation("orderFlow");

  const { returnItemsReceived } = useReturnOrderStatus();
  const { shipmentInvoice } = useShipmentInvoice();
  const {
    shipment: {
      courierName,
      qrCodeUrl,
      shipmentLabelLink,
      fee,
      customerBorneShipping,
      shippingMethod: { type, shippingServiceType, fixedPrice, customerNote }
    },
    isLoading
  } = useShipment();

  const returnOrderHasRefunds =
    returnItems.cart.filter((item: ReturnItem) => item.returnMethod.type === "Refund").length > 0;
  const shipmentFeeCents = fee?.cents;

  let shippingDescription;

  if (customerNote === "" || customerNote) {
    shippingDescription = customerNote;
  } else {
    const shippingMethodDescription = qrCodeUrl
      ? t("shippingMethodSummary.printerlessReturn", { shipmentLabelLink })
      : shippingMethodInfo(type, shippingServiceType, t);
    const labelFeePaymentDescription =
      shipmentFeeCents && shipmentInvoice?.payAt === "after_resolve" && !instantExchange && returnOrderHasRefunds
        ? t("shippingMethodSummary.deductLabelFeeOnResolve")
        : "";
    const shipmentRequiresPayment = shipmentFeeCents && customerBorneShipping;
    const deltaFeeDisclaimer =
      shipmentRequiresPayment && courierName === "USPS" && !fixedPrice
        ? " " + t("shippingMethodSummary.deltaFeeDisclaimer")
        : "";

    shippingDescription = `${shippingMethodDescription} ${labelFeePaymentDescription} ${deltaFeeDisclaimer}`;
  }

  const labelFee =
    fee && fee?.cents && customerBorneShipping
      ? getFormattedPriceString(shipmentFeeCents, fee?.currencyIso)
      : t("shippingMethodSummary.free");

  if (isLoading) return <Loading />;

  return (
    <div>
      {!returnItemsReceived && (
        <Card.Description className={styles.shippingMethodInfo}>
          <Grid.Row style={{ margin: 0 }}>{parse(shippingDescription)}</Grid.Row>
        </Card.Description>
      )}
      {courierName && (
        <Grid columns={2}>
          <Grid.Column className={styles.gridColumn}>
            <Card.Header>
              <span className={styles.subHeader}>{t("shippingMethodSummary.courier")}</span>
            </Card.Header>
            <Card.Description>{courierName}</Card.Description>
          </Grid.Column>

          <Grid.Column className={styles.gridColumn}>
            <Card.Header>
              <span className={styles.subHeader}>{t("shippingMethodSummary.fee")}</span>
            </Card.Header>
            <Card.Description>{labelFee}</Card.Description>
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
};
