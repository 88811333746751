import { ShipmentInvoice } from "constants/invoice";
import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";

export const getInvoices = async (
  returnOrderId: string,
  returnType: "regularReturn" | "instantExchange"
): Promise<any> => {
  try {
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/invoices?return_type=${returnType}`
    );
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

export const getShipmentInvoice = async (returnOrderId: string): Promise<ShipmentInvoice> => {
  try {
    // TODO: create a new endpoint to fetch shipment and replace the url
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/invoices?invoice_type=shipment`
    );
    return camelcaseKeys(response.data[0], { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};
