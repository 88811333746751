import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// NOTE: Temporary - we should use set up and use i18next-locize-backend later.
import Backend from "i18next-xhr-backend";
// import LocizeBackend from "i18next-locize-backend";

i18n
  // .use(LocizeBackEnd)
  // NOTE: i18next-xhr-backend should be temporary until we set up Locize
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en",
    whitelist: ["en", "bg", "da", "de", "es", "fr", "id", "it", "ms", "nl", "ro", "vi", "zh-Hant"],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    },
    returnEmptyString: false
  });

export default i18n;
