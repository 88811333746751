import React from "react";
import { ExchangeOption } from "./ExchangeOption";
import { useInstantExchangePreflightCheck } from "hooks/useInstantExchange";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { StaticInstantExchangeOption } from "./StaticInstantExchangeOption";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useTranslation } from "react-i18next";
import { Loading } from "components";
import { useAuthContext } from "contexts/AuthProvider";

export const ExchangeOptionsSection = () => {
  const { t } = useTranslation("orderFlow");
  const { editableReturnOrder } = useReturnOrderStatus();
  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const { instantExchangeEnabled: shopOffersInstantExchange, allowInstantExchangeOnly } = currentShop;
  const fetchInstantExchangePreflightCheck = editableReturnOrder && shopOffersInstantExchange;
  const { offerInstantExchange, isLoading: loadingInstantExchangePreflightCheck } = useInstantExchangePreflightCheck({
    enabled: fetchInstantExchangePreflightCheck
  });
  const { isFetching: fetchingAuthContext } = useAuthContext();

  if (!editableReturnOrder && instantExchange) return <StaticInstantExchangeOption />;

  if (!shopOffersInstantExchange || !offerInstantExchange || !editableReturnOrder) return <></>;

  if (loadingInstantExchangePreflightCheck || fetchingAuthContext) return <Loading />;

  return (
    <div style={{ padding: "2rem 0 1rem 0" }}>
      <div style={{ fontWeight: "bold" }}>{t("exchangeOptions.exchangeOptions")}</div>
      <ExchangeOption type="instantExchange" />
      {!allowInstantExchangeOnly && <ExchangeOption type="regularExchange" />}
    </div>
  );
};
