import React from "react";

export const RewardIcon = () => (
  <div
    style={{
      backgroundColor: "#519872",
      borderRadius: "50%",
      padding: 10,
      width: 50,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0795 8.93283C11.3635 8.70721 11.7009 8.54314 12.0625 8.44061V11.9343C11.694 11.8297 11.3575 11.663 11.0795 11.4422C10.5873 11.0512 10.3969 10.5912 10.3969 10.1875C10.3969 9.78381 10.5873 9.3238 11.0795 8.93283Z"
        fill="white"
      />
      <path
        d="M13.9375 17.577V14.0479C14.3716 14.1535 14.7672 14.3322 15.0892 14.5737C15.6223 14.9735 15.8125 15.4311 15.8125 15.8125C15.8125 16.1939 15.6223 16.6514 15.0892 17.0512C14.7672 17.2927 14.3716 17.4715 13.9375 17.577Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 0.8125C6.26903 0.8125 0.8125 6.26903 0.8125 13C0.8125 19.731 6.26903 25.1875 13 25.1875C19.731 25.1875 25.1875 19.731 25.1875 13C25.1875 6.26903 19.731 0.8125 13 0.8125ZM13.9375 5.5C13.9375 4.98223 13.5178 4.5625 13 4.5625C12.4822 4.5625 12.0625 4.98223 12.0625 5.5V6.51953C11.2789 6.65836 10.5317 6.97342 9.91323 7.46469C9.02277 8.17207 8.5219 9.15163 8.5219 10.1875C8.5219 11.2234 9.02277 12.2029 9.91323 12.9103C10.5413 13.4092 11.2908 13.7188 12.0625 13.8554V17.5769C11.6285 17.4714 11.233 17.2926 10.9111 17.0512L9.81251 16.2272C9.39831 15.9166 8.81068 16.0005 8.50001 16.4147C8.18934 16.8289 8.27328 17.4165 8.68749 17.7272L9.78608 18.5512C10.4526 19.0511 11.2474 19.357 12.0625 19.4879V20.5C12.0625 21.0178 12.4822 21.4375 13 21.4375C13.5178 21.4375 13.9375 21.0178 13.9375 20.5V19.488C14.7527 19.357 15.5476 19.0511 16.2142 18.5512C17.1455 17.8527 17.6875 16.8706 17.6875 15.8125C17.6875 14.7543 17.1455 13.7723 16.2142 13.0737C15.5476 12.5738 14.7527 12.2679 13.9375 12.137V8.44071C14.299 8.54325 14.6362 8.70729 14.9201 8.93283L15.4388 9.34482C15.8442 9.66688 16.4339 9.5993 16.756 9.19389C17.078 8.78848 17.0105 8.19874 16.605 7.87668L16.0864 7.4647C15.4681 6.97349 14.721 6.65846 13.9375 6.51959V5.5Z"
        fill="white"
      />
    </svg>
  </div>
);
