import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { History } from "history";

export default function setupSentry(history: History) {
  const sentryDSN = process.env.REACT_APP_CUSTOMER_SENTRY_DSN;

  if (process.env.REACT_APP_ENVIRONMENT !== "production") return null;
  const replaysSessionSampleRate = process.env.REACT_APP_ENVIRONMENT === "production" ? 0.05 : 1;

  if (!sentryDSN) {
    throw new Error("Missing env var: REACT_APP_CUSTOMER_SENTRY_DSN");
  } else {
    Sentry.init({
      environment: process.env.REACT_APP_ENVIRONMENT,
      dsn: sentryDSN,
      replaysSessionSampleRate,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.Replay({ maskAllText: false, maskAllInputs: false, blockAllMedia: false }),
        new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) })
      ],
      tracesSampleRate: 0.5
    });
  }
}
