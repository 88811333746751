import { ActionTypes } from "./types";

export interface ClearErrorsAction {
  type: ActionTypes.clearErrors;
}

export interface SetErrorAction {
  type: ActionTypes.setError;
  payload: Error;
}

export interface SetErrorsAction {
  type: ActionTypes.setErrors;
  payload: Error[];
}

export const clearErrors = (): ClearErrorsAction => {
  return {
    type: ActionTypes.clearErrors
  };
};

export const setError = (error: Error): SetErrorAction => {
  return {
    type: ActionTypes.setError,
    payload: error
  };
};

export const setErrors = (errors: Error[]): SetErrorsAction => {
  return {
    type: ActionTypes.setErrors,
    payload: errors
  };
};
