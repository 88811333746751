import { useQuery } from "react-query";
import { getReturnItemsSummary } from "./api";
import { ReturnItemsSummary } from "./components/types";

export const useGetReturnItemsSummary = (returnOrderId: string, options = {}) => {
  const { data, isFetching: isGettingReturnItemsSummary } = useQuery<ReturnItemsSummary>({
    queryKey: ["getReturnItemsSummary", returnOrderId],
    queryFn: () => getReturnItemsSummary(returnOrderId),
    ...options
  });

  const returnItemsSummary = data ?? { normalReturnItems: [], p2pReturnItems: [] };

  return { returnItemsSummary, isGettingReturnItemsSummary };
};
