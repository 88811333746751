import { Action, ActionTypes, Order } from "../actions";

const initialState: Order = {
  currency: "",
  customer: {
    email: "",
    firstName: "",
    lastName: "",
    phone: ""
  },
  email: "",
  id: "",
  lineItems: [],
  name: "",
  phone: "",
  orderCreatedAt: "",
  returnOrderId: "",
  shippingAddress: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    countryCode: "",
    email: "",
    firstName: "",
    lastName: "",
    province: "",
    provinceCode: "",
    zip: "",
    phone: "",
    district: "",
    ward: ""
  },
  status: "draft",
  stripeIntegrationEnabled: false
};

export const orderReducer = (state: Order = initialState, action: Action): Order => {
  switch (action.type) {
    case ActionTypes.addOrder:
      return action.payload;
    case ActionTypes.updateShippingAddress:
      return {
        ...state,
        shippingAddress: action.payload
      };
    case ActionTypes.updateBankAccount:
      return {
        ...state,
        bankAccount: action.payload
      };
    case ActionTypes.updateEwalletAccount:
      return {
        ...state,
        ewalletAccount: action.payload
      };
    case ActionTypes.updateStripePaymentMethod:
      return {
        ...state,
        stripePaymentMethod: action.payload
      };
    case ActionTypes.updateStripePaymentIntentId:
      return {
        ...state,
        stripePaymentIntentId: action.payload
      };
    case ActionTypes.updateLineItemsReturnability:
      const updatedLineItems = state.lineItems.map(item => {
        if (action.payload.lineItemIds.includes(item.id.toString())) {
          return {
            ...item,
            returnable: false,
            unreturnableReason: action.payload.t("returnItemsSelectionPage.itemNotReturnable")
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        lineItems: updatedLineItems
      };
    case ActionTypes.updateReturnOrderId:
      return {
        ...state,
        returnOrderId: action.payload
      };
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
