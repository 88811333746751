import React, { useEffect, useRef } from "react";

export const CameraPreviewer = ({ previewStream }: { previewStream: MediaStream }) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = ref.current!;
    video.srcObject = previewStream;

    return () => {
      previewStream.getTracks().forEach(track => track.stop());
    };
  }, []);

  return (
    <video
      ref={ref}
      autoPlay
      playsInline
      style={{
        width: "100vw",
        backgroundColor: "white",
        objectFit: "contain",
        marginInline: "auto"
      }}
    />
  );
};
