import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Order, ShippingAddress } from "actions";
import qs from "qs";
import { ShippingMethod } from "types";
import { PostCo360API } from "api/constants";

const getShippingFee = async (
  returnOrderId: Order["returnOrderId"],
  address: ShippingAddress | null,
  shippingMethod: ShippingMethod,
  shopId: number | null
): Promise<any> => {
  const query = {
    filter: { shopId: shopId },
    customerBorne: shippingMethod.customerBorne,
    returnOrderId,
    address
  };
  const queryString = qs.stringify(query, { arrayFormat: "brackets" });
  try {
    const response: AxiosResponse<any> = await PostCo360API().get(
      `/api/v2/customer/shipping_methods/${shippingMethod.id}/shipping_fee?${queryString}`
    );

    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

function useShippingFee(
  key: string,
  shippingMethod: ShippingMethod,
  address: ShippingAddress | null,
  enabled = true,
  options = {}
) {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { data, ...others } = useQuery({
    queryKey: [key, shippingMethod.id],
    queryFn: () => getShippingFee(returnOrderId, address, shippingMethod, shopId),
    enabled: enabled && Boolean(address),
    onError: (_err: Error) => {},
    ...options
  });
  return { ...others };
}

export { useShippingFee };
