import { ActionTypes } from "./types";
import { ShopLocale } from "actions";
import { clearCustomerLocaleCookie, getStoredCustomerLocale, setShopLocale, setCustomerLocaleCookie } from "helpers";

export interface SetLocaleAction {
  type: ActionTypes.setLocale;
  payload: ShopLocale;
}

export const defaultLocale: ShopLocale = {
  id: "0",
  shopId: 0,
  localeId: 0,
  isDefault: true,
  name: "English",
  code: "en"
};

export const setInitialLocale = (shopLocales: ShopLocale[]): SetLocaleAction => {
  setShopLocale(shopLocales.find(locale => locale.isDefault) as ShopLocale);
  const existingCustomerLocale = getStoredCustomerLocale();

  if (existingCustomerLocale && !shopLocales.map(locale => locale.code).includes(existingCustomerLocale.code)) {
    clearCustomerLocaleCookie();
  }

  return {
    type: ActionTypes.setLocale,
    payload: existingCustomerLocale || defaultLocale
  };
};

export const setLocale = (locale: ShopLocale): SetLocaleAction => {
  setCustomerLocaleCookie(locale);

  return {
    type: ActionTypes.setLocale,
    payload: locale
  };
};

export const shopifyLocales: { [key: string]: string } = {
  en: "en-US",
  vi: "vi-VN",
  "zh-Hant": "zh-TW",
  ro: "ro-RO",
  bg: "bg-BG",
  fr: "fr-FR",
  ms: "ms-MY",
  de: "de-DE",
  es: "es-ES",
  da: "da-DK",
  it: "it-IT",
  nl: "nl-NL"
};
