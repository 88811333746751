import React from "react";
import { getFormattedPriceString } from "helpers";
import { Grid } from "semantic-ui-react";
import { SquareImage } from "components";
import { LineItem } from "constants/invoice";
import styles from "./ExchangingLineItems.module.css";

interface LineItemProp {
  lineItem: LineItem;
}

export const ExchangingLineItem: React.FC<LineItemProp> = ({ lineItem }): JSX.Element => {
  const { returnItem, exchangeItem } = lineItem;
  const url =
    exchangeItem.imageUrl || "https://postco360-attachments.sgp1.cdn.digitaloceanspaces.com/static/blank-white.png";

  return (
    <React.Fragment>
      <Grid columns={2} verticalAlign="middle" style={{ padding: "8px 16px" }}>
        <Grid.Column computer={3}>
          <SquareImage src={url} />
        </Grid.Column>
        <Grid.Column computer={8}>
          <div className={styles.ellipsisWhenOverFlow}>{exchangeItem.name}</div>
          <div className={`${styles.ellipsisWhenOverFlow} ${styles.returningItem}`}>Returning {returnItem.name}</div>
        </Grid.Column>
        <Grid.Column computer={5} textAlign="right">
          <div>
            {getFormattedPriceString(exchangeItem.presentmentPrice.cents, exchangeItem.presentmentPrice.currencyIso, {
              useParenthesis: true
            })}
          </div>
          <div className={styles.returningItem}>
            {getFormattedPriceString(returnItem.presentmentPrice.cents, returnItem.presentmentPrice.currencyIso, {
              useParenthesis: true
            })}
          </div>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};
