import React from "react";
import { Button } from "components";
import { Link, useParams } from "react-router-dom";
import styles from "./NotFound.module.css";

interface PropTypes {
  header?: string;
  paragraph?: string;
  fallback_link?: string;
  showBtn?: boolean;
  btnString?: string;
}

export const NotFound: React.FC<PropTypes> = ({
  header = "Page Not Found",
  paragraph,
  fallback_link,
  showBtn = true,
  btnString = "Back to home"
}): JSX.Element => {
  const { shopSlug } = useParams<{ shopSlug: string }>();

  const button = showBtn && (
    <Button as={Link} color="primaryColor" size="large" to={fallback_link || `/${shopSlug}`}>
      {btnString}
    </Button>
  );

  return (
    <div className={styles.container}>
      <h1>{header}</h1>
      <p>{paragraph}</p>
      {button}
    </div>
  );
};
