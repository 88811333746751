import React from "react";

export const DeleteIcon = ({ onClick }: { onClick: () => void }) => (
  <div
    style={{
      padding: "8px",
      position: "absolute",
      top: -15,
      right: -15,
      zIndex: 10,
      margin: 0,
      borderRadius: "50%"
    }}
    onClick={onClick}
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: "white", borderRadius: "50%" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.875C4.51269 0.875 0.875 4.51269 0.875 9C0.875 13.4873 4.51269 17.125 9 17.125C13.4873 17.125 17.125 13.4873 17.125 9C17.125 4.51269 13.4873 0.875 9 0.875ZM7.56694 6.68306C7.32286 6.43898 6.92714 6.43898 6.68306 6.68306C6.43898 6.92714 6.43898 7.32286 6.68306 7.56694L8.11612 9L6.68306 10.4331C6.43898 10.6771 6.43898 11.0729 6.68306 11.3169C6.92714 11.561 7.32286 11.561 7.56694 11.3169L9 9.88388L10.4331 11.3169C10.6771 11.561 11.0729 11.561 11.3169 11.3169C11.561 11.0729 11.561 10.6771 11.3169 10.4331L9.88388 9L11.3169 7.56694C11.561 7.32286 11.561 6.92714 11.3169 6.68306C11.0729 6.43898 10.6771 6.43898 10.4331 6.68306L9 8.11612L7.56694 6.68306Z"
        fill="#CC2029"
      />
    </svg>
  </div>
);
