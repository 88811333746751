import React from "react";
import { Grid, Card } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { CardWithShadow } from "components";
import { ReturnItemSummary } from "./ReturnItemSummary";
import { ReturnItem } from "types";

interface ReturnItemsSummaryProps {
  title: string;
  returnItems: ReturnItem[];
}

export const ReturnItemsSummary: React.FC<ReturnItemsSummaryProps> = ({ title, returnItems }) => {
  return (
    <React.Fragment>
      <CardWithShadow>
        <Card.Content>
          {!isMobile && <Card.Header style={{ paddingBottom: "1rem" }}>{title}</Card.Header>}
          <Grid columns={3}>
            {returnItems.map(
              (returnItem, index: number): JSX.Element => (
                <ReturnItemSummary returnItem={returnItem} key={index} />
              )
            )}
          </Grid>
        </Card.Content>
      </CardWithShadow>
    </React.Fragment>
  );
};
