import React, { useState } from "react";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateReturnOrderThenContinue, ReturnSteps } from "actions";
import { PaymentDetails } from "../";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { useAuthContext } from "contexts/AuthProvider";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const PaymentAndSubmission: React.FC<any> = (): JSX.Element => {
  const { p2pRequestInfo } = useInvoiceContext();
  const optedInForRenewReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const instantExchange = useSelector((store: StoreState) => store.returnItems.instantExchange);

  const { requiresPayment } = useInvoiceContext();
  const { broadcastLogout } = useAuthContext();

  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    dispatch(
      updateReturnOrderThenContinue(history, ReturnSteps.summaryAndPaymentDetails, { continueToSubmittedStep: true })
    );
    broadcastLogout();
  };

  const toggleShowPayment = () => {
    setShowPaymentDetails(showPayment => !showPayment);
  };
  const ProceedToPaymentButton = () => {
    return (
      <div style={{ padding: "0rem 1rem" }}>
        <Button onClick={toggleShowPayment} color="primaryColor" fluid>
          {t("exchangePaymentPage.proceedToPayment")}
        </Button>
        {!instantExchange && (
          <p
            style={{
              color: "gray",
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              fontSize: "0.95rem",
              marginTop: "6px"
            }}
          >
            {t("summary.paymentHelpText")}
          </p>
        )}
      </div>
    );
  };
  return (
    <>
      <br />
      {requiresPayment ? (
        <>
          <SlideDown className={"my-dropdown-slidedown"}>
            <PaymentDetails header={t("orderSummary.paymentDetails")} showPaymentDetails={showPaymentDetails} />
          </SlideDown>
          {!showPaymentDetails && <ProceedToPaymentButton />}
        </>
      ) : (
        <div style={{ paddingInline: "14px" }}>
          <Button color="primaryColor" onClick={handleSubmit} fluid>
            {p2pRequestInfo.requestable
              ? optedInForRenewReturns
                ? t("summaryAndPaymentDetailsPage.submitRenewReturnRequest")
                : t("summaryAndPaymentDetailsPage.submitRegularReturnRequest")
              : t("orderSummary.submit")}
          </Button>
        </div>
      )}
    </>
  );
};
