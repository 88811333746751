import React from "react";
import { useShopifyCountries } from "hooks/useShopifyCountries";
import { getFieldsLayout, getAddressFieldValue } from "./helpers";
import { Card, Grid } from "semantic-ui-react";
import i18n from "i18n";
import { ShippingAddress, shopifyLocales } from "actions";
import { ShippingAddress as SummaryShippingAddress } from "types";

interface ShopifyShippingAddressSummaryProps {
  shippingAddress: ShippingAddress | SummaryShippingAddress;
}

export const ShopifyShippingAddressSummary: React.FC<ShopifyShippingAddressSummaryProps> = ({
  shippingAddress
}): JSX.Element => {
  const localeCode = i18n.language;
  const locale = shopifyLocales[localeCode] || "en-US";
  const { findCountry } = useShopifyCountries(locale);
  const shopifyCountry = findCountry(shippingAddress.countryCode);
  const fieldsLayout = getFieldsLayout(shopifyCountry);

  return (
    <Grid.Column>
      {fieldsLayout.map((line, index) => {
        const lineValue = line.map(e => getAddressFieldValue(e, shippingAddress)).join(" ");
        return <Card.Description key={index}>{lineValue}</Card.Description>;
      })}
    </Grid.Column>
  );
};
