import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "reducers";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { Loading } from "./Loading/Loading";
import { P2PImageSection } from "pages/SummaryAndPaymentDetails/components/P2PSection/P2PImageSection";
import { Accordion } from "components";

export const RenewReturnImagesAccordion = () => {
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const history = useHistory();

  const { productListings, isLoading: fetchingProductListings } = useP2PProductListings();

  if (fetchingProductListings) {
    return <Loading />;
  }

  const title = (
    <div
      style={{
        display: "inline",
        fontWeight: "bold",
        fontSize: 14,
        backgroundColor: "#fbfbfb",
        marginBottom: "32px"
      }}
    >
      reNEW return images
    </div>
  );

  const content = (
    <P2PImageSection>
      {productListings.map(productListing => (
        <P2PImageSection.Item
          key={productListing.id}
          productListing={productListing}
          onClick={() =>
            history.push(`/${shopSlug}/returns/${returnOrderId}/p2p/product_listings/${productListing.id}`)
          }
        />
      ))}
    </P2PImageSection>
  );

  return productListings.length > 0 ? (
    <div style={{ paddingBottom: "32px" }}>
      <Accordion
        panels={[
          {
            openByDefault: true,
            title,
            content
          }
        ]}
      />
    </div>
  ) : null;
};
