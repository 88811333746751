import { checkPriceIsWholeNumber, getFormattedPriceString, hasCustomName } from "helpers";
import { TFunction } from "i18next";
import { formModalPush, FormModalSteps, RefundMethod, setCurrentReturnItemProperty } from "actions";
import { Dispatch } from "redux";
import chroma from "chroma-js";

export function refundButtonText(
  refundMethods: RefundMethod[],
  t: TFunction,
  isBonusCreditPresent?: boolean,
  formattedRefundAmountString?: string
) {
  if (refundMethods.length === 1) {
    const refundMethod = refundMethods[0];

    if (refundMethod.hasCustomName) {
      return refundMethod.displayName;
    } else {
      const formattedRefundAmount = isBonusCreditPresent ? formattedRefundAmountString : "";
      const refundMethod = refundMethods[0].displayName.toLocaleLowerCase();
      const refundTo = t("refundTo", { formattedRefundAmount });

      return `${refundTo} ${refundMethod}`;
    }
  } else {
    return t("refundButton.combined_methods", isBonusCreditPresent ? { formattedRefundAmountString } : "");
  }
}

export function storeCreditButtonText(storeCreditMethods: RefundMethod[], t: TFunction) {
  if (storeCreditMethods.length === 1) {
    const methodName = hasCustomName(storeCreditMethods[0])
      ? storeCreditMethods[0].displayName.toLocaleLowerCase()
      : t("storeCredit");
    return `${t("returnFor")} ${methodName}`;
  } else {
    return `${t("returnFor")} ${t("storeCredit")}`;
  }
}

export function handleContinue(type: string, dispatch: Dispatch, appendStepHistory = true) {
  dispatch(setCurrentReturnItemProperty("returnMethodType", type));
  dispatch(formModalPush(FormModalSteps.returnMethodsSelector, appendStepHistory));
}

export function getFormattedAmount(amount: number, currency: string) {
  const isWholeNumber = checkPriceIsWholeNumber(amount, currency);
  const amountString = getFormattedPriceString(amount, currency);
  return isWholeNumber ? amountString.split(".")[0] : amountString;
}

export function getModalHeaderText(
  exchangeAvailable: boolean,
  storeCreditAvailable: boolean,
  refundAvailable: boolean,
  t: Function
) {
  let headerText = "";

  // If only one type of return method is available this screen will be skipped
  if (refundAvailable && storeCreditAvailable && exchangeAvailable) {
    headerText = t("refundOrStoreCreditOrExchange");
  } else if (refundAvailable && storeCreditAvailable) {
    headerText = t("refundOrStoreCredit");
  } else if (refundAvailable && exchangeAvailable) {
    headerText = t("refundOrExchange");
  } else if (storeCreditAvailable && exchangeAvailable) {
    headerText = t("storeCreditOrExchange");
  }

  return headerText;
}

export function getBonusCreditBadgeColor(primaryColor: string): { textColor: string; bonusCreditBadgeColor: string } {
  const bonusCreditBadgeDarkGreen = "#58B767";
  const bonusCreditBagdeLightGreen = "#C8EACD";
  const contrast = chroma.contrast(primaryColor!, bonusCreditBadgeDarkGreen);

  const bonusCreditBadgeColor =
    contrast === 1 ? "white" : contrast < 1.5 ? bonusCreditBagdeLightGreen : bonusCreditBadgeDarkGreen;

  const textColor =
    bonusCreditBadgeColor === bonusCreditBagdeLightGreen
      ? "black"
      : bonusCreditBadgeColor === bonusCreditBadgeDarkGreen
      ? "white"
      : bonusCreditBadgeDarkGreen;

  return { textColor, bonusCreditBadgeColor };
}

export function getPrioritizedButton(
  exchangeAvailable: boolean,
  storeCreditAvailable: boolean,
  exchangeBonusCredit: number,
  storeCreditBonusCredit: number
) {
  let priorityButton = "";

  if (exchangeAvailable && storeCreditAvailable) {
    if (exchangeBonusCredit >= storeCreditBonusCredit) {
      priorityButton = "exchange";
    } else {
      priorityButton = "storeCredit";
    }
  } else if (exchangeAvailable) {
    priorityButton = "exchange";
  } else if (storeCreditAvailable) {
    priorityButton = "storeCredit";
  }

  return priorityButton;
}
