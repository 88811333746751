import { ActionTypes } from "./types";

export interface ClearLoadingAction {
  type: ActionTypes.clearLoading;
}

export interface SetLoadingAction {
  type: ActionTypes.setLoading;
}

export const clearLoading = (): ClearLoadingAction => {
  document.body.style.overflowY = "scroll";

  return {
    type: ActionTypes.clearLoading
  };
};

export const setLoading = (): SetLoadingAction => {
  window.scrollTo(0, 0);
  document.body.style.overflowY = "hidden";

  return {
    type: ActionTypes.setLoading
  };
};
