import React from "react";
import { ShippingMethod } from "types";
import {
  MailDescription,
  PickupDescription,
  DropOffDescription,
  InstoreReturnsDescription,
  CustomDescription
} from ".";
import { ModalType } from "../ShippingMethodSelector";

interface DescriptionProps {
  method: ShippingMethod;
  openModal: ModalType;
  country: string;
  shopName: string;
}

export const Description: React.FC<DescriptionProps> = ({ method, openModal, country, shopName }) => {
  switch (method.type) {
    case "MailShippingMethod":
      return <MailDescription method={method} />;
    case "IntegratedShippingMethod":
      switch (method.serviceType) {
        case "DropOff":
          return <DropOffDescription method={method} openModal={openModal} />;
        case "Pickup":
        case "OnDemandPickup":
          return <PickupDescription country={country} shippingMethod={method} openModal={openModal} />;
        default:
          return null;
      }
    case "InStoreShippingMethod":
      return <InstoreReturnsDescription shopName={shopName} method={method} />;
    case "CustomShippingMethod":
      return <CustomDescription method={method} />;
    default:
      return null;
  }
};
