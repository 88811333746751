import { Variant } from "actions";
import React, { useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import { VariantSelectorContext } from "./context";
import { evaluateOptionDisability, getDisabledMessage, variantFromSelectionFilter } from "./helpers";

interface VariantOptionDropdown {
  optionName: string;
  onSelect: (value: string) => void;
  optionSelections: (string | null)[];
  optionIndex: "option1" | "option2" | "option3";
  selectedOption: string | undefined;
}

export const VariantOptionDropdown: React.FC<VariantOptionDropdown> = ({
  optionName,
  onSelect,
  optionSelections,
  optionIndex,
  selectedOption
}): JSX.Element => {
  const { selectedOption1, selectedOption2, selectedOption3, variants } = useContext(VariantSelectorContext);

  const options = optionSelections.map(option => {
    const variant = variants.find((variant: Variant) =>
      variantFromSelectionFilter(optionIndex, option, variant, selectedOption1, selectedOption2, selectedOption3)
    );
    const disabled = evaluateOptionDisability(optionIndex, option, variant, variants, selectedOption1, selectedOption2);
    return {
      text: option || "",
      value: option || "",
      disabled: disabled,
      description: disabled ? getDisabledMessage(variant) : ""
    };
  });

  function disableSelection() {
    switch (optionIndex) {
      case "option1":
        return false;
      case "option2":
        return selectedOption1 === null;
      case "option3":
        return selectedOption1 === null || selectedOption2 === null;
    }
  }

  function handleOnChange(event: any, data: any) {
    onSelect(data.value);
  }

  return (
    <Dropdown
      fluid
      selection
      placeholder={`Select ${optionName}`}
      options={options}
      disabled={disableSelection()}
      onChange={handleOnChange}
      value={selectedOption || ""}
    />
  );
};
