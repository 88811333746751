import { Courier } from "actions";

export interface ReturnOrder {
  id: string;
  status: string;
  address: {
    email: string;
  };
  requiresPayment: boolean;
  shippingMethod: {
    courier: Courier;
    shippingService: {
      type: string;
      holiday: boolean;
      weekend: boolean;
      serviceInfo?: {
        cutoffTime: string;
        holiday: boolean;
        noticeHour: number;
        hourRange: number;
        sameDayDelivery: boolean;
        startTime: string;
        weekend: boolean;
      };
    };
  };
  shipment: {
    feeCents: number;
    feeCurrency: string;
    pickupEmail: string;
  };
}

export const nullReturnOrder = {
  id: "0",
  address: {
    email: ""
  },
  status: "pending",
  requiresPayment: false,
  shippingMethod: {
    courier: {
      id: "0",
      allowCustomTrackingNumber: false,
      name: "",
      country: "",
      onDemand: false,
      weekend: false,
      holiday: false,
      startTime: "",
      cutoffTime: "",
      supportedPostcodes: [],
      sameDayDelivery: false,
      hourRange: 0,
      noticeHour: 0,
      pickup: false,
      dropOff: false,
      dropOffLocationsUrl: "",
      logoUrl: ""
    },
    shippingService: {
      type: "",
      holiday: false,
      weekend: false,
      serviceInfo: {
        cutoffTime: "",
        holiday: false,
        noticeHour: 0,
        hourRange: 0,
        sameDayDelivery: false,
        startTime: "",
        weekend: false
      }
    }
  },
  shipment: {
    feeCents: 0,
    feeCurrency: "",
    pickupEmail: ""
  }
};

export interface FormValues {
  noPrinter: boolean;
  trackingNumber: string;
}

export interface PaymentIntent {
  id: string;
  clientSecret: string;
}
