import React from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { PreSubmitReturnItemSummary } from "./PreSubmitReturnItemSummary";

export const PreSubmitReturnItemsSummary: React.FC = () => {
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const orderCurrency = useSelector((state: StoreState) => state.order.currency);

  return (
    <Grid columns={1}>
      {cart.map(
        (returnItem, index: number): JSX.Element => (
          <PreSubmitReturnItemSummary returnItem={returnItem} orderCurrency={orderCurrency} key={index} />
        )
      )}
    </Grid>
  );
};
