import React, { useContext, useEffect } from "react";
import { Slider, Slide, CarouselContext } from "pure-react-carousel";
import { Image } from "semantic-ui-react";
import styles from "./ImageCarousel.module.css";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface ImageSlideProps {
  imgUrls: string[];
}

export const ImageSlide: React.ComponentType<ImageSlideProps> = ({ imgUrls }): JSX.Element => {
  const { setStoreState } = useContext(CarouselContext);
  const productImageObjectFit = useSelector(
    (state: StoreState) => state.currentShop.exchangeSetting.productImageObjectFit
  );

  useEffect(() => {
    setStoreState({ currentSlide: 0 });
  }, [imgUrls, setStoreState]);

  return (
    <Slider>
      {imgUrls.map((img: string, index: number) => (
        <Slide index={index} key={index}>
          <div className={styles.carouselImgContainer}>
            <Image src={img} className={styles.slideImage} style={{ objectFit: productImageObjectFit }} />
          </div>
        </Slide>
      ))}
    </Slider>
  );
};
