import React from "react";
import { CarouselProvider, ButtonBack, ButtonNext } from "pure-react-carousel";
import { CircleDot, ImageDot, ImageSlide } from ".";
import styles from "./ImageCarousel.module.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { isMobile } from "react-device-detect";

interface ImageCarouselProps {
  imgUrls: string[];
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({ imgUrls }): JSX.Element => {
  return (
    <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={imgUrls.length}>
      <ImageSlide imgUrls={imgUrls} />
      {!isMobile && (
        <>
          <ButtonBack className={`${styles.slideButton} ${styles.slideBackButton}`}>
            <img
              src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/left-arrow.png"
              alt="go to previous image"
              style={{ height: "14px", cursor: "pointer", marginLeft: "5px" }}
            />
          </ButtonBack>
          <ButtonNext className={`${styles.slideButton} ${styles.slideNextButton}`}>
            <img
              src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/right-arrow.png"
              alt="go to next image"
              style={{ height: "14px", cursor: "pointer", marginRight: "5px" }}
            />
          </ButtonNext>
        </>
      )}
      {isMobile ? <CircleDot slides={imgUrls.length} /> : <ImageDot imgUrls={imgUrls} />}
    </CarouselProvider>
  );
};
