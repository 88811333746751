import { Loading } from "components/Loading/Loading";
import React from "react";
import { useCollectionProducts } from "./hooks";
import styles from "./ExchangeProductSelector.module.css";
import { ProductCard } from "./ProductCard";
import { Button } from "components/Custom";
import { CollectionTab } from "./types";
import { NoProductsFound } from "./NoProductsFound";

interface Props {
  selectedTab: CollectionTab;
}

export const CollectionProducts = ({ selectedTab }: Props) => {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useCollectionProducts(selectedTab.id);

  const currentProductsCount = data.pages.reduce((count, page) => {
    return count + page.products.length;
  }, 0);

  if (isLoading) {
    return <Loading />;
  }

  return currentProductsCount === 0 ? (
    <NoProductsFound title="NO PRODUCTS FOUND" description="No exchangeable products available" />
  ) : (
    <div className={styles.productGridContainer}>
      <div className={styles.productCardGrid}>
        {data.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.products.map(product => (
              <ProductCard product={product} key={product.id} />
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className={styles.footerSection}>
        {hasNextPage && (
          <div className={styles.loadMoreButtonContainer}>
            <Button
              color="primaryColor"
              loading={isFetchingNextPage}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              LOAD MORE
            </Button>
          </div>
        )}
        <div className={styles.footerDescription}>
          <p>
            Viewing {currentProductsCount} exchangeable product(s) of {selectedTab.productsCount} product(s)
          </p>
        </div>
      </div>
    </div>
  );
};
