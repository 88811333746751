import { MainInvoice } from "constants/invoice";
import { getFormattedPriceString } from "helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { SquareImage } from "components";
import styles from "./ExchangingLineItems.module.css";
import { Accordion } from "components";

interface ExchaningLineItemsProps {
  exchangingForLineItems: MainInvoice["exchangingForLineItems"];
}

export const ExchangingLineItems: React.FC<ExchaningLineItemsProps> = ({ exchangingForLineItems }): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  const title = (
    <div
      style={{
        display: "inline",
        fontWeight: "bold",
        fontSize: 14,
        backgroundColor: "#fbfbfb"
      }}
    >
      {t("summary.exchangingItems")}
      {exchangingForLineItems ? (
        <span style={{ float: "right" }}>
          {getFormattedPriceString(
            exchangingForLineItems.reduce((totalCents, lineItem) => totalCents + lineItem.presentmentPrice.cents, 0),
            exchangingForLineItems[0].presentmentPrice.currencyIso
          )}
        </span>
      ) : null}
    </div>
  );

  const content = exchangingForLineItems?.map((item, index) => {
    const url = item.imageUrl || "https://postco360-attachments.sgp1.cdn.digitaloceanspaces.com/static/blank-white.png";

    return (
      <Grid key={index} columns={3} verticalAlign="middle" style={{ padding: "8px 16px" }}>
        <Grid.Column computer={3}>
          <SquareImage src={url} />
        </Grid.Column>
        <Grid.Column computer={7}>
          <div className={styles.exchangeItemName}>{item.name}</div>
          {item.type === "other_product" && (
            <span className={styles.helpText}>{t("summary.otherProductHelpText")}</span>
          )}
        </Grid.Column>
        <Grid.Column computer={6} textAlign="right">
          {getFormattedPriceString(item.presentmentPrice.cents, item.presentmentPrice.currencyIso, {
            useParenthesis: true
          })}
        </Grid.Column>
      </Grid>
    );
  });

  return (
    <div style={{ marginBottom: 32 }}>
      <Accordion
        panels={[
          {
            title,
            content
          }
        ]}
      />
    </div>
  );
};
