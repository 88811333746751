import {
  updateReturnOrderThenContinue,
  ReturnSteps,
  ShippingAddress,
  updateShippingAddress,
  shopifyLocales
} from "actions";
import { ContactDetailsFields, ContinueButton, Loading } from "components";
import { Formik, FormikProps, validateYupSchema, yupToFormErrors, FormikErrors } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "reducers";
import { Form } from "semantic-ui-react";
import { getAddressFormHeader, getInitialAddressValues, shippingAddressValidationSchema } from "../helpers";
import styles from "../ShippingAddressDetails.module.css";
import { ScrollToFirstErrorField } from "components/ScrollToFirstErrorField";
import { ShopifyAddressFields } from "components";
import { useShopifyCountries } from "hooks/useShopifyCountries";

interface Props {
  formRef: React.RefObject<FormikProps<ShippingAddress>>;
}

export const ShippingAddressFormForStickyButtons = ({ formRef }: Props): JSX.Element => {
  // TODO: when refreshing this page, the `state.returnItems.shippingMethod.courier` Redux state is reset.
  // `courier` might not be the only state that is reset on page refreshing (needs more investigation)
  const { t, i18n } = useTranslation("orderFlow");
  const localeCode = i18n.language;
  const { findCountry, isLoading: countriesAreLoading } = useShopifyCountries(shopifyLocales[localeCode] || "en-US");

  const autoHideValidShippingAddress = useSelector(
    (state: StoreState) => state.currentShop.autoHideValidShippingAddress
  );
  const ecomProvider = useSelector((state: StoreState) => state.currentShop.ecomProvider);
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const order = useSelector((state: StoreState) => state.order);

  const dispatch = useDispatch();
  const isShopify = ecomProvider === "Shopify";
  const initialValues = getInitialAddressValues(order, findCountry);

  const validate = (values: ShippingAddress): FormikErrors<ShippingAddress> => {
    try {
      validateYupSchema(values, shippingAddressValidationSchema(isShopify), true, { skipCountryValidation: true });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  };

  const handleContinue = (values: ShippingAddress) => {
    dispatch(updateShippingAddress(values));
  };

  if (countriesAreLoading) return <Loading />;

  return (
    <div style={{ paddingLeft: "1rem", marginBottom: "50px" }}>
      <div className={styles.submitContainer}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleContinue}
          validate={validate}
          innerRef={formRef}
          enableReinitialize
        >
          {(formikProps: FormikProps<ShippingAddress>): JSX.Element => {
            const { handleSubmit } = formikProps;
            const header = getAddressFormHeader(autoHideValidShippingAddress, cart, t);

            return (
              <Form onSubmit={handleSubmit}>
                <ScrollToFirstErrorField />
                <ShopifyAddressFields
                  header={header}
                  autoHideIfValid={autoHideValidShippingAddress}
                  validateShippingZones
                  shippingAddressPage={true}
                />
                <ContactDetailsFields phoneNumber={initialValues.phone} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export const ShippingAddressForm: React.FC = (): JSX.Element => {
  // TODO: when refreshing this page, the `state.returnItems.shippingMethod.courier` Redux state is reset.
  // `courier` might not be the only state that is reset on page refreshing (needs more investigation)

  const currentStep = ReturnSteps.shippingAddress;
  const { t, i18n } = useTranslation("orderFlow");
  const localeCode = i18n.language;
  const { findCountry, isLoading: countriesAreLoading } = useShopifyCountries(shopifyLocales[localeCode] || "en-US");
  const history = useHistory();

  const autoHideValidShippingAddress = useSelector(
    (state: StoreState) => state.currentShop.autoHideValidShippingAddress
  );
  const ecomProvider = useSelector((state: StoreState) => state.currentShop.ecomProvider);
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const order = useSelector((state: StoreState) => state.order);

  const dispatch = useDispatch();
  const isShopify = ecomProvider === "Shopify";
  const initialValues = getInitialAddressValues(order, findCountry);

  function validate(values: ShippingAddress) {
    try {
      validateYupSchema(values, shippingAddressValidationSchema(isShopify), true, { skipCountryValidation: true });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  }
  const handleContinue = (values: ShippingAddress) => {
    dispatch(updateShippingAddress(values));
    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  if (countriesAreLoading) return <Loading />;

  return (
    <div style={{ paddingLeft: "1rem" }}>
      <div className={styles.submitContainer}>
        <Formik initialValues={initialValues} onSubmit={handleContinue} validate={validate} enableReinitialize>
          {(formikProps: FormikProps<ShippingAddress>): JSX.Element => {
            const { handleSubmit, isSubmitting } = formikProps;
            const header = getAddressFormHeader(autoHideValidShippingAddress, cart, t);
            return (
              <Form onSubmit={handleSubmit}>
                <ScrollToFirstErrorField />
                <ShopifyAddressFields
                  header={header}
                  autoHideIfValid={autoHideValidShippingAddress}
                  validateShippingZones
                  shippingAddressPage={true}
                />
                <ContactDetailsFields phoneNumber={initialValues.phone} />
                <ContinueButton isSubmitting={isSubmitting} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
