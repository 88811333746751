interface searchParams {
  [key: string]: any;
}
export const searchParamsToString = (params: searchParams): string => {
  const queryString = new URLSearchParams();
  Object.entries(params).forEach(([key, value]: [string, string]): void =>
    queryString.append(key, value)
  );

  return `?${queryString.toString()}`;
};
