import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { getExchangeableCollections } from "./api";
import { setError, closeFormModal, clearCurrentReturnItem } from "actions";
import { useReturnMethods } from "hooks/useReturnMethods";
import { PostCo360API } from "api/constants";
import qs from "qs";
import camelcaseKeys from "camelcase-keys";
import {
  CollectionTab,
  GraphqlApiCollectionProductsResponse,
  RestApiCollectionProductPageParams,
  RestApiCollectionProductsQueryData,
  RestApiCollectionProductsResponse
} from "./types";
import { useTranslation } from "react-i18next";

export const useExchangeableCollections = () => {
  const dispatch = useDispatch();

  const productId = useSelector((state: StoreState) => state.returnItems.currentReturnItem.productId);
  const shopId = useSelector((state: StoreState) => state.currentShop.id);

  const { data, isLoading } = useQuery({
    queryKey: ["exchangeable_collections", productId, shopId],
    queryFn: () => getExchangeableCollections(productId, shopId),
    onError: error => {
      dispatch(setError(error as Error));
      dispatch(clearCurrentReturnItem());
      dispatch(closeFormModal());
    }
  });

  const exchangeableCollections = data ?? [];

  return { exchangeableCollections, isLoading };
};

export const useCollectionTabs = () => {
  const { exchangeMethods, isLoading: isLoadingReturnMethods } = useReturnMethods();
  const { exchangeableCollections, isLoading: isLoadingExchangeableCollections } = useExchangeableCollections();
  const { t } = useTranslation("orderFlow");

  const collectionTabs: CollectionTab[] = [];

  const isLoading = isLoadingExchangeableCollections || isLoadingReturnMethods;

  if (isLoading) {
    return { collectionTabs, isLoading, exchangeMethods };
  }

  exchangeableCollections.sort((collection1, collection2) => collection2.productsCount - collection1.productsCount);

  const sameProductReturnMethod = exchangeMethods.find(method => method.name === "same_product");
  if (sameProductReturnMethod) {
    collectionTabs.push({ id: "same_product", title: sameProductReturnMethod.displayName, productsCount: 1 });
  }

  collectionTabs.push({
    id: "all",
    title: t("productSelector.allCollectionsProductsTitle"),
    productsCount: exchangeableCollections.reduce(
      (totalProductsCount, collection) => totalProductsCount + collection.productsCount,
      0
    )
  });

  collectionTabs.push(...exchangeableCollections);

  return { collectionTabs, isLoading, exchangeMethods };
};

export function useAllCollectionsProducts(sortedCollectionIds: string[]) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const returnMethodId = currentReturnItem.returnMethod.returnMethodId;
  const productId = currentReturnItem.productId;
  const variantId = currentReturnItem.variantId;
  const lineItemId = currentReturnItem.lineItemId;
  const bundleItemId = currentReturnItem.bundleItemId;

  const {
    data: responseData,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage
  } = useInfiniteQuery<RestApiCollectionProductsResponse>(
    ["allCollectionsProducts", lineItemId, bundleItemId],
    async ({
      pageParam = {
        collectionId: sortedCollectionIds[0],
        nextPageInfo: null
      }
    }) => {
      const { collectionId, nextPageInfo } = pageParam;
      const query = {
        filter: {
          shopId
        },
        collectionId,
        nextPageInfo,
        returnMethodId,
        returnOrderId,
        orderId,
        lineItemId,
        bundleItemId,
        variantId,
        productId
      };
      const queryString = qs.stringify(query, { arrayFormat: "brackets" });
      const res = await PostCo360API().get(`/api/v2/customer/collection_products?${queryString}`);
      return camelcaseKeys(res.data, { deep: true });
    },
    {
      staleTime: 180000,
      onSuccess: data => {
        const pages = data.pages;
        const pageParams = data.pageParams as RestApiCollectionProductPageParams[];
        const lastPage = data.pages[pages.length - 1];
        const cacheKeys = ["collectionProducts", lastPage.collectionId, lineItemId, bundleItemId];
        const cachePageParams = [
          undefined,
          ...pageParams.filter(param => param?.collectionId === lastPage.collectionId)
        ];
        const cachePages = [...pages.filter(page => page.collectionId === lastPage.collectionId)];
        queryClient.setQueryData<RestApiCollectionProductsQueryData>(cacheKeys, {
          pageParams: cachePageParams,
          pages: cachePages
        });
      },
      onError: error => {
        dispatch(setError(error as Error));
        dispatch(clearCurrentReturnItem());
        dispatch(closeFormModal());
      },
      getNextPageParam: lastPage => {
        const currentCollectionIdIndex = sortedCollectionIds.findIndex(id => id === lastPage.collectionId);
        if (lastPage.hasNextPage) {
          return { collectionId: lastPage.collectionId, nextPageInfo: lastPage.nextPageInfo };
        } else if (currentCollectionIdIndex !== sortedCollectionIds.length - 1) {
          return { collectionId: sortedCollectionIds[currentCollectionIdIndex + 1], nextPageInfo: null };
        } else {
          return undefined;
        }
      }
    }
  );

  const nullResponse: RestApiCollectionProductsResponse = {
    hasNextPage: false,
    nextPageInfo: "",
    collectionId: "",
    products: []
  };
  const data = responseData ?? { pages: [nullResponse], pageParams: [undefined] };

  const autoFetchNextPage = responseData
    ? responseData.pages.length < sortedCollectionIds.length &&
      responseData.pages[responseData.pages.length - 1].products.length < 10
    : false;

  return { data, hasNextPage, isLoading, isFetching, isFetchingNextPage, fetchNextPage, autoFetchNextPage };
}

export function useCollectionProducts(collectionId: string) {
  const dispatch = useDispatch();

  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const returnMethodId = currentReturnItem.returnMethod.returnMethodId;
  const productId = currentReturnItem.productId;
  const variantId = currentReturnItem.variantId;
  const lineItemId = currentReturnItem.lineItemId;
  const bundleItemId = currentReturnItem.bundleItemId;

  const { data: responseData, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage } = useInfiniteQuery<
    RestApiCollectionProductsResponse
  >(
    ["collectionProducts", collectionId, lineItemId, bundleItemId],
    async ({
      pageParam = {
        collectionId: collectionId,
        nextPageInfo: null
      }
    }) => {
      const query = {
        filter: {
          shopId
        },
        collectionId: pageParam.collectionId,
        nextPageInfo: pageParam.nextPageInfo,
        returnMethodId,
        returnOrderId,
        orderId,
        lineItemId,
        bundleItemId,
        variantId,
        productId
      };
      const queryString = qs.stringify(query, { arrayFormat: "brackets" });
      const res = await PostCo360API().get(`/api/v2/customer/collection_products?${queryString}`);
      return camelcaseKeys(res.data, { deep: true });
    },
    {
      staleTime: 180000,
      onError: error => {
        dispatch(setError(error as Error));
        dispatch(clearCurrentReturnItem());
        dispatch(closeFormModal());
      },
      getNextPageParam: lastPage => {
        if (lastPage.hasNextPage) {
          return { collectionId: collectionId, nextPageInfo: lastPage.nextPageInfo };
        } else {
          return undefined;
        }
      }
    }
  );

  const nullResponse: RestApiCollectionProductsResponse = {
    collectionId: "",
    hasNextPage: false,
    nextPageInfo: "",
    products: []
  };
  const data = responseData ?? { pages: [nullResponse], pageParams: [undefined] };

  return { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage };
}

export function useFilteredCollectionsProducts(searchTerm: string, collectionIds: string[], options = {}) {
  const dispatch = useDispatch();

  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const returnMethodId = currentReturnItem.returnMethod.returnMethodId;
  const productId = currentReturnItem.productId;
  const variantId = currentReturnItem.variantId;
  const lineItemId = currentReturnItem.lineItemId;
  const bundleItemId = currentReturnItem.bundleItemId;

  const { data: responseData, ...others } = useInfiniteQuery<GraphqlApiCollectionProductsResponse>(
    ["filteredCollectionsProducts", searchTerm, collectionIds, lineItemId, bundleItemId],
    async ({
      pageParam = {
        endCursor: null
      }
    }) => {
      const query = {
        filter: {
          shopId
        },
        endCursor: pageParam.endCursor,
        availableCollectionIds: collectionIds,
        searchTerm: searchTerm.replace(/\\/g, ""),
        returnMethodId,
        returnOrderId,
        orderId,
        lineItemId,
        bundleItemId,
        variantId,
        productId
      };
      const queryString = qs.stringify(query, { arrayFormat: "brackets" });
      const res = await PostCo360API().get(`/api/v2/customer/filtered_collection_products?${queryString}`);
      return camelcaseKeys(res.data, { deep: true });
    },
    {
      enabled: Boolean(searchTerm),
      staleTime: 180000,
      onError: error => {
        dispatch(setError(error as Error));
        dispatch(clearCurrentReturnItem());
        dispatch(closeFormModal());
      },
      getNextPageParam: lastPage => {
        if (lastPage.hasNextPage) {
          return { endCursor: lastPage.endCursor };
        } else {
          return undefined;
        }
      },
      ...options
    }
  );

  const nullResponse: GraphqlApiCollectionProductsResponse = {
    collectionId: "",
    hasNextPage: false,
    endCursor: "",
    products: []
  };
  const data = responseData ?? { pages: [nullResponse], pageParams: [undefined] };

  return { data, ...others };
}
