import React from "react";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { getFormattedPriceString } from "helpers/currency";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import styles from "./InstantExchangeSection.module.css";
import { useInstantExchangeData } from "hooks/useInstantExchange";
import { Loading } from "components";
import { useIsEmbedded } from "hooks/useIsEmbedded";

export const PreSubmissionInstantExchangeSection: React.FC = (): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { instantExchangeInvoice, instantExchangeExchangeOrderInvoice: exchangeOrderInvoice } = useInvoiceContext();
  const { returningLineItems, presentmentCurrency: currency } = exchangeOrderInvoice;

  const exchangeOrderTotalCents = exchangeOrderInvoice.total.cents;
  const returnItemsAmountCents = returningLineItems.reduce(
    (acc, returnItem) => Math.abs(returnItem.presentmentPrice.cents) + acc,
    0
  );

  const { isEmbedded } = useIsEmbedded();
  const {
    instantExchangeData: { paymentOption, shipBackWindow },
    isLoading
  } = useInstantExchangeData();
  const chargeAndRefund = paymentOption === "charge_and_refund";

  const instantExchangeAmountCents = chargeAndRefund ? instantExchangeInvoice.total.cents : returnItemsAmountCents;
  const instantExchangeAmount = getFormattedPriceString(instantExchangeAmountCents, currency);

  const instantExchangeDescription = chargeAndRefund
    ? t("summary.instantExchange.chargeOnSubmitDescription", { instantExchangeAmount })
    : t("summary.instantExchange.saveOnSubmitDescription", { shipBackWindow, instantExchangeAmount });

  const exchangeOrderAmount = getFormattedPriceString(exchangeOrderInvoice.total.cents, currency);

  const totalAmountCents =
    exchangeOrderTotalCents > 0 ? exchangeOrderTotalCents + instantExchangeAmountCents : instantExchangeAmountCents;
  const totalAmount = getFormattedPriceString(totalAmountCents, currency);

  const ExchangeOrderOutstanding = () => (
    <>
      <Grid.Column computer={12} className={styles.totalDue}>
        {t("summary.instantExchange.totalOutstandingAmountDueToday")}
      </Grid.Column>
      <Grid.Column computer={4} textAlign="right" className={styles.totalDue}>
        {exchangeOrderAmount}
      </Grid.Column>
    </>
  );

  const InstantExchangeOutstanding = () => (
    <>
      {exchangeOrderInvoice.total.cents > 0 && (
        <>
          <Grid.Column computer={12} className={styles.subTotalDue}>
            {t("summary.instantExchange.outstandingPayment")}
          </Grid.Column>
          <Grid.Column
            computer={4}
            textAlign="right"
            className={styles.subTotalDue}
            style={{ paddingLeft: isEmbedded ? "1rem" : 0 }}
          >
            {exchangeOrderAmount}
          </Grid.Column>
        </>
      )}
      <Grid.Column computer={12} className={styles.totalDue}>
        {t("summary.instantExchange.totalDue")}
      </Grid.Column>
      <Grid.Column
        computer={4}
        textAlign="right"
        className={styles.totalDue}
        style={{ paddingLeft: isEmbedded ? "1rem" : 0 }}
      >
        {totalAmount}
      </Grid.Column>
    </>
  );

  if (isLoading) return <Loading />;

  return (
    <div className={styles.instantExchangeSection} style={{ padding: "8px 16px" }}>
      <Grid columns={2}>
        <Grid.Column computer={12} className={styles.subTotalDue}>
          <div>{t("summary.instantExchange.temporaryHold")}</div>
          <div style={{ fontStyle: "italic", fontSize: "0.85rem", fontWeight: "normal" }}>
            {instantExchangeDescription}
          </div>
        </Grid.Column>
        <Grid.Column
          computer={4}
          textAlign="right"
          className={styles.subTotalDue}
          style={{ paddingLeft: isEmbedded ? "1rem" : 0 }}
        >
          {instantExchangeAmount}
        </Grid.Column>

        {!chargeAndRefund && exchangeOrderInvoice.total.cents > 0 && <ExchangeOrderOutstanding />}
        {chargeAndRefund && totalAmountCents > 0 && <InstantExchangeOutstanding />}
      </Grid>
    </div>
  );
};
