import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { NormalShippingMethodSummary } from "./components/NormalShippingMethodSummary";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { P2PShippingMethodSummary } from "./components/P2PShippingMethodSummary";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { Header } from "semantic-ui-react";
import styles from "./ShippingMethodSummary.module.css";
import { changeReturnStep, ReturnSteps } from "actions";
import { useTranslation } from "react-i18next";
import { useShipment } from "./hooks";
import { Loading } from "components";

export const ShippingMethodSummary: React.FC = () => {
  const p2pReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);

  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();

  const { returnItemsReceived, editableReturnOrder } = useReturnOrderStatus();
  const {
    shipment: {
      courierName,
      id: shipmentId,
      shippingMethod: { requireShipment }
    },
    isLoading
  } = useShipment();

  const handleSelectEdit = (): void => {
    dispatch(changeReturnStep(ReturnSteps.shippingMethod));
  };

  if (isLoading) return <Loading />;

  // All items kept => shipment nil, require shipment true => showShippingMethodSummary is false
  // Instore Returns=> shipment nil, require shipment false => showShippingMethodSummary is true
  const showNormalShippingMethodSummary =
    (Boolean(shipmentId) || !requireShipment) && (!returnItemsReceived || courierName);
  const showShippingMethodSummary = p2pReturns ? !returnItemsReceived : showNormalShippingMethodSummary;

  if (!showShippingMethodSummary) return null;

  return (
    <div style={{ borderBottom: !isMobile ? "1px solid #D9D9D9" : "", padding: isMobile ? "4px 16px" : "30px 0px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
        <span>
          <Header as={isMobile ? "h4" : "h3"}>{t("shippingMethodSummary.header")}</Header>
        </span>
        {editableReturnOrder && (
          <Link to="#" className={styles.headerLink} onClick={handleSelectEdit}>
            {`${t(`shippingMethodSummary.edit`)}`}
          </Link>
        )}
      </div>
      {p2pReturns ? <P2PShippingMethodSummary /> : <NormalShippingMethodSummary />}
    </div>
  );
};
