import React from "react";
import { Button } from "components";
import { isMobile } from "react-device-detect";
import { Label } from "semantic-ui-react";
import styles from "../BonusCreditReturnMethodTypeSelector.module.css";
import { getBonusCreditBadgeColor, getFormattedAmount } from "../helpers";
import { StoreState } from "reducers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

type Props = {
  title: string | JSX.Element | JSX.Element[];
  description: string | JSX.Element | JSX.Element[];
  isPrioritized: boolean;
  bonusCredit?: number;
  onClick: () => void;
  hideAmount?: boolean;
};

const BonusCreditBadge = ({
  containerBackgroundColor,
  content
}: {
  containerBackgroundColor: string;
  content: string;
}) => {
  const { textColor: color, bonusCreditBadgeColor: backgroundColor } = getBonusCreditBadgeColor(
    containerBackgroundColor
  );

  return (
    <Label
      style={{
        fontSize: "12px",
        fontWeight: 800,
        backgroundColor,
        color,
        marginBottom: "6px",
        padding: "4px 6px",
        position: "relative",
        textAlign: "center",
        whiteSpace: "nowrap"
      }}
      size="mini"
    >
      {content}
    </Label>
  );
};

export const ReturnMethodTypeButton = ({
  title,
  description,
  isPrioritized,
  bonusCredit,
  onClick,
  hideAmount
}: Props) => {
  const displayPrice = useSelector((state: StoreState) => state.formModal.selectedLineItem!.displayPrice);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const { t } = useTranslation("orderFlow");

  const bonusCreditAmount = bonusCredit || 0;

  const formattedTotalCredit = getFormattedAmount(displayPrice.cents + bonusCreditAmount, currency);
  const formattedBonusCredit = getFormattedAmount(bonusCreditAmount, currency).replace(/\s/, "");
  const primaryColor = useSelector((state: StoreState) => state.frontendConfigs.primaryColor) ?? "white";

  return (
    <div className={styles.buttonContainer}>
      <Button
        color={isPrioritized ? "primaryColor" : undefined}
        fluid
        onClick={onClick}
        style={{
          border: "solid 0.5px"
        }}
        className={isPrioritized ? styles.highPriorityButton : styles.lowPriorityButton}
      >
        <div className={styles.buttonContentContainer}>
          <div className={styles.firstLine} style={{ fontSize: isMobile ? "14px" : "16px" }}>
            <div className={styles.bonusCreditBadge}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  textAlign: "left",
                  marginRight: "8px",
                  gap: isMobile ? "8px" : "8px",
                  alignItems: "flex-start"
                }}
              >
                {title}
                {Boolean(bonusCredit) && (
                  <BonusCreditBadge
                    containerBackgroundColor={primaryColor}
                    content={`+ ${formattedBonusCredit} ${t("bonusCreditText")}`}
                  />
                )}
              </div>
            </div>
            {!hideAmount && formattedTotalCredit}
          </div>

          <p className={styles.buttonDescription}>{description}</p>
        </div>
      </Button>
    </div>
  );
};
