import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Header, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { PageHeader, FormModal, Button, Loading } from "components";
import { ReturnSteps, setFromAddress, updateReturnOrderThenContinue, Order } from "../../actions";
import { StoreState } from "../../reducers";
import styles from "./ReturnItemsSelection.module.css";
import { isAllItemsRejected } from "helpers/returnItems";
import parse from "html-react-parser";
import { useHistory, useLocation } from "react-router-dom";
import { useCheckCurrentStep } from "helpers/currentStep";
import { LineItemCard } from "./components/LineItemCard";
import { BundleReturnLineItemCard } from "./components/BundleReturnLineItemCard";
import { useReturnableItemsValidation } from "hooks/useReturnableItemsValidation";
import { useExchangeableItemsValidation } from "hooks/useExchangeableItemsValidation";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { useQuery, useQueryClient } from "react-query";
import { getLastReturnOrderId } from "api";
import { useAuthContext } from "contexts/AuthProvider";
import { StickyBottomAppBar } from "StickyBottomAppBar";
import { ContinueBottomAppBar } from "components/ContinueBottomAppBar";
import { Stepper } from "components/Stepper";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import qs from "qs";
import { saveMobileEmbeddedKeyToStorage } from "helpers/sessionStorage";

export const ReturnItemsSelection: React.FC = (): JSX.Element | null => {
  const currentStep = ReturnSteps.returnItems;
  useCheckCurrentStep(currentStep);

  useReturnableItemsValidation();
  useExchangeableItemsValidation();

  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isFetching } = useAuthContext();

  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnItems = useSelector((state: StoreState) => state.returnItems);
  const order = useSelector((state: StoreState) => state.order);
  const hasLineItems = order.lineItems.length > 0;
  const returnItemsSelectionDisclaimer = useSelector(
    (state: StoreState) => state.currentShop.returnItemsSelectionDisclaimer
  );
  const selectedItemsCount = useSelector((state: StoreState) => state.returnItems.cart.length);
  const returnOrderUnreturnable = order.lineItems.every(lineItem => lineItem.unreturnableReason);

  const uniqueReturnableDeadlines = new Set(
    order.lineItems.map(lineItem => lineItem.returnableDeadline).filter(deadline => Boolean(deadline))
  );
  const [returnableDeadline] = uniqueReturnableDeadlines;
  const showReturnableDeadline =
    ["draft", "pending", "update_draft"].includes(order.status) && uniqueReturnableDeadlines.size === 1;

  const dispatch = useDispatch();

  const { t } = useTranslation("orderFlow");

  const {
    featureFlags: { stickyButtons }
  } = useFeatureFlags();

  const { isEmbedded } = useIsEmbedded();

  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const returnOrderIdFromUrl = matchResult && matchResult.length ? matchResult[1] : "";
  const returnOrderIdFromState = order.returnOrderId;

  const [lastReturnOrderId, setLastReturnOrderId] = useState<number | null>(null);
  const { isFetching: isGettingLastReturnOrderId } = useQuery({
    queryKey: ["getLastReturnOrderId", returnOrderIdFromState],
    queryFn: () => getLastReturnOrderId({ returnOrderId: returnOrderIdFromState }),
    onSuccess: data => setLastReturnOrderId(data.lastReturnOrderId)
  });

  // reset from address when user navigate back here.
  useEffect(() => {
    dispatch(setFromAddress(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (returnOrderIdFromUrl !== returnOrderIdFromState) {
      queryClient.invalidateQueries(["returnOrder", returnOrderIdFromUrl]);
      queryClient.invalidateQueries(["order", returnOrderIdFromUrl]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOriginFromMobileEmbeddedApp = urlSearchParams.get("mobileEmbedded");

    Boolean(isOriginFromMobileEmbeddedApp) && saveMobileEmbeddedKeyToStorage("true");
  }, []);

  const hasReturnItems = returnItems.cart.length > 0;

  const getStatusHeader = (orderStatus: Order["status"]): string => {
    const headerStatuses = {
      draft: t("headers.new"),
      update_draft: t("headers.pending"),
      pending: t("headers.pending"),
      reviewed: t("headers.reviewed"),
      archived: t("headers.archived"),
      received: t("headers.received"),
      pending_payment_from_customer: t("headers.pendingPaymentFromCustomer"),
      stripe_account_disabled: t("headers.stripeAccountDisabled"),
      pending_action: t("headers.pendingAction"),
      completed: isAllItemsRejected(returnItems.cart) ? t("headers.rejected") : t("headers.completed"),
      rejected: t("headers.rejected")
    };
    return headerStatuses[orderStatus];
  };
  const handleSubmit = (): void => {
    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  const status = order.status;
  const notDraft = typeof status === "string" && status.trim.length > 0 && status !== "draft";

  const canContinue =
    (order.status === "draft" ||
      (order.status === "update_draft" && returnItems.isDirty) ||
      (order.status === "pending" && returnItems.isDirty)) &&
    !returnOrderUnreturnable &&
    hasReturnItems;

  const queryString = qs.stringify(
    { orderNameOrId: order.id, emailOrPhone: order.customer.email },
    { arrayFormat: "brackets" }
  );
  const pastReturnsButton =
    !isGettingLastReturnOrderId && lastReturnOrderId ? (
      <p style={{ textAlign: "center", marginTop: "-24px", marginBottom: "30px" }}>
        <a
          href={`/${shopSlug}/returns/${lastReturnOrderId}/details?${queryString}`}
          className={styles.pastReturnsButton}
        >
          Check status of past returns
        </a>
      </p>
    ) : null;

  if (isFetching) {
    return <Loading />;
  }

  const ProgressBarSection = () => (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-around",
          marginTop: isEmbedded ? "16.5px" : 0
        }}
      >
        <Stepper totalStepCount={3} currentStepCount={1} />
      </div>
    </div>
  );

  return stickyButtons ? (
    <div className={styles.returnItemsSelectionContainer}>
      <Grid centered container padded="vertically">
        <Grid.Column mobile={16} tablet={13} computer={10} style={{ marginBottom: isEmbedded ? "100px" : 0 }}>
          <ProgressBarSection />
          <PageHeader header={parse(getStatusHeader(order.status)) as string} />
          {hasLineItems && showReturnableDeadline && (
            <p className={styles.returnableDeadline}>{t("orderSummary.returnableUntil", { returnableDeadline })}</p>
          )}
          {pastReturnsButton}
          {order.lineItems.length === 0 && (
            <Message warning>
              <Message.Header>
                <Header as="h3" textAlign="center">
                  {t("orderSummary.noItemMessage")}
                </Header>
              </Message.Header>
            </Message>
          )}
          {hasLineItems &&
            order.lineItems.map((lineItem, index) =>
              !lineItem.bundleItems.length || lineItem.quantity === 0 ? (
                <LineItemCard key={`${lineItem.id}-${index}`} lineItem={lineItem} />
              ) : (
                <BundleReturnLineItemCard key={`${lineItem.id}-${index}`} lineItem={lineItem} />
              )
            )}
          {returnItemsSelectionDisclaimer && parse(returnItemsSelectionDisclaimer)}
        </Grid.Column>
      </Grid>
      <StickyBottomAppBar>
        <ContinueBottomAppBar
          helperText={t("returnItemsSelectionPage.selectedItemsCount", { count: selectedItemsCount })}
          action={{ label: t("continue"), disabled: !canContinue, onClick: handleSubmit }}
        />
      </StickyBottomAppBar>
      <FormModal />
    </div>
  ) : (
    <React.Fragment>
      <Grid centered container padded="vertically">
        <Grid.Column mobile={16} tablet={13} computer={10} style={{ marginBottom: isEmbedded ? "100px" : 0 }}>
          <ProgressBarSection />
          <PageHeader header={parse(getStatusHeader(order.status)) as string} />
          {hasLineItems && showReturnableDeadline && (
            <p className={styles.returnableDeadline}>{t("orderSummary.returnableUntil", { returnableDeadline })}</p>
          )}
          {pastReturnsButton}
          {order.lineItems.length === 0 && (
            <Message warning>
              <Message.Header>
                <Header as="h3" textAlign="center">
                  {t("orderSummary.noItemMessage")}
                </Header>
              </Message.Header>
            </Message>
          )}
          {hasLineItems &&
            order.lineItems.map((lineItem, index) =>
              !lineItem.bundleItems.length ? (
                <LineItemCard key={`${lineItem.id}-${index}`} lineItem={lineItem} />
              ) : (
                <BundleReturnLineItemCard key={`${lineItem.id}-${index}`} lineItem={lineItem} />
              )
            )}
          {(order.status === "draft" ||
            (order.status === "update_draft" && returnItems.isDirty) ||
            (order.status === "pending" && returnItems.isDirty)) &&
            !returnOrderUnreturnable &&
            hasReturnItems && (
              <div className={styles.submitContainer}>
                <Button color="primaryColor" fluid onClick={handleSubmit} size="large">
                  {t("continueToNextStep")}
                </Button>
              </div>
            )}
          {returnItemsSelectionDisclaimer && parse(returnItemsSelectionDisclaimer)}
        </Grid.Column>
      </Grid>
      <FormModal />
    </React.Fragment>
  );
};
