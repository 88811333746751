import React from "react";
import { useSelector } from "react-redux";
import { Header, Icon, Modal } from "semantic-ui-react";

import { StoreState } from "reducers";
import styles from "./PickupModal.module.css";
import { useTranslation } from "react-i18next";
import { ShippingMethod } from "types";
import { ModalType } from "pages/ShippingMethodSelector/ShippingMethodSelector";
import { FromAddressForm } from "components/FromAddressForm/FromAddressForm";

interface PickupModalInterface {
  shippingMethod: ShippingMethod | null;
  openModal: ModalType;
  setOpenModal: Function;
}

export const PickupModal: React.FC<PickupModalInterface> = ({ shippingMethod, openModal, setOpenModal }) => {
  const order = useSelector((state: StoreState) => state.order);
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const fromAddress = useSelector((state: StoreState) => state.returnItems?.fromAddress);

  const { t } = useTranslation("orderFlow");

  if (!shippingMethod) return null;

  const courier = shippingMethod.courier;

  if (!courier) return null;
  const hasOnDemandPickup = shippingMethod.serviceType === "OnDemandPickup";

  function allowCustomTrackingNumber(courier: ShippingMethod["courier"]) {
    if (courier) {
      return courier.allowCustomTrackingNumber && currentShop.allowCustomTrackingNumber;
    } else {
      return false;
    }
  }
  return (
    <Modal
      centered={false}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onClose={() => null}
      open={openModal === "pickupModal"}
      size="small"
      className={"pickupModal"}
    >
      <div className={styles.headerContainer}>
        <div>
          <Header as="h3" style={{ marginBottom: "2px" }}>
            {t("customerAddress")}
          </Header>
          <span className={styles.subheader}>{t("pickupModal.description")}</span>
        </div>
        <Icon className={styles.headerIcon} name="close" onClick={() => setOpenModal("none")} />
      </div>

      <Modal.Content>
        <Modal.Description>
          <FromAddressForm
            address={
              fromAddress || {
                ...order.shippingAddress,
                address2: order.shippingAddress.address2 || "",
                firstName: order.shippingAddress.firstName || "",
                lastName: order.shippingAddress.lastName || "",
                email: order.shippingAddress.email || order.customer.email || order.email || "",
                phone: order.shippingAddress.phone || order.customer.phone || order.phone || "",
                province: order.shippingAddress.province || "",
                pickupDateTimeInfo: false,
                noPrinter: false,
                trackingNumber: ""
              }
            }
            onDemandPickup={hasOnDemandPickup}
            allowCustomTrackingNumer={allowCustomTrackingNumber(courier)}
            courier={courier}
            shippingMethod={shippingMethod}
            setOpenModal={setOpenModal}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
