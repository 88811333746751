import React from "react";
import { PaymentMethodForm, PaymentMethodInfo } from "components";
import { PaymentMethod } from "@stripe/stripe-js";

interface HandlePaymentMethodProps {
  requiresPayment: boolean;
  price: string;
  setPaymentMethod: (paymentMethod: PaymentMethod | null) => void;
  paymentStatus: string;
  paymentMethod: PaymentMethod | null;
}

export const HandlePaymentMethod: React.FC<HandlePaymentMethodProps> = ({
  requiresPayment,
  price,
  setPaymentMethod,
  paymentStatus,
  paymentMethod
}) => {
  if (!requiresPayment) return null;

  if (!paymentMethod) {
    return <PaymentMethodForm price={price} setPaymentMethod={setPaymentMethod} paymentStatus={paymentStatus} />;
  }
  return (
    <PaymentMethodInfo
      cardBrand={paymentMethod?.card ? paymentMethod.card.brand : ""}
      cardLast4={paymentMethod?.card ? paymentMethod.card.last4 : ""}
      onClose={() => setPaymentMethod(null)}
      price={price}
    />
  );
};
