import * as yup from "yup";
import countryRegionData, { Region } from "country-region-data";
import VIETNAM_ADDRESS_DATA from "./vietnam-provinces-districts-wards";

export const baseAddressShape = {
  address1: yup.string(),
  address2: yup.string(),
  country: yup.string(),
  province: yup.string(),
  city: yup.string(),
  zip: yup.string(),
  district: yup.string(),
  ward: yup.string()
};

export const addressValidationShape = {
  address1: yup
    .string()
    .required("Please enter your address street")
    .min(10)
    .max(80)
    .when("country", {
      is: country => country !== "United Kingdom",
      then: yup.string().matches(/\d+/, "Address line 1 should have House no / Flat no / Road no.")
    }),
  address2: yup
    .string()
    .max(80)
    .when("country", {
      is: country => country === "India",
      then: yup
        .string()
        .min(5)
        .max(80)
    }),
  country: yup.string().required("Please select your address country"),
  province: yup.string().when("country", {
    is: country => country === "United Kingdom",
    then: yup.string().nullable(),
    otherwise: yup.string().required("Please select your address state")
  }),
  city: yup
    .string()
    .nullable()
    .when("country", {
      is: country => country !== "Vietnam",
      then: yup.string().required("Please enter your address city")
    }),
  zip: yup
    .string()
    .nullable()
    .when("country", {
      is: country => country !== "Vietnam",
      then: yup.string().required("Please enter your address postcode")
    }),
  district: yup
    .string()
    .nullable()
    .when("country", {
      is: country => country === "Vietnam",
      then: yup.string().required()
    }),
  ward: yup
    .string()
    .nullable()
    .when("country", {
      is: country => country === "Vietnam",
      then: yup.string().required()
    })
};

export interface District {
  name: string;
  wards: string[];
}

export interface State {
  name: string;
  districts: District[];
}

export interface Country {
  name: string;
  states: State[];
}

const regionMapFn = (region: Region) => {
  let { name } = region;
  const isMatched = name.match(/^(Wilayah Persekutuan)\s{1}\((.+)\)$/);
  if (isMatched) {
    name = `${isMatched[2]}`;
  }
  return { name, districts: [] };
};

//  To add missing states
function getAdditionalStates(countryName: string) {
  switch (countryName) {
    case "United Kingdom":
      return [{ name: "Berkshire", districts: [] }];
    case "Romania":
      return [{ name: "Ilfov", districts: [] }];
    default:
      return [];
  }
}

export const COUNTRIES_DATA: Country[] = countryRegionData.map(country => {
  const { countryName, regions } = country;
  if (countryName === "Vietnam") {
    return { name: countryName, states: VIETNAM_ADDRESS_DATA };
  } else if (["United Kingdom", "Romania"].includes(countryName)) {
    const existingStates = regions.map(regionMapFn);
    const additonalStates: State[] = getAdditionalStates(countryName);
    const allStates = [...existingStates, ...additonalStates].sort((a, b) => a.name.localeCompare(b.name));
    return { name: countryName, states: allStates };
  } else {
    return { name: countryName, states: regions.map(regionMapFn) };
  }
});
