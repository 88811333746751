import { useQuestionFormContext } from "contexts/QuestionFormProvider";
import React from "react";
import { Form, Grid, TextArea } from "semantic-ui-react";
import styles from "./AnswerTextArea.module.css";
import { useTranslation } from "react-i18next";
import { capitalize } from "helpers";
export const AnswerTextArea: React.FC = () => {
  const { t } = useTranslation("orderFlow");
  const { questions, questionsDispatch, page } = useQuestionFormContext();
  const { id, answers, optional } = questions[page];

  function handleAnswerOnChange(option: string) {
    questionsDispatch({
      type: "UPDATE_TEXT_ANSWER",
      payload: {
        questionId: id,
        answer: option
      }
    });
  }

  const placeholderText = optional
    ? `${t("question.form.textPlaceHolder")} (${capitalize(t("optional"))})`
    : t("question.form.textPlaceHolder");

  return (
    <Form>
      <Grid>
        <Grid.Column mobile={16} className={styles.textAreaContainer}>
          <TextArea
            name={`answer.${page}`}
            value={answers[0] ?? ""} // When it's undefined, the answers from previous question persists
            placeholder={placeholderText}
            onChange={event => {
              const { value } = event.target as HTMLTextAreaElement;
              handleAnswerOnChange(value);
            }}
          />
        </Grid.Column>
      </Grid>
    </Form>
  );
};
