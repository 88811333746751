import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { Form } from "semantic-ui-react";
import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from "formik";
import { OnlineTransfer, BankAccountFields, EwalletAccountFields, ContinueButton } from "components";
import { ReturnSteps, updateBankAccount, updateEwalletAccount, updateReturnOrderThenContinue } from "actions";
import { useReceivablePaymentMethod } from "helpers";
import { validationSchema } from "../helpers";
import { useCheckCurrentStep } from "helpers/currentStep";
import { useHistory } from "react-router-dom";
import { ScrollToFirstErrorField } from "components/ScrollToFirstErrorField";

interface Props {
  formRef: React.RefObject<FormikProps<OnlineTransfer>>;
}

export const ReceivablePaymentFormForStickyButtons = ({ formRef }: Props): JSX.Element | null => {
  const currentStep = ReturnSteps.receivablePaymentDetails;
  useCheckCurrentStep(currentStep);

  const country = useSelector((state: StoreState) => state.order.shippingAddress.country);
  const bankAccount = useSelector((state: StoreState) => state.order.bankAccount);
  const ewalletAccount = useSelector((state: StoreState) => state.order.ewalletAccount);

  const { hasOnlineBankTransfer, hasEwalletTransfer } = useReceivablePaymentMethod();

  const dispatch = useDispatch();

  const handleContinue = ({ bankAccount, ewalletAccount }: OnlineTransfer) => {
    if (hasOnlineBankTransfer && bankAccount) dispatch(updateBankAccount(bankAccount));
    if (hasEwalletTransfer && ewalletAccount) dispatch(updateEwalletAccount(ewalletAccount));
  };

  const getInitialValues = () => {
    let initialValues = {};
    if (hasOnlineBankTransfer) {
      initialValues = {
        ...initialValues,
        bankAccount: {
          bank: bankAccount?.bank,
          beneficiary: bankAccount?.beneficiary,
          number: bankAccount?.number,
          ifsc: bankAccount?.ifsc
        }
      };
    }
    if (hasEwalletTransfer) {
      initialValues = {
        ...initialValues,
        ewalletAccount: {
          identifier: ewalletAccount?.identifier,
          name: ewalletAccount?.name,
          provider: ewalletAccount?.provider
        }
      };
    }
    return initialValues;
  };

  const validate = (values: OnlineTransfer) => {
    try {
      validateYupSchema(values, validationSchema(hasOnlineBankTransfer, hasEwalletTransfer), true, {
        country: country
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  };

  return (
    <div style={{ paddingLeft: "1rem", marginBottom: "50px" }}>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={handleContinue}
        validate={validate}
        innerRef={formRef}
        enableReinitialize
      >
        {(formikProps: FormikProps<OnlineTransfer>): JSX.Element => {
          const { handleSubmit } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <ScrollToFirstErrorField />
              {hasOnlineBankTransfer && <BankAccountFields country={country} />}
              {hasEwalletTransfer && <EwalletAccountFields />}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const ReceivablePaymentForm = (): JSX.Element | null => {
  const currentStep = ReturnSteps.receivablePaymentDetails;
  useCheckCurrentStep(currentStep);
  const history = useHistory();

  const country = useSelector((state: StoreState) => state.order.shippingAddress.country);
  const bankAccount = useSelector((state: StoreState) => state.order.bankAccount);
  const ewalletAccount = useSelector((state: StoreState) => state.order.ewalletAccount);

  const { hasOnlineBankTransfer, hasEwalletTransfer } = useReceivablePaymentMethod();

  const dispatch = useDispatch();

  const handleContinue = ({ bankAccount, ewalletAccount }: OnlineTransfer) => {
    if (hasOnlineBankTransfer && bankAccount) dispatch(updateBankAccount(bankAccount));
    if (hasEwalletTransfer && ewalletAccount) dispatch(updateEwalletAccount(ewalletAccount));

    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  const getInitialValues = () => {
    let initialValues = {};
    if (hasOnlineBankTransfer && bankAccount) {
      initialValues = {
        ...initialValues,
        bankAccount: {
          bank: bankAccount.bank,
          beneficiary: bankAccount.beneficiary,
          number: bankAccount.number,
          ifsc: bankAccount.ifsc
        }
      };
    }
    if (hasEwalletTransfer && ewalletAccount) {
      initialValues = {
        ...initialValues,
        ewalletAccount: {
          identifier: ewalletAccount.identifier,
          name: ewalletAccount.name,
          provider: ewalletAccount.provider
        }
      };
    }
    return initialValues;
  };

  const validate = (values: OnlineTransfer) => {
    try {
      validateYupSchema(values, validationSchema(hasOnlineBankTransfer, hasEwalletTransfer), true, {
        country: country
      });
    } catch (err) {
      return yupToFormErrors(err);
    }
    return {};
  };

  return (
    <div style={{ paddingLeft: "1rem" }}>
      <Formik initialValues={getInitialValues()} onSubmit={handleContinue} validate={validate} enableReinitialize>
        {(formikProps: FormikProps<OnlineTransfer>): JSX.Element => {
          const { handleSubmit, isSubmitting } = formikProps;
          return (
            <Form onSubmit={handleSubmit}>
              {hasOnlineBankTransfer && <BankAccountFields country={country} />}
              {hasEwalletTransfer && <EwalletAccountFields />}
              <ContinueButton isSubmitting={isSubmitting} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
