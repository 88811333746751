import React from "react";
import { Button } from "components";

interface ShippingMethodButtonProps {
  url: string;
  basic?: boolean;
  children: string;
}

export const ShippingMethodButton: React.FC<ShippingMethodButtonProps> = ({
  url,
  basic = false,
  children
}): JSX.Element => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: "1rem" }}>
      <Button basic={basic} color="primaryColor">
        {children}
      </Button>
    </a>
  );
};
