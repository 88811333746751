import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { Shipment, Summary } from "types";
import { ShippingMethodButton } from ".";

interface MakePaymentButtonRowProps {
  custEmail: string;
  shipment: Shipment;
  returnOrderStatus: Summary["returnOrderStatus"];
}

export const MakePaymentButtonRow: React.FC<MakePaymentButtonRowProps> = ({
  custEmail,
  shipment,
  returnOrderStatus
}): JSX.Element => {
  const { shopSlug, returnId } = useParams<{
    shopSlug: string;
    returnId: string;
  }>();
  const { t } = useTranslation("orderFlow");

  if (!shipment.requiresPayment || returnOrderStatus !== "reviewed") return <></>;

  const queryString = new URLSearchParams();
  queryString.append("email", custEmail);
  const paymentLink = `/${shopSlug}/returns/${returnId}/shipment_and_payment/edit?${queryString}`;

  return (
    <Grid style={{ paddingTop: "0.5rem" }}>
      <Grid.Row>
        <ShippingMethodButton url={paymentLink}>{t("shipmentInfo.button.makePayment")}</ShippingMethodButton>
      </Grid.Row>
    </Grid>
  );
};
