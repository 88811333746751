import React from "react";
import { Container as SemanticContainer } from "semantic-ui-react";

export const Container: React.FC<any> = ({ children }): JSX.Element => {
  return (
    <SemanticContainer
      style={{
        marginTop: "4rem"
      }}
    >
      {children}
    </SemanticContainer>
  );
};
