import { PostCo360API } from "api/constants";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import camelcaseKeys from "camelcase-keys";
import {
  InstantExchangeData,
  nullInstantExchangeData,
  InstantExchangeTransactionInfo
} from "constants/instantExchange";

export const useInstantExchangeData = (options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);

  const { data, ...others } = useQuery({
    queryKey: ["instantExchangeData", returnOrderId],
    queryFn: () => getInstantExchangeData(returnOrderId),
    ...options
  });

  const instantExchangeData = data ? data : nullInstantExchangeData;
  return { instantExchangeData, ...others };
};

export const useInstantExchangePreflightCheck = (options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["instantExchangePreflightCheck", returnOrderId],
    queryFn: () => getInstantExchangePreflightCheck(returnOrderId),
    ...options
  });

  const offerInstantExchange = data ? data.offerInstantExchange : false;

  return { offerInstantExchange, ...others };
};

export const useInstantExchangeTransactionInfo = (options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["instantExchangeTransactionInfo", returnOrderId],
    queryFn: () => getInstantExchangeTransactionInfo(returnOrderId),
    ...options
  });

  const instantExchangeTransactionInfo: InstantExchangeTransactionInfo = data ? data : {};
  return { instantExchangeTransactionInfo, ...others };
};

const getInstantExchangeData = async (returnOrderId: string): Promise<InstantExchangeData> => {
  try {
    const response = await PostCo360API().get(`/api/v2/customer/return_orders/${returnOrderId}/instant_exchange_data`);
    return camelcaseKeys(response.data, { deep: true }) as InstantExchangeData;
  } catch (error) {
    throw Error(error.message);
  }
};

const getInstantExchangePreflightCheck = async (returnOrderId: string): Promise<any> => {
  try {
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/instant_exchange_preflight_check`
    );

    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

const getInstantExchangeTransactionInfo = async (returnOrderId: string): Promise<any> => {
  try {
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/instant_exchange_transaction_info`
    );
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};
