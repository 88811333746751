import { Variant } from "actions";

function isVariantDisabled(variant?: Variant) {
  return !variant?.exchangeableByInventory || !variant?.exchangeableByPrice;
}

export function evaluateOptionDisability(
  option: "option1" | "option2" | "option3",
  optionValue: string | null,
  variant: Variant | undefined,
  variants: Variant[],
  selectedOption1: string | null,
  selectedOption2: string | null
): boolean {
  switch (option) {
    case "option1":
      const option1VariantPairs = variants.filter((variant: Variant) => variant.option1 === optionValue);
      const isAllOption1VariantPairDisabled = option1VariantPairs.reduce(
        (acc: Boolean, variant: Variant) => acc && isVariantDisabled(variant),
        true
      );

      if (selectedOption2) return isAllOption1VariantPairDisabled;
      if (variant) return isVariantDisabled(variant);

      return isAllOption1VariantPairDisabled;
    case "option2":
      if (!selectedOption1) return true;
      if (variant) return isVariantDisabled(variant);

      const option2VariantPairs = variants.filter(
        (variant: Variant) => variant.option1 === selectedOption1 && variant.option2 === optionValue
      );
      const isAllOption2VariantPairDisabled = option2VariantPairs.reduce(
        (acc: Boolean, variant: Variant) => acc && isVariantDisabled(variant),
        true
      );

      return isAllOption2VariantPairDisabled;
    case "option3":
      if (!selectedOption1 || !selectedOption2) return true;

      return isVariantDisabled(variant);
    default:
      return true;
  }
}

export function variantFromSelectionFilter(
  option: "option1" | "option2" | "option3",
  optionValue: string | null,
  variant: Variant,
  selectedOption1: string | null,
  selectedOption2: string | null,
  selectedOption3: string | null
): boolean {
  switch (option) {
    case "option1":
      return (
        variant[option] === optionValue && variant.option2 === selectedOption2 && variant.option3 === selectedOption3
      );
    case "option2":
      return (
        variant[option] === optionValue && variant.option1 === selectedOption1 && variant.option3 === selectedOption3
      );
    case "option3":
      return (
        variant[option] === optionValue && variant.option1 === selectedOption1 && variant.option2 === selectedOption2
      );
    default:
      return false;
  }
}

export function getSelectionsFromVariants(
  variants: Variant[],
  option: "option1" | "option2" | "option3"
): (string | null)[] {
  const options = Array.from(new Set(variants.map(variant => variant[option])));

  return options.filter(option => option !== null);
}

export function getDisabledMessage(variant: Variant | undefined) {
  if (variant) {
    if (!variant.exchangeableByInventory) {
      // Not using variant.notExchangeableByInventoryMessage
      // because customer doesn't need to know the detail
      return `${variant.title} is out of stock`;
    } else if (!variant.exchangeableByPrice) {
      return variant.notExchangeableByPriceMessage;
    } else {
      return "";
    }
  } else {
    return "Variant unavailable";
  }
}

export function evaluatePendingSelection(
  option: string,
  selectedOption1: string | null,
  selectedOption2: string | null,
  selectedOption3: string | null,
  haveOption1: boolean,
  haveOption2: boolean,
  haveOption3: boolean
) {
  switch (option) {
    case "option1":
      if (haveOption2 && !selectedOption2) return true;
      if (haveOption3 && !selectedOption3) return true;

      return false;
    case "option2":
      if (haveOption1 && !selectedOption1) return true;
      if (haveOption3 && !selectedOption3) return true;

      return false;
    case "option3":
      if (haveOption1 && !selectedOption1) return true;
      if (haveOption2 && !selectedOption2) return true;

      return false;
    default:
      return false;
  }
}

export function getPriceCentsAndCurrency(price: { cents: number; currencyIso: string } | undefined) {
  const priceCents = price ? price.cents : 0;
  const currency = price ? price.currencyIso : "";
  return { priceCents, currency };
}
