import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { PostSubmitReturnItemSummary } from "./PostSubmitReturnItemSummary";
import { ReturnItemsSummaryGroup } from "./types";
import { useTranslation } from "react-i18next";
import camelCase from "camelcase";
import { returnItemsGroupTitle } from "./helpers";
import styles from "./ReturnItemSummary.module.css";

interface Props {
  returnItemsGroups: ReturnItemsSummaryGroup[];
  separatedByBorder: boolean;
  title?: string;
}

export const PostSubmitReturnItemsGroups: React.FC<Props> = ({ returnItemsGroups, separatedByBorder, title }) => {
  const { t } = useTranslation("orderFlow");

  const separatedByBorderStyle = {
    border: "1px solid #D3D3D3",
    borderRadius: "10px",
    padding: "10px 15px",
    marginBottom: "10px"
  };

  if (returnItemsGroups.length === 0) return null;

  return (
    <div className={styles.returnItemsGroupsContainer}>
      {title && <div style={{ color: "gray", fontWeight: "bold", paddingBottom: "10px" }}>{title}</div>}

      {returnItemsGroups.map((returnItemsGroup, index) => (
        <div style={separatedByBorder ? separatedByBorderStyle : {}} key={`return_items_group-${index}`}>
          {returnItemsGroup.title && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "10px",
                flexDirection: isMobile ? "column" : "row"
              }}
            >
              <div style={{ color: "#707070" }}>{returnItemsGroupTitle(returnItemsGroup, t)}</div>
              {returnItemsGroup.actionButtons.map(actionButton => (
                <div style={{ minWidth: "110px", textAlign: isMobile ? "left" : "right" }}>
                  <a href={actionButton.url} target="_blank">
                    {t(`orderSummary.returnItemsGroupButtonName.${camelCase(actionButton.type)}`)}
                  </a>
                </div>
              ))}
            </div>
          )}
          {returnItemsGroup.returnItems.map((returnItem, index) => (
            <PostSubmitReturnItemSummary returnItem={returnItem} key={`return_items_summary_${index}`} />
          ))}
        </div>
      ))}
    </div>
  );
};
