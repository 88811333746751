import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field, FieldProps, useFormikContext } from "formik";
import { Form, Header } from "semantic-ui-react";
import { ReturnSteps, ShippingAddress } from "actions";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import styles from "./ContactDetailsFields.module.css";
import { StoreState } from "reducers";

interface ContactDetailFieldProps {
  phoneNumber?: string;
}

export const ContactDetailsFields: React.FC<ContactDetailFieldProps> = ({ phoneNumber: initialPhoneNumber }) => {
  const { t } = useTranslation("orderFlow");
  const { setFieldValue } = useFormikContext<ShippingAddress>();

  const countryCode = useSelector((state: StoreState) => state.order.shippingAddress.countryCode);
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const shippingAddressPage = returnStep === ReturnSteps.shippingAddress;
  const className = shippingAddressPage ? styles.contactDetailsHeaderMainPage : styles.contactDetailsHeaderContainer;

  const parsePhoneToInternationalFormat = (value: string): string => {
    return parsePhoneNumberFromString(value, countryCode as any)?.formatInternational() || "";
  };

  useEffect(() => {
    if (!!initialPhoneNumber) {
      setFieldValue("phone", parsePhoneToInternationalFormat(initialPhoneNumber));
    } else {
      setFieldValue("phone", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPhoneNumber]);

  return (
    <Fragment>
      <div className={className}>
        <Header sub={!shippingAddressPage}>{t("addressForm.contactHeader")}</Header>
      </div>
      <Form.Group widths="equal">
        <Field name="firstName">
          {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["firstName"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.firstName")}</label>
              <Form.Input error={touched.firstName && errors.firstName} type="text" {...field} />
            </Form.Field>
          )}
        </Field>

        <Field name="lastName">
          {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["lastName"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.lastName")}</label>
              <Form.Input
                error={touched.lastName && errors.lastName}
                type="text"
                {...field}
                value={field.value || ""}
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="email">
          {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["email"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.email")}</label>
              <Form.Input error={touched.email && errors.email} type="text" {...field} />
            </Form.Field>
          )}
        </Field>
        <Field name="phone">
          {({ form: { errors, touched }, field }: FieldProps<ShippingAddress["phone"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.phone")}</label>
              <PhoneInput
                className={touched.phone && errors.phone ? "error field" : ""}
                defaultCountry={countryCode as CountryCode}
                international
                name="phone"
                placeholder="Please include the country's dial code (e.g. +60)"
                onChange={phoneNumber => setFieldValue("phone", phoneNumber ?? "")}
                onBlur={event => field.onBlur("phone")(event)}
                value={field.value}
              />{" "}
              {touched.phone && errors.phone && <div className="ui pointing above prompt label">{errors.phone}</div>}
            </Form.Field>
          )}
        </Field>
      </Form.Group>
    </Fragment>
  );
};
