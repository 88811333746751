import React, { useEffect, useRef } from "react";
import { MobileCameraView } from "./MobileCameraView";
import { MobileImagePreviewer } from "./MobileImagePreviewer";
import { FullscreenContainer } from "components/FullscreenContainer";
import { ImageUploadRequirementInstruction } from "hooks/useP2PProductListings";
import { UppyFile } from "@uppy/core";
import { useTranslation } from "react-i18next";
import { createUppy } from "../../helpers";
import { UploadedImage } from "actions/returnItems";
import { useHistory } from "react-router-dom";

interface Props {
  instructions: ImageUploadRequirementInstruction[];
  selectedInstructionIndex: number;
  isUploading: boolean;
  isDestroying: boolean;
  onImageUploaded: (uploadedImage: UploadedImage) => void;
  onImageRemoved: () => void;
  onBack?: () => void;
  onProceed: () => void;
}

export const MobileImageUpload = ({
  instructions,
  selectedInstructionIndex,
  isUploading,
  isDestroying,
  onImageUploaded,
  onImageRemoved,
  onBack,
  onProceed
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation("orderFlow");
  const instruction = instructions[selectedInstructionIndex];

  const uppy = useRef(createUppy());

  useEffect(() => {
    const onUploadSuccess = (file: UppyFile | undefined) => {
      if (!file) return null;

      const meta = file.meta;
      const key = (meta["key"] as string) || "";
      const matches = key.match(/^\S+-cache\/(.+)/); // object key without prefix
      const id = matches ? matches[1] : null;
      onImageUploaded({
        id,
        uppyFileId: file.id,
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      });

      uppy.current.getFiles().forEach(file => uppy.current.removeFile(file.id));
    };

    uppy.current.setOptions({ autoProceed: true });
    uppy.current.on("upload-success", onUploadSuccess);

    return () => {
      uppy.current.off("upload-success", onUploadSuccess);
    };
  }, [onImageUploaded]);

  const aboveTitle = t("p2pProductListingPage.imagesProgress", {
    current: selectedInstructionIndex + 1,
    total: instructions.length
  });

  if (instruction.image) {
    return (
      <FullscreenContainer>
        <MobileImagePreviewer
          isDestroying={isDestroying}
          aboveTitle={aboveTitle}
          src={instruction.image.url}
          onBack={onBack}
          onApprove={onProceed}
          onReject={onImageRemoved}
        />
      </FullscreenContainer>
    );
  } else {
    return (
      <FullscreenContainer>
        <MobileCameraView
          aboveTitle={aboveTitle}
          title={instruction.title}
          description={instruction.description}
          sampleImages={instruction.sampleImages}
          onCapture={uploadedImage => {
            uppy.current.addFile({ data: uploadedImage, type: "image/webp" });
          }}
          onCancel={onBack ?? history.goBack}
          isUploading={isUploading}
        />
      </FullscreenContainer>
    );
  }
};
