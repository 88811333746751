import { PostCo360API } from "api/constants";
import camelcaseKeys from "camelcase-keys";
import * as Sentry from "@sentry/react";

export const getInstantExchangePaymentIntent = async (returnOrderId: string): Promise<{ clientSecret: string }> => {
  try {
    const response = await PostCo360API().post(
      `api/v2/customer/return_orders/${returnOrderId}/instant_exchange_data/payment_transaction`
    );
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

export const updateInstantExchangePaymentTransaction = (returnOrderId: string) => {
  return async (paymentIntentId: string): Promise<any> => {
    try {
      const response = await PostCo360API().put(
        `api/v2/customer/return_orders/${returnOrderId}/instant_exchange_data/payment_transaction`,
        {
          paymentIntentId: paymentIntentId
        }
      );
      return response;
    } catch (error) {
      throw Error(error.message);
    }
  };
};

interface RegularPaymentIntent {
  clientSecret: string;
  exchangeOrderId: string;
}

export const getRegularExchangePaymentIntent = async (returnOrderId: string): Promise<RegularPaymentIntent> => {
  try {
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/exchange_order/payment_intent.json`
    );
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

export const updateExchangeOrder = (exchangeOrderId: string) => {
  return async (paymentIntentId: string): Promise<any> => {
    try {
      const response = await PostCo360API().put(`/api/v2/customer/exchange_orders/${exchangeOrderId}`, {
        payment_intent_id: paymentIntentId
      });
      return response;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      throw Error("exchangePaymentPage.errorNeedsSupport");
    }
  };
};
