import { useIsEmbedded } from "hooks/useIsEmbedded";
import React, { ReactNode, useEffect } from "react";

export const FullscreenContainer = ({ children }: { children?: ReactNode }) => {
  const { isEmbedded } = useIsEmbedded();

  useEffect(() => {
    if (isEmbedded) {
      window.top?.postMessage({ type: "enterFullscreen" }, "*");
    }

    return () => {
      if (isEmbedded) {
        window.top?.postMessage({ type: "exitFullscreen" }, "*");
      }
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 20,
        height: "100dvh",
        minWidth: "100vw",
        backgroundColor: "white",
        overflowY: "scroll"
      }}
    >
      {children}
    </div>
  );
};
