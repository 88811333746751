import { ReturnItem } from "actions";
import { TFunction } from "i18next";
import { ReturnItem as SummaryReturnItem } from "types";
import { ReturnItemSummary } from "components/ReturnItemsSummary/components/types";
import { storeCreditNames } from "constants/returnMethod";

export function isAllItemsRejected(returnItems: ReturnItem[]) {
  return returnItems.reduce((accumulator, returnItem) => accumulator && Boolean(returnItem.rejectReason), true);
}

export function variantName(returnItem: ReturnItem): string {
  let title: string;
  if (returnItem.exchangeItemName) {
    // For existing return order
    title = returnItem.exchangeItemName;
  } else if (returnItem.exchangingVariant && returnItem.exchangingVariant.title) {
    // For new return order
    const productTitle = returnItem.selectedProduct?.title;
    const variantTitle = returnItem.exchangingVariant.title;
    if (variantTitle.toLowerCase() === "default title") {
      title = productTitle ?? variantTitle;
    } else {
      title = productTitle ? `${productTitle} - ${variantTitle}` : variantTitle;
    }
  } else {
    title = "no title";
  }
  return title;
}

export function getRefundMethodBadgeText(
  returnItem: ReturnItem | SummaryReturnItem | ReturnItemSummary,
  t: TFunction
): string {
  const { name, displayName, hasCustomName } = returnItem.returnMethod;

  if (name === "apparel21_voucher") {
    return `${t("returnFor")} gift voucher`;
  }

  if (hasCustomName && displayName) {
    return displayName;
  }

  if (storeCreditNames.includes(name)) {
    return `${t("returnFor")} ${t("summary.returnMethod.storeCredit")}`;
  } else {
    return `${displayName} ${t("forRefund")}`;
  }
}

export function getExchangeMethodBadgeText(
  returnItem: ReturnItem | SummaryReturnItem | ReturnItemSummary,
  forExchange: string,
  variantName: string
): string {
  if (["same_product", "advanced"].includes(returnItem.returnMethod.name)) {
    return `${forExchange} ${variantName}`;
  } else {
    return `${forExchange} other product`;
  }
}
