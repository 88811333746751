import React from "react";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearErrors } from "../../actions";
import { StoreState } from "../../reducers";
import styles from "./ErrorMessages.module.css";

interface ErrorMessagesProps {
  errors: Error[];
  clearErrors: typeof clearErrors;
}

const _ErrorMessages: React.FC<ErrorMessagesProps> = ({ clearErrors, errors }) => {
  const { t } = useTranslation("translation");
  if (errors.length === 0) return null;

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <Message error header={t("error")} list={errors.map(error => error.message)} onDismiss={clearErrors} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    errors: state.errors
  };
};

export const ErrorMessages = connect(mapStateToProps, { clearErrors })(_ErrorMessages);
