import React from "react";
import { Header } from "semantic-ui-react";
import styles from "./PageHeader.module.css";

interface PageHeaderProps {
  header: string;
  textAlign?: "left" | "center" | "right" | "justified";
  isReturnMethodTypeSelectorHeader?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  header,
  textAlign = "center",
  isReturnMethodTypeSelectorHeader
}): JSX.Element => {
  return (
    <div className={isReturnMethodTypeSelectorHeader ? styles.returnMethodTypeSelectorHeader : styles.header}>
      <Header as="h2" textAlign={textAlign}>
        {header}
      </Header>
    </div>
  );
};
