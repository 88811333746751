import React from "react";
import styles from "./ExchangeProductSelector.module.css";
import { ProductCard } from "./ProductCard";
import { Button } from "components/Custom";
import { useFilteredCollectionsProducts } from "./hooks";
import { Loading } from "components/Loading/Loading";
import { NoProductsFound } from "./NoProductsFound";

interface Props {
  debouncedSearchTerm: string;
  collectionIds: string[];
}

export const FilteredCollectionsProducts = ({ debouncedSearchTerm, collectionIds }: Props) => {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useFilteredCollectionsProducts(
    debouncedSearchTerm,
    collectionIds
  );

  const productsCount = data.pages.reduce((count, page) => {
    return count + page.products.length;
  }, 0);

  if (isLoading) {
    return <Loading />;
  }

  if (!debouncedSearchTerm) {
    return null;
  }

  return productsCount === 0 ? (
    <NoProductsFound
      title="NO PRODUCTS FOUND"
      description={`We can't find any products for “${debouncedSearchTerm}”`}
    />
  ) : (
    <div className={styles.productGridContainer}>
      <div className={styles.productCardGrid}>
        {data.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.products.map(product => (
              <ProductCard product={product} key={product.id} />
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className={styles.footerSection}>
        {hasNextPage && (
          <div className={styles.loadMoreButtonContainer}>
            <Button
              color="primaryColor"
              loading={isFetchingNextPage}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              LOAD MORE
            </Button>
          </div>
        )}
        <div className={styles.footerDescription}>
          <p>Viewing {productsCount} exchangeable product(s)</p>
        </div>
      </div>
    </div>
  );
};
