import * as yup from "yup";
import { ReturnOrder } from "./types";

export const validationSchema = () => {
  let shape = {
    noPrinter: yup.boolean(),
    trackingNumber: yup.string().when("noPrinter", {
      is: true,
      then: yup
        .string()
        .required("Original tracking number is required if you don't have a printer")
        .test("regexes matches", "Tracking number prefix, suffix or substring does not match", function(val) {
          const regexes: { customContext: string } = this.options.context as {
            customContext: string;
          };
          const regexArray = regexes["customContext"].replace(/\s/g, "").split(",");
          return regexArray.some((regex: string) => new RegExp(regex).test(val));
        })
    })
  };

  return yup.object().shape(shape);
};

export const shippingInfo = (returnOrder: ReturnOrder) => {
  const {
    shippingMethod,
    shippingMethod: {
      shippingService,
      courier,
      shippingService: { serviceInfo, type: serviceType }
    },
    shipment: { feeCents, feeCurrency }
  } = returnOrder;
  const usesDropOff = serviceType === "DropOff";
  const usesPickup = ["Pickup", "OnDemandPickup"].includes(serviceType);

  return {
    shippingMethod,
    shippingService,
    serviceInfo,
    serviceType,
    usesDropOff,
    usesPickup,
    courier,
    feeCents,
    feeCurrency
  };
};
